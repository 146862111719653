/* eslint-disable @typescript-eslint/no-explicit-any */
import { io, Socket } from 'socket.io-client';

// import { store } from '../context/store';
// import {
//   addNewActiveUser,
//   removeActiveUser,
// } from '../context/store/appReducer';
// import {
//   addNewMessage,
//   deleteMessage,
// } from '../context/thunks/communityMessagesThunk';
import {
  SOCKET_ACTION_EVENTS_MAP,
  SOCKET_BROADCAST_EVENTS_MAP,
} from '../types/socketTypes';
import config from './config';

export class SocketAPI {
  private static instance: Socket | null = null;

  private static token: string | null = null;

  static initialize = (token: string) => {
    if (this.instance) return this.instance; // Prevent multiple instances

    console.log('🔄 Initializing new socket connection...');
    this.token = token;

    this.instance = io(config.SOCKET_ENDPOINT, {
      transports: ['websocket', 'polling'],
      auth: { token },
      reconnection: true, // Enable reconnection
      reconnectionAttempts: 10,
      reconnectionDelay: 3000, // Try reconnecting every 3s
      reconnectionDelayMax: 10000,
    });

    this.instance.on('connect', () => {
      console.log('✅ Socket Connected:', this.instance?.id);

      //   const { dispatch } = store;

      //   if (this.instance) {
      //     this.instance.off(SOCKET_BROADCAST_EVENTS.USER_JOINED);
      //     this.instance.on(SOCKET_BROADCAST_EVENTS.USER_JOINED, (event) => {
      //       dispatch(addNewActiveUser(event.payload.user));
      //     });

      //     this.instance.off(SOCKET_BROADCAST_EVENTS.USER_LEFT);
      //     this.instance.on(SOCKET_BROADCAST_EVENTS.USER_LEFT, (event) => {
      //       dispatch(removeActiveUser(event.payload.user));
      //     });

      //     this.instance.off(SOCKET_BROADCAST_EVENTS.ROOM_MESSAGE_CREATED);
      //     this.instance.on(
      //       SOCKET_BROADCAST_EVENTS.ROOM_MESSAGE_CREATED,
      //       (resp) => {
      //         dispatch(addNewMessage(resp.payload.message));
      //       },
      //     );

      //     this.instance.off(SOCKET_BROADCAST_EVENTS.ROOM_MESSAGE_EDITED);
      //     this.instance.on(
      //       SOCKET_BROADCAST_EVENTS.ROOM_MESSAGE_EDITED,
      //       (resp) => {
      //         dispatch(addNewMessage(resp.payload.message));
      //       },
      //     );

      //     this.instance.off(SOCKET_BROADCAST_EVENTS.ROOM_MESSAGE_REACTED);
      //     this.instance.on(
      //       SOCKET_BROADCAST_EVENTS.ROOM_MESSAGE_REACTED,
      //       (resp) => {
      //         dispatch(addNewMessage(resp.payload.message));
      //       },
      //     );

      //     this.instance.off(SOCKET_BROADCAST_EVENTS.ROOM_MESSAGE_DELETED);
      //     this.instance.on(
      //       SOCKET_BROADCAST_EVENTS.ROOM_MESSAGE_DELETED,
      //       (resp) => {
      //         dispatch(deleteMessage(resp.payload.room, resp.payload.message));
      //       },
      //     );
      //   }
    });

    this.instance.on('disconnect', (reason) => {
      console.warn('⚠️ Socket Disconnected:', reason);
      if (
        reason === 'io server disconnect' ||
        reason === 'io client disconnect' ||
        reason === 'ping timeout' ||
        reason === 'transport close' ||
        reason === 'transport error'
      ) {
        this.reconnect(); // Handle manual reconnection if disconnected by the server
      }
    });

    this.instance.on('connect_error', (err) => {
      console.error('🚨 Socket Error:', err);
      if (this.instance) {
        this.instance.io.opts.transports = ['polling', 'websocket'];
      }
    });

    this.instance?.on('exception', (error: any) => {
      console.error('🚨 Socket Exception Error:', error);
    });

    return this.instance;
  };

  static getInstance = (): Socket => {
    if (!this.instance) {
      throw new Error(
        '❌ Socket instance not initialized. Call initialize first.',
      );
    }
    return this.instance;
  };

  static reconnect() {
    if (!this.token) {
      console.error('❌ No token found, cannot reconnect.');
      return;
    }
    console.log('🔄 Reconnecting socket...');
    this.disconnect(); // Close existing connection before reconnecting
    this.initialize(this.token);
  }

  static disconnect() {
    if (this.instance) {
      console.log('🔌 Disconnecting socket...');
      this.instance.disconnect();
      this.instance.close();
      this.instance = null;
    }
  }

  static on<T extends keyof SOCKET_BROADCAST_EVENTS_MAP>(
    event: T,
    callback: (data: SOCKET_BROADCAST_EVENTS_MAP[T]) => void,
  ) {
    this.getInstance().on(event, callback as any);
  }

  static emit<T extends keyof SOCKET_ACTION_EVENTS_MAP>(
    event: T,
    data: SOCKET_ACTION_EVENTS_MAP[T],
    callback?: (response: any) => void,
  ) {
    if (!this.instance || !this.instance.connected) {
      console.error('❌ Socket not connected. Cannot emit event:', event);
      return;
    }

    if (callback) {
      this.getInstance().emit(event, data, callback);
    } else {
      this.getInstance().emit(event, data);
    }
  }
}
