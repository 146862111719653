/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { SocketAPI } from '../api/SocketAPI';
import { useAppSelector } from '../shared/hooks';

interface IUseSocket {
  setupSocket: () => void;
}

const SocketContext = createContext<IUseSocket>({
  setupSocket: () => {},
});
export const useSocket = () => useContext(SocketContext);

interface Props {
  children: React.ReactNode;
}

export const CommunitySocketProvider: React.FC<Props> = ({ children }) => {
  const {
    app: { token },
    user: { id },
  } = useAppSelector((state) => state);

  const setupSocket = useCallback(() => {
    if (!token || !id) {
      return;
    }

    if (token) {
      console.log('🔌 Setting up socket...');
      SocketAPI.initialize(token);
    }
  }, [token, id]);

  useEffect(() => {
    if (!token || !id) return;

    setupSocket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id]);

  const contextValue = useMemo(() => ({ setupSocket }), [setupSocket]);

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};
