import './styles.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Badge } from 'antd';
import { PiEyeBold } from 'react-icons/pi';
import { useLocation, useNavigate } from 'react-router-dom';
import TMIcon from 'tm-icons-library';

import tmLogo from '../../assets/svg/TagMango.svg';
import {
  postTypeList,
  setFilteredMangoes,
  setFilters,
  setPostTags,
} from '../../context/activityReducer';
import {
  resetAllFilters,
  setActiveSection,
  setShowing,
  setSortBy,
  showingOptionList,
  sortByOptionList,
} from '../../context/communityReducer';
import { useTheme } from '../../context/ThemeProvider';
import { checkForPermissions } from '../../context/userReducer';
import CommunityChannelDrawer from '../../screens/Community/components/CommunityChannelDrawer';
import CreatePostChannelBottomSheet from '../../screens/Community/components/CreatePostChannelBottomSheet';
import { routeVsTitle, RouteVsTitleKeys } from '../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import { Onboarding, UAMPermissions } from '../../types/userTypes';
import CommunityChannelTabPannel from './components/CommunityChannelTabPannel';
import FilterButtonWithBottomSheet from './components/FilterButtonWithBottomSheet';
import DynamicHeader from './DynamicHeader';

interface Props {
  showWorkspace?: boolean;
}

const CommunityHeader: React.FC<Props> = () => {
  const { colors } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    isCommunityEnabled,
    isTagMango,
    hostMetadata,
    tags: allTags,
  } = useAppSelector((state) => state.app);
  const userDetails = useAppSelector((state) => state.user);
  const { filteredMangoes, postTags, filters } = useAppSelector(
    (state) => state.activity,
  );
  const {
    showing,
    sortBy,
    headerTitle,
    activeSection,
    selectedChannelData,
    channelPageActiveTab,
  } = useAppSelector((state) => state.community);

  const [showCreatePost, setShowCreatePost] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const setHeaderTitle = useCallback(() => {
    if (headerTitle) return headerTitle;
    const pathname = location.pathname;
    if (
      location.pathname.includes(
        ROUTES.COMMUNITY_CHANNEL.replace(':channelId', ''),
      )
    )
      return;
    const isFeedMsgOrTMPage =
      pathname === ROUTES.COMMUNITY_FEED ||
      pathname === ROUTES.COMMUNITY_MESSAGES ||
      pathname === ROUTES.COMMUNITY_TAGMANGO_TEAM;
    const key = pathname.split('/').pop();
    if (isFeedMsgOrTMPage && pathname) {
      dispatch(
        setActiveSection({
          activeSection: key as RouteVsTitleKeys,
          headerTitle: routeVsTitle[key as RouteVsTitleKeys],
        }),
      );
    } else {
      navigate(ROUTES.COMMUNITY_FEED);
    }
  }, [dispatch, headerTitle, location.pathname, navigate]);

  const isCommunityFeedPage = useMemo(() => {
    return location.pathname === ROUTES.COMMUNITY_FEED;
  }, [location.pathname]);

  const isCommunityChannelPage = useMemo(() => {
    return location.pathname.includes(
      ROUTES.COMMUNITY_CHANNEL.replace(':channelId', ''),
    );
  }, [location.pathname]);

  const showAddIcon = useMemo(() => {
    const userConditions =
      Boolean(
        isTagMango
          ? userDetails.type === 'creator_completed' ||
              userDetails.type === 'dual'
          : userDetails.id === hostMetadata.creator._id,
      ) ||
      (userDetails.type === 'creator_restricted' &&
        checkForPermissions(
          [UAMPermissions.FEED_WRITE],
          userDetails.userAccessPermissions,
        )) ||
      (userDetails.type === 'fan_completed' &&
        isCommunityEnabled &&
        !userDetails.isBlockedFromCommunityEngagement);

    return (
      userConditions &&
      activeSection !== 'tagmango-team' &&
      !(window.location.pathname.includes('/channel/') && selectedChannelData)
    );
  }, [
    activeSection,
    hostMetadata.creator._id,
    isCommunityEnabled,
    isTagMango,
    selectedChannelData,
    userDetails.id,
    userDetails.isBlockedFromCommunityEngagement,
    userDetails.type,
    userDetails.userAccessPermissions,
  ]);

  const mangoList = useMemo(() => {
    const tempMangoList =
      userDetails?.type === Onboarding.FAN_COMPLETED
        ? userDetails?.subscribedMangoes
        : userDetails?.creatorMangoes;
    let mangoIdList: string[] = [];
    return [...filteredMangoes, ...tempMangoList].reduce((acc, eachMango) => {
      if (!mangoIdList.includes(eachMango?._id)) {
        mangoIdList = [...mangoIdList, eachMango?._id];
        return [...acc, eachMango];
      }
      return acc;
    }, []);
  }, [
    filteredMangoes,
    userDetails?.creatorMangoes,
    userDetails?.subscribedMangoes,
    userDetails?.type,
  ]);

  const tags = useMemo(
    () =>
      Object.entries(allTags).map(([key, value]) => ({
        ...value,
        key,
      })),
    [allTags],
  );

  const filteredTagList = useMemo(() => {
    return postTags.map((eachTag) => ({
      key: eachTag?._id,
      value: eachTag?.tagName,
      creatorId: eachTag?.creator,
      title: eachTag?.tagName,
      _id: eachTag?._id,
    }));
  }, [postTags]);

  const tagList = useMemo(() => {
    let tagIdList: string[] = [];
    return [...filteredTagList, ...(tags || [])].reduce(
      (acc: any[], eachTagObj) => {
        if (!tagIdList.includes(eachTagObj?.key)) {
          tagIdList = [...tagIdList, eachTagObj?.key];
          return [
            ...acc,
            { ...eachTagObj, _id: eachTagObj?.key, title: eachTagObj?.value },
          ];
        }
        return acc;
      },
      [],
    );
  }, [filteredTagList, tags]);

  const sortByTitle = useMemo(() => {
    return sortByOptionList.find((each) => each?._id === sortBy[0]?._id)?.title;
  }, [sortBy]);

  const showingTitle = useMemo(() => {
    return showingOptionList.find((each) => each?._id === showing[0]?._id)
      ?.title;
  }, [showing]);

  const handleChannelTitleClick = useCallback(() => {
    const searchParams = new URLSearchParams(location?.search);
    searchParams.append('info', 'true');
    navigate(`${location?.pathname}?${searchParams?.toString()}`);
  }, [location?.pathname, location?.search, navigate]);

  const customTitle = useMemo(() => {
    if (activeSection === 'tagmango-team') {
      return (
        <div className="header-community">
          <div className="header-community_logo-wrapper">
            <img src={tmLogo} alt="logo" />
          </div>
          <div className="header-community_text-wrapper">
            <p className="header-community_text-wrapper_title">
              From the Tagmango Team
            </p>
            <span className="header-community_text-wrapper_subtitle">
              <PiEyeBold />
              ONLY VISIBLE TO HOSTS
            </span>
          </div>
        </div>
      );
    }
    if (window.location.pathname.includes('/channel/') && selectedChannelData) {
      return (
        <button
          className="header-community"
          onClick={() => handleChannelTitleClick()}>
          {selectedChannelData.communityProfilePictureType === 'emoji' ? (
            <div className="header-community_emoji-wrapper">
              {selectedChannelData.communityProfilePicture}
            </div>
          ) : (
            <div className="header-community_logo-wrapper">
              <img
                src={selectedChannelData.communityProfilePicture}
                alt="logo"
              />
            </div>
          )}
          <div className="header-community_text-wrapper">
            <p className="header-community_text-wrapper_title">
              {selectedChannelData.name}
            </p>
            <span className="header-community_text-wrapper_subtitle">
              Tap here for info
            </span>
          </div>
        </button>
      );
    }
    return null;
  }, [activeSection, handleChannelTitleClick, selectedChannelData]);

  const setShowingAction = useCallback(
    (data: any[]) => {
      dispatch(setShowing(data));
    },
    [dispatch],
  );

  const setSortByAction = useCallback(
    (data: any[]) => {
      dispatch(setSortBy(data));
    },
    [dispatch],
  );

  const setFilteredMangoesAction = useCallback(
    (data: any[]) => {
      dispatch(setFilteredMangoes(data));
    },
    [dispatch],
  );

  const setFilteredPostTagsAction = useCallback(
    (data: any[]) => {
      dispatch(
        setPostTags(
          data.map((eachTag) => ({
            _id: eachTag.key,
            tagName: eachTag.value,
            creator: eachTag?.creatorId,
          })),
        ),
      );
    },
    [dispatch],
  );

  const setFilterPostTypeAction = useCallback(
    (data: any[]) => {
      dispatch(setFilters(data));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!headerTitle) {
      setHeaderTitle();
    }
  }, [headerTitle, setHeaderTitle]);

  useEffect(() => {
    return () => {
      dispatch(resetAllFilters());
    };
  }, [dispatch]);

  return (
    <>
      <DynamicHeader
        path={ROUTES.COMMUNITY}
        title={headerTitle}
        beforTitleAction={
          <>
            <button
              className="community-header-before-title-action-button"
              onClick={() => setIsDrawerOpen(true)}>
              <TMIcon name="menu-outline" color={colors.ICON} />
            </button>
          </>
        }
        customTitle={customTitle}
        actionItems={[
          showAddIcon && (
            <button
              className="header--activity__action"
              key="showCreatePost"
              style={{ border: 'none' }}
              onClick={() => {
                if (location?.pathname === ROUTES.COMMUNITY_FEED) {
                  setShowCreatePost(true);
                } else {
                  navigate(ROUTES.COMMUNITY_NEW_MESSAGES);
                }
              }}>
              <TMIcon name="add" color={colors.ICON} />
            </button>
          ),
        ]}>
        {isCommunityChannelPage ? <CommunityChannelTabPannel /> : null}
        {(isCommunityChannelPage && channelPageActiveTab === 'feed') ||
        isCommunityFeedPage ? (
          <div className="tags-filter__container">
            <div className="tags-filter__text">
              <p>
                <Badge
                  overflowCount={9}
                  size="small"
                  style={{
                    backgroundColor: colors.PRIMARY,
                  }}>
                  Filters
                </Badge>
              </p>
            </div>
            <div className="tags-filter__wrapper">
              <div style={{ display: 'flex', margin: 6 }}>
                {isCommunityChannelPage && channelPageActiveTab === 'feed' ? (
                  <>
                    <FilterButtonWithBottomSheet
                      filteredDataList={showing}
                      setFilteredDataAction={setShowingAction}
                      dateList={showingOptionList}
                      searchKey="title"
                      customHeaderTitle={`Showing`}
                      customTitle={` `}
                      customDescription={' '}
                      buttonTitle={showingTitle || 'Showing'}
                      allowClear={false}
                    />

                    <FilterButtonWithBottomSheet
                      filteredDataList={sortBy}
                      setFilteredDataAction={setSortByAction}
                      dateList={sortByOptionList}
                      searchKey="title"
                      customHeaderTitle={`SortBy`}
                      customTitle={` `}
                      customDescription={' '}
                      buttonTitle={sortByTitle || 'SortBy'}
                      allowClear={false}
                    />
                  </>
                ) : null}

                {isCommunityFeedPage ? (
                  <>
                    <FilterButtonWithBottomSheet
                      filteredDataList={filteredMangoes}
                      setFilteredDataAction={setFilteredMangoesAction}
                      dateList={mangoList}
                      searchKey="title"
                      customHeaderTitle={`Select ${hostMetadata?.offeringTitles}`}
                      customTitle={`Select ${hostMetadata?.offeringTitles}`}
                      customDescription={
                        'Choose the services for which you want to see the posts'
                      }
                      noDataFoundTitle={`No ${hostMetadata?.offeringTitles} found`}
                      buttonTitle={hostMetadata?.offeringTitle.toTitleCase()}
                      showSearchBar
                      showSelectAllAndClearSection
                      multiple
                    />
                    <FilterButtonWithBottomSheet
                      filteredDataList={filters}
                      setFilteredDataAction={setFilterPostTypeAction}
                      dateList={postTypeList}
                      searchKey="title"
                      customHeaderTitle={`Select type`}
                      customTitle={`Select post type`}
                      customDescription={
                        'Choose the type of post which you want to see '
                      }
                      noDataFoundTitle={`No tags found`}
                      buttonTitle="Post type"
                      multiple
                    />
                    <FilterButtonWithBottomSheet
                      filteredDataList={postTags.map((eachTag) => ({
                        key: eachTag?._id,
                        value: eachTag?.tagName,
                        creatorId: eachTag?.creator,
                        title: eachTag?.tagName,
                        _id: eachTag?._id,
                      }))}
                      setFilteredDataAction={setFilteredPostTagsAction}
                      dateList={tagList}
                      searchKey="title"
                      customTitle={`Select tag`}
                      customHeaderTitle={`Select tag`}
                      customDescription={
                        'Choose the tags for which you want to see the posts'
                      }
                      noDataFoundTitle={`No tags found`}
                      buttonTitle="Tag"
                      showSearchBar
                      showSelectAllAndClearSection
                      multiple
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <CreatePostChannelBottomSheet
          show={showCreatePost}
          closeModal={() => {
            setShowCreatePost(false);
          }}
        />
        <CommunityChannelDrawer
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      </DynamicHeader>
    </>
  );
};

export default React.memo(CommunityHeader);
