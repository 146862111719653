import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Header from '../../../components/Header/Header';
import SearchBar from '../../../components/Header/SearchBar';
import {
  newMessageUserInitiateState,
  updateNewMessageUserState,
} from '../../../context/communityMessageReducer';
import { useTheme } from '../../../context/ThemeProvider';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../shared/hooks';
import { IChannel } from '../../../types/communityTypes';
import CommunityMembers from './CommunityMembers';
import CommunityUserCard from './CommunityUserCard';
import ListTitle from './ListTitle';

type IGroupWithNoRoomChannels = {
  _id: string;
  name: string;
  channelArr: IChannel[];
};

type Props = {};

const CommunityNewMessage: React.FC<Props> = () => {
  const navigate = useAppNavigate();
  const { colors } = useTheme();
  const dispatch = useAppDispatch();

  const { groupChannelList } = useAppSelector((state) => state?.community);

  const [searchQuery, setSearchQuery] = useState('');

  const handleBack = () => {
    setTimeout(() => {
      navigate.goBack();
    }, 200);
  };

  const groupsWithNoRoomChannels = useMemo(() => {
    return groupChannelList.reduce(
      (acc: IGroupWithNoRoomChannels[], eachGroup) => {
        const channelsWithoutChatRoom = eachGroup.channelArr.filter(
          (eachChannel) =>
            !Boolean(eachChannel?.chatRoom) &&
            eachChannel?.name
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase()),
        );
        if (channelsWithoutChatRoom?.length) {
          return [
            ...acc,
            {
              _id: eachGroup?._id,
              name: eachGroup?.name,
              channelArr: channelsWithoutChatRoom,
            },
          ];
        }
        return [...acc];
      },
      [],
    );
  }, [groupChannelList, searchQuery]);

  const displayChannelCardList = useCallback((list: IChannel[]) => {
    return (
      <>
        {list.map((eachData) => (
          <CommunityUserCard
            user={{
              _id: eachData?._id,
              name: eachData?.name,
              profilePicUrl: eachData?.communityProfilePicture,
              profilePicType: eachData?.communityProfilePictureType,
            }}
            isClickable
            onClick={() => {}}
          />
        ))}
      </>
    );
  }, []);

  const displayChannelList = useCallback(
    (groupData: IGroupWithNoRoomChannels) => {
      return (
        <>
          <ListTitle title={groupData?.name} key={groupData?._id} />
          <div>{displayChannelCardList(groupData?.channelArr)}</div>
        </>
      );
    },
    [displayChannelCardList],
  );

  const displayNoChatRoomChannel = useCallback(() => {
    return groupsWithNoRoomChannels.map((eachGroup) =>
      displayChannelList(eachGroup),
    );
  }, [displayChannelList, groupsWithNoRoomChannels]);

  useEffect(() => {
    return () => {
      dispatch(updateNewMessageUserState(newMessageUserInitiateState));
    };
  }, [dispatch]);

  return (
    <div
      style={{
        width: '100%',
      }}>
      <Header title="New message" handleBack={handleBack} />
      <div className="new-message_search">
        <SearchBar
          onDebounce={(value) => {
            if (setSearchQuery) {
              setSearchQuery(value);
            }
          }}
          placeholder="Search"
          style={{
            width: '100%',
            borderColor: colors.BORDER,
            padding: 0,
          }}
        />
      </div>
      <div className="new-message_list">
        {displayNoChatRoomChannel()}
        <CommunityMembers searchQuery={searchQuery} />
      </div>
    </div>
  );
};

export default CommunityNewMessage;
