import React, { memo, useEffect, useMemo, useState } from 'react';

import { DatePicker, TimePicker } from 'antd';
import moment, { Moment } from 'moment-timezone';
import { BottomSheet } from 'react-spring-bottom-sheet';

import HeaderTextAction from '../../../components/Header/actions/HeaderTextAction';
import Header from '../../../components/Header/Header';
import { localDateTimeToISO } from '../../../shared/utils';

type Props = {
  show: boolean;
  closeModal: () => void;
  handleSave: (dateTime: string) => void;
  scheduleDateTime: string | null;
};

const CommunityPostScheduleModel: React.FC<Props> = ({
  show,
  closeModal,
  handleSave,
  scheduleDateTime,
}) => {
  const [date, setDate] = useState<Moment | null>(
    scheduleDateTime ? moment(scheduleDateTime) : null,
  );
  const [time, setTime] = useState<Moment | null>(
    scheduleDateTime ? moment(scheduleDateTime) : null,
  );

  const loaclISODateTime = useMemo(() => {
    if (!(date && time)) return null;
    const localISODate = localDateTimeToISO(
      date?.format('DD-MM-YYYY') as string,
      time?.format('HH:mm') as string,
    );
    return localISODate;
  }, [date, time]);
  const handleSaveClick = () => {
    const localISODate = localDateTimeToISO(
      date?.format('DD-MM-YYYY') as string,
      time?.format('HH:mm') as string,
    );
    const utc = moment(localISODate).toISOString();
    handleSave(utc);
  };

  useEffect(() => {
    setDate(scheduleDateTime && show ? moment(scheduleDateTime) : null);
    setTime(scheduleDateTime && show ? moment(scheduleDateTime) : null);
  }, [scheduleDateTime, show]);

  return (
    <BottomSheet
      open={show}
      onDismiss={() => {
        closeModal();
      }}
      className="mango__selection__modal"
      header={
        <Header
          title={'Schedule post'}
          canGoBack={false}
          noBorder
          actionItems={[
            <HeaderTextAction text="Done" onClick={() => handleSaveClick()} />,
          ]}
        />
      }>
      <div className="post-schedule_container">
        <div className="post-schedule_container_info-wrapper">
          {loaclISODateTime ? (
            <p>
              {moment(loaclISODateTime).format('ddd, MMM DD, hh:mm A')}{' '}
              {moment.tz.guess()}, based on your location
            </p>
          ) : null}
        </div>
        <div className="post-schedule_container_date-time-wrapper">
          <h3>Date</h3>
          <DatePicker
            disabledDate={(current) => {
              // Can not select days before 2 years ago
              return current && current < moment(new Date());
            }}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode as HTMLElement;
            }}
            format={'DD-MMM-YYYY'}
            value={date}
            size="large"
            onChange={(selectedDate) => {
              if (selectedDate) {
                setDate(selectedDate);
              }
            }}
            allowClear={false}
            showToday={false}
          />
        </div>
        <div className="post-schedule_container_date-time-wrapper">
          <h3>Time</h3>
          <TimePicker
            format={'HH:mm'}
            size="large"
            onChange={(selectedTime) => {
              if (selectedTime) {
                setTime(selectedTime);
              }
            }}
            defaultOpenValue={moment('00:00', 'HH:mm')}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode as HTMLElement;
            }}
            value={time}
          />
        </div>
      </div>
    </BottomSheet>
  );
};

export default memo(CommunityPostScheduleModel);
