import '../styles.scss';

import React, { memo, useState } from 'react';

import { Avatar, Button, Typography } from 'antd';
import { IoCaretDown } from 'react-icons/io5';

import { useAppSelector } from '../../../shared/hooks';
import { IChannel } from '../../../types/communityTypes';
import CreatePostSelectChannelModel from './CreatePostSelectChannelModel';

interface Props {
  openSettingsModel?: boolean;
  setOpenSettingsModel?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedChannelList: IChannel[];
  setSelectedChannelList: React.Dispatch<React.SetStateAction<IChannel[]>>;
  profilePicUrl?: string;
  creatorName?: string;
  customTitle?: string;
  customDescription?: string;
  canEditSubscriberPost?: boolean;
}

const CreateCommunityPostHeader: React.FC<Props> = ({
  profilePicUrl,
  creatorName,
  canEditSubscriberPost,
  selectedChannelList,
  setSelectedChannelList,
}) => {
  const {
    hostMetadata: { creator },
  } = useAppSelector((state) => state.app);
  const { creatorMangoes, subscribedMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );

  const [showSelectChannelModel, setShowSelectedChannelModel] = useState(false);

  return (
    <>
      <div className="create-post-header">
        <Avatar
          size={55}
          src={
            profilePicUrl ||
            (userDetails.type === 'creator_restricted'
              ? creator.profilePicUrl
              : userDetails.profilePic)
          }
        />
        <div className="create-post-header-details">
          <Typography.Title level={5}>
            {creatorName ||
              (userDetails.type === 'creator_restricted'
                ? creator?.name || ''
                : userDetails.name)}
          </Typography.Title>
          <div className="create-post-header-details-mango">
            <Button
              className="siteBtn create-post-header-details-mango-button"
              onClick={() => setShowSelectedChannelModel(true)}
              disabled={canEditSubscriberPost}
              style={{
                opacity: canEditSubscriberPost ? 0.5 : 1,
              }}>
              {selectedChannelList.length > 0 ? (
                <Typography.Text>
                  {selectedChannelList[0].name}{' '}
                  {selectedChannelList.length > 1
                    ? `+${selectedChannelList.length - 1}`
                    : ''}
                </Typography.Text>
              ) : (
                <Typography.Text>Choose where to post</Typography.Text>
              )}
              <IoCaretDown size={14} />
            </Button>
          </div>
        </div>
      </div>
      <CreatePostSelectChannelModel
        show={showSelectChannelModel}
        closeModal={() => setShowSelectedChannelModel(false)}
        handleSave={(selectedChannelsData) => {
          setSelectedChannelList(selectedChannelsData);
          setShowSelectedChannelModel(false);
        }}
        selectedIdList={selectedChannelList.map((each) => each?._id)}
      />
    </>
  );
};

export default memo(CreateCommunityPostHeader);
