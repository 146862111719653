/* eslint-disable @typescript-eslint/naming-convention */

import { ICommunityMessage } from './communityMessagesTypes';
import { IUserDetails } from './userTypes';

export enum SOCKET_BROADCAST_EVENTS {
  USER_JOINED = 'user.joined',
  USER_LEFT = 'user.left',
  ROOM_MESSAGE_CREATED = 'room.message.created',
  ROOM_MESSAGE_EDITED = 'room.message.edited',
  ROOM_MESSAGE_DELETED = 'room.message.deleted',
  ROOM_MESSAGE_REACTED = 'room.message.reacted',
}

export enum SOCKET_ACTION_EVENTS {
  ROOM_JOIN = 'room.join',
  ROOM_MESSAGE_CREATE = 'room.message.create',
  ROOM_MESSAGE_EDIT = 'room.message.edit',
  ROOM_MESSAGE_DELETE = 'room.message.delete',
  ROOM_MESSAGE_REACT = 'room.message.react',
}

export interface SOCKET_BROADCAST_EVENTS_MAP {
  'user.joined': {
    user: Pick<
      IUserDetails,
      '_id' | 'name' | 'email' | 'phone' | 'onboarding' | 'profilePicUrl'
    >;
  };
  'user.left': {
    user: Pick<
      IUserDetails,
      '_id' | 'name' | 'email' | 'phone' | 'onboarding' | 'profilePicUrl'
    >;
  };
  'room.message.created': {
    room: string;
    message: ICommunityMessage;
  };
  'room.message.edited': {
    room: string;
    message: ICommunityMessage;
  };
  'room.message.deleted': {
    room: string;
    message: ICommunityMessage;
  };
  'room.message.reacted': {
    room: string;
    message: ICommunityMessage;
  };
}

export interface SOCKET_ACTION_EVENTS_MAP {
  'room.join': { room: string };
  'room.message.create': {
    room: string;
    message: string;
    assets?: string[];
    threadRoot?: string;
  };
  'room.message.edit': { room: string; messageId: string; message: string };
  'room.message.delete': { room: string; messageId: string };
  'room.message.see': { room: string; messageId: string };
  'room.message.react': { room: string; messageId: string; reaction: string };
}
