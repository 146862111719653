import React from 'react';

import moment from 'moment';
import { PiClock, PiPencilSimpleLight, PiTrash } from 'react-icons/pi';

type Props = {
  scheduleDateTime: string;
  handleSaveShedule: (dateTime: null) => void;
  handleClickOnSchedule: () => void;
};

const CommunityPostScheduleDetails: React.FC<Props> = ({
  scheduleDateTime,
  handleSaveShedule,
  handleClickOnSchedule,
}) => {
  return (
    <div className="schedule-details_container">
      <div className="schedule-details_container_info-wrapper">
        <PiClock />{' '}
        <p>
          Schedule for:{' '}
          {moment(scheduleDateTime).format('ddd, MMM DD, hh:mm A')}
        </p>
      </div>
      <div className="schedule-details_container_actions-wrapper">
        <button
          className="schedule-details_container_actions-wrapper_button schedule-details_container_actions-wrapper_button_edit"
          onClick={() => handleClickOnSchedule()}>
          <PiPencilSimpleLight />
        </button>
        <button
          className="schedule-details_container_actions-wrapper_button schedule-details_container_actions-wrapper_button_remove"
          onClick={() => handleSaveShedule(null)}>
          <PiTrash />
        </button>
      </div>
    </div>
  );
};

export default CommunityPostScheduleDetails;
