import React, {
  memo,
  useEffect,
  useRef,
} from 'react';

import moment from 'moment';
import Linkify from 'react-linkify';

import {
  setReplyMessageData,
  setTouchedMessageData,
} from '../../../context/communityMessageReducer';
import { useAppDispatch } from '../../../shared/hooks';
import { ICommunityMessage } from '../../../types/communityMessagesTypes';
import { TFileTypes } from '../../../types/communityTypes';
import CreatePostMediaRender
  from '../../CreateCommunityPost/components/CreatePostMediaRender';

type Props = {
  message: ICommunityMessage;
  showReplyCard?: boolean;
};

const calculateTime = (dateString: string) => {
  const timeDiffInHrs = moment().diff(moment(dateString), 'hours');
  if (timeDiffInHrs > 24) {
    return moment(dateString).format('hh:mm A');
  }
  return `${timeDiffInHrs} hrs`;
};

const MessagesCard: React.FC<Props> = ({ message, showReplyCard = true }) => {
  const dispatch = useAppDispatch();

  const timerRef = useRef<any>(null);

  const onlongtouch = () => {
    timerRef.current = null;
    dispatch(setTouchedMessageData(message));
  };

  const touchstart = (e: any) => {
    e.stopPropagation();
    if (!timerRef?.current) {
      timerRef.current = setTimeout(onlongtouch, 500);
    }
  };

  const touchend = () => {
    //stops short touches from firing the event
    if (timerRef) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const handleReplyClick = () => {
    dispatch(setReplyMessageData(message));
  };

  useEffect(() => {
    const element = document.getElementById(message?._id);
    if (element) {
      element.addEventListener('touchstart', touchstart, false);
      element.addEventListener('touchend', touchend, false);
      element.addEventListener('touchmove', touchend, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?._id]);

  return (
    <div
      className="chat-card"
      key={message?._id}
      id={message?._id}
      style={{ zIndex: 0 }}>
      {/* avatar */}
      <div className="chat-card_avatar">
        <img src={message?.sender?.profilePicUrl} />
      </div>
      <div className="chat-card_content">
        {/* sender name and time */}
        <div className="chat-card_content_text">
          <p className="chat-card_content_text_name">{message?.sender?.name}</p>
          <p className="chat-card_content_text_time">
            {moment(message?.createdAt).format('hh:mm A')}
          </p>
        </div>
        {/* message content */}
        <div className="chat-card_content_message">
          <Linkify>
            <p>{message?.message}</p>
          </Linkify>
        </div>
        {message.assets?.length ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
            }}>
            {message.assets.map((each) => (
              <CreatePostMediaRender
                key={each?.asset}
                files={[
                  { url: each?.contentUrl, type: each?.fileType as TFileTypes },
                ]}
                isMessageRender
              />
            ))}
          </div>
        ) : null}
        {/* Reaction and reply */}
        <div className="chat-card_content_action">
          {/* Reaction */}
          {Object.values(message?.reactions || {}).some((each) => each > 0) ? (
            <div className="chat-card_content_action_reactions">
              {Object.entries(message?.reactions || {}).map(
                ([emoji, reactionCount]) => (
                  <div className="chat-card_content_action_reactions_emoji">
                    {emoji}{' '}
                    {reactionCount > 99 ? `${reactionCount}+` : reactionCount}
                  </div>
                ),
              )}
            </div>
          ) : null}
          {/* replay user avatar and reply count */}
          {message?.threadRootMetadata?.firstFiveRepliers?.length &&
          showReplyCard ? (
            <div className="chat-card_content_action_replay">
              <div className="chat-card_content_action_replay_">
                {message?.threadRootMetadata?.firstFiveRepliers.map(
                  (eachUser) => (
                    <div className="chat-card_content_action_replay__avtar">
                      <img src={eachUser?.profilePicUrl} />
                    </div>
                  ),
                )}
                {message?.threadRootMetadata?.totalReplies > 2 ? (
                  <div className="chat-card_content_action_replay__avtar chat-card_content_action_replay__count">
                    +
                    {message?.threadRootMetadata?.totalReplies -
                      message?.threadRootMetadata?.firstFiveRepliers?.length}
                  </div>
                ) : null}
              </div>
              <div className="chat-card_content_action_replay_button">
                <button onClick={handleReplyClick}>
                  {message?.threadRootMetadata?.totalReplies}{' '}
                  {message?.threadRootMetadata?.totalReplies === 1
                    ? 'reply'
                    : 'replies'}
                </button>
                <p>
                  Last reply{' '}
                  {calculateTime(message?.threadRootMetadata?.lastRepliedAt)}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default memo(MessagesCard);
