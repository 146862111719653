import React from 'react';

import chatBackground from '../../../assets/img/chat-background.png';

type Props = {
  styles?: React.CSSProperties;
};

const CommunityChatbackgroundLayer: React.FC<Props> = ({ styles }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${chatBackground})`,
        position: 'absolute',
        height: '100vh',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        zIndex: -1,
        opacity: 1,
        filter: 'contrast(25%)',
        backgroundColor: 'darkgoldenrod',
        top: 0,
        backgroundBlendMode: 'soft-light',
        ...styles,
      }}
    />
  );
};

export default CommunityChatbackgroundLayer;
