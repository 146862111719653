import React, { memo, useCallback } from 'react';

import { PiPlayCircleFill } from 'react-icons/pi';
import Slider from 'react-slick';

import audioWave from '../../../assets/img/audio-wave.jpg';
import AudioPlayer from '../../../components/AudioPlayer/AudioPlayer';
import BitmovinPlayer from '../../../components/VideoPlayer/BitmovinPlayer/BitmovinPlayer';
import { IPostMediaLayout, ISelFile } from '../../../types/communityTypes';

type Props = {
  files: ISelFile[];
  selectedLayout?: IPostMediaLayout;
  renderDummy?: boolean;
  isMessageRender?: boolean;
};

const dotSVG = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBNSVQuIE1hZGUgYnkgV2lsbCBLZWxseTogaHR0cHM6Ly93d3cud2lsbC1rZWxseS5jby51ay8gLS0+Cjxzdmcgd2lkdGg9IjgwMHB4IiBoZWlnaHQ9IjgwMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjIiIGZpbGw9IiMwMDAwMDAiLz4KPC9zdmc+`;
const CreatePostMediaRender: React.FC<Props> = ({
  files,
  selectedLayout = 'carousel',
  renderDummy = false,
  isMessageRender = false,
}) => {
  const renderAttachment = useCallback(
    (file: ISelFile) => {
      if (file.type === 'image') {
        return (
          <div
            style={
              isMessageRender
                ? { width: '100%', aspectRatio: 'auto', border: 'none' }
                : {}
            }
            className={`createpost__attachment createpost__attachment__image
              ${
                selectedLayout === 'carousel'
                  ? ''
                  : 'createpost__attachment__collage-image'
              }`}>
            <img src={file.url} alt={file.name} />
          </div>
        );
      } else if (file.type === 'video') {
        if (selectedLayout === 'collage' || renderDummy) {
          return (
            <div
              className="audio-dummy-img"
              style={{ filter: 'brightness(70%)' }}>
              <PiPlayCircleFill />
              {renderAttachment({
                url: dotSVG,
                file: undefined as unknown as File,
                name: 'audioWave',
                ext: '.png',
                type: 'image',
              })}
            </div>
          );
        }

        return (
          <div className="createpost__attachment createpost__attachment__video">
            <BitmovinPlayer
              mediaUrl={file.url}
              isCompressDone
              mediaId={`create-post-video_${file.name}`}
            />
          </div>
        );
      } else if (file.type === 'audio') {
        if (selectedLayout === 'collage' || renderDummy) {
          return (
            <div
              className="audio-dummy-img"
              style={{ filter: 'brightness(70%)' }}>
              <PiPlayCircleFill />
              {renderAttachment({
                url: audioWave,
                file: undefined as unknown as File,
                name: 'audioWave',
                ext: '.png',
                type: 'image',
              })}
            </div>
          );
        }
        return (
          <div className="createpost__attachment createpost__attachment__audio">
            <AudioPlayer id="create-post-audio" url={file.url} />
          </div>
        );
      }
    },
    [isMessageRender, renderDummy, selectedLayout],
  );

  const displayMediaInCarousel = useCallback(
    (fileList: ISelFile[]) => {
      return (
        <Slider
          className="mangoMediaCarousel"
          easing="linear"
          arrows
          swipeToSlide
          dots={false}
          slidesToShow={1}
          infinite={false}>
          {fileList.map((eachMedia) => (
            <>
              <div>{renderAttachment(eachMedia)}</div>
            </>
          ))}
        </Slider>
      );
    },
    [renderAttachment],
  );

  const displaySingleMedia = (filesList: ISelFile[]) => {
    return (
      <>
        {filesList.map((eachMedia) => (
          <div>{renderAttachment(eachMedia)}</div>
        ))}
      </>
    );
  };

  const displayTwoMedia = (filesList: ISelFile[]) => {
    return (
      <div className="two-media_container">
        {filesList.map((eachMedia) => renderAttachment(eachMedia))}
      </div>
    );
  };

  const displayThreeMedia = (filesList: ISelFile[]) => {
    const [firstMediaObj, ...remainingObj] = filesList;
    return (
      <div className="three-media_container">
        <div className="three-media_container_first-media">
          {renderAttachment(firstMediaObj)}
        </div>
        <div className="three-media_container_remaining-media">
          {remainingObj.map((eachMedia) => renderAttachment(eachMedia))}
        </div>
      </div>
    );
  };

  const displayFourMedia = (filesList: ISelFile[]) => {
    const [firstMediaObj, secMediaObj, ...remainingObj] = filesList;
    return (
      <div className="four-media_container">
        <div className="four-media_container_wrapper">
          {[firstMediaObj, secMediaObj].map((eachMedia) =>
            renderAttachment(eachMedia),
          )}
        </div>
        <div className="four-media_container_wrapper">
          {remainingObj.map((eachMedia) => renderAttachment(eachMedia))}
        </div>
      </div>
    );
  };

  const displayFiveMedia = (filesList: ISelFile[]) => {
    const [firstMediaObj, secMediaObj, ...remainingObj] = filesList;
    return (
      <div className="five-media_container">
        <div className="five-media_container_two-media-wrapper">
          {[firstMediaObj, secMediaObj].map((eachMedia) =>
            renderAttachment(eachMedia),
          )}
        </div>
        <div className="five-media_container_three-media-wrapper">
          {remainingObj.map((eachMedia) => renderAttachment(eachMedia))}
        </div>
      </div>
    );
  };

  const displayFivePlusMedia = (filesList: ISelFile[]) => {
    const [
      firstMediaObj,
      secMediaObj,
      thirdMediaObj,
      fourthMediaObj,
      fifthMediaObj,
      ...remainingObj
    ] = filesList;
    return (
      <div className="five-media_container">
        <div className="five-media_container_two-media-wrapper">
          {[firstMediaObj, secMediaObj].map((eachMedia) =>
            renderAttachment(eachMedia),
          )}
        </div>
        <div className="five-media_container_three-media-wrapper five-media_container_remaining-count">
          {[thirdMediaObj, fourthMediaObj, fifthMediaObj].map((eachMedia) =>
            renderAttachment(eachMedia),
          )}
          <div className="createpost__attachment createpost__attachment__image five-media_container_remaining-count_wrapper">
            +{remainingObj?.length}
          </div>
        </div>
      </div>
    );
  };

  const displayMediaInCollage = (filesList: ISelFile[]) => {
    switch (filesList?.length) {
      case 0:
        return null;
      case 1:
        return displaySingleMedia(filesList);
      case 2:
        return displayTwoMedia(filesList);
      case 3:
        return displayThreeMedia(filesList);
      case 4:
        return displayFourMedia(filesList);
      case 5:
        return displayFiveMedia(filesList);
      default:
        return displayFivePlusMedia(filesList);
    }
  };

  if (selectedLayout === 'carousel' && files?.length > 1 && !renderDummy) {
    return <div>{displayMediaInCarousel(files)}</div>;
  }
  return <div>{displayMediaInCollage(files)}</div>;
};

export default memo(CreatePostMediaRender);
