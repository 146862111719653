import React, { useState } from 'react';

import { Button, message, Space } from 'antd';
import moment from 'moment';

import FeedAPI from '../../../../api/FeedAPI';
import { setLikedByUsersModalState } from '../../../../context/activityReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import showAppError from '../../../../shared/error';
import { useAppDispatch } from '../../../../shared/hooks';
import { nFormat, timeAgo } from '../../../../shared/utils';

interface IPollOption {
  _id: string;
  optionType: string;
  optionContent: string;
  optionNumber: number;
  optionId: string;
}

interface IPostPollProps {
  pollId: string;
  question: string;
  options: IPollOption[];
  onOptionClicked?: (index: string) => void;
  isAnswered?: boolean;
  totalVotes?: number;
  individualVotes?: Record<string, number>;
  answerOption?: string;
  endDate: Date;
  isOwnPost: boolean;
  updatePost: (newPoll: any) => void;
}

const PostPoll: React.FC<IPostPollProps> = ({
  pollId,
  question,
  options,
  onOptionClicked,
  answerOption,
  isAnswered,
  totalVotes,
  individualVotes,
  endDate,
  isOwnPost,
  updatePost,
}) => {
  const { colors } = useTheme();

  const dispatch = useAppDispatch();

  const showAnswer = moment().isAfter(endDate) || isAnswered;
  const votePercent =
    totalVotes && totalVotes > 0 && individualVotes
      ? Object.values(individualVotes).map((vote) => (vote / totalVotes) * 100)
      : new Array(options.length).fill(0);

  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const viewVotedByUsersList = () => {
    dispatch(
      setLikedByUsersModalState({
        visible: true,
        pollId,
        commentId: '',
        postId: '',
      }),
    );
  };

  const vote = async (optionId: string) => {
    try {
      setLoading(true);
      const response = await FeedAPI.votePoll(pollId, optionId);
      if (response.status === 200) {
        updatePost(response.data.result);
        message.success('Vote submitted successfully');
      } else {
        showAppError(response.data);
      }
    } catch (err: any) {
      showAppError(err);
    } finally {
      setLoading(false);
    }
  };

  const undoVote = async () => {
    try {
      setLoading(true);
      const response = await FeedAPI.undoVotePoll(pollId);
      if (response.status === 200) {
        updatePost(response.data.result);
        message.success('Vote removed successfully');
      } else {
        showAppError(response.data);
      }
    } catch (err: any) {
      showAppError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="feedPollSystem">
      <div className="feedPollSystemInner">
        <h1>{question?.trim()}</h1>
        {showAnswer || showResult ? (
          <div className="pollAnswersWrapper">
            {options.map((option, index) => (
              <div key={option.optionId} className="pollAnswer">
                <div
                  className="pollAnswerProgress"
                  style={{ width: `${votePercent[index]}%` }}
                />
                <div className="pollOptionText">
                  {option.optionContent?.trim()}
                </div>
                <div className="pollOptionPercentage">
                  {Math.floor(votePercent[index] || 0)}%
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="pollOptionsWrapper">
            {options.map((option) => (
              <Button
                key={option.optionId}
                className="siteBtn noStyle pollOption"
                block
                disabled={loading}
                loading={loading}
                onClick={() => {
                  if (moment(endDate || new Date()).isAfter(moment())) {
                    if (onOptionClicked) onOptionClicked(option.optionId);
                    vote(option.optionId);
                  } else {
                    message.error('Poll has ended');
                  }
                }}>
                {option.optionContent?.trim()}
              </Button>
            ))}
          </div>
        )}
        <Space
          align="center"
          split={<span>•</span>}
          size={4}
          style={{ fontSize: 12, color: colors.TEXT3 }}>
          <div onClick={viewVotedByUsersList}>
            {nFormat(totalVotes || 0, 999)} votes
          </div>
          {moment().isBefore(endDate) ? (
            <div>{timeAgo(endDate)} left</div>
          ) : null}
          {answerOption && moment().isBefore(endDate) ? (
            <Button className="siteBtn siteBtnLink sm" onClick={undoVote}>
              Undo
            </Button>
          ) : null}
          {isOwnPost && !showAnswer ? (
            <Button
              className="siteBtn siteBtnLink blue sm"
              onClick={() => setShowResult((prev) => !prev)}>
              {showResult ? 'Hide' : 'View'} result
            </Button>
          ) : null}
        </Space>
      </div>
    </div>
  );
};

export default React.memo(PostPoll);
