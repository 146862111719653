import {
  ICommunityMessage,
  ICommunityMessageRoom,
  ICommunityMessageSender,
  IMessageRoomParticipant,
} from '../types/communityMessagesTypes';
import API from './';

export default class CommunityMessagesAPI {
  static root = 'api/v1/community';

  static getMessageRoomList = () =>
    API.get<ICommunityMessageRoom[]>(`${this.root}/chatrooms`);

  static getNewMessageUser = (query: string) =>
    API.get<IMessageRoomParticipant[]>(
      `${this?.root}/channels/participants?${query}`,
    );

  static getCommuiniyMessageRoomDetails = (roomId: string) =>
    API.get<ICommunityMessageRoom>(`${this.root}/chatrooms/${roomId}`);

  static getRoomMessage = (roomId: string, query: string) =>
    API.get<ICommunityMessage[]>(
      `${this.root}/chatrooms/${roomId}/messages?${query}`,
    );

  static pinnedMessage = (roomId: string, messageId: string, query: string) =>
    API.patch(
      `${this.root}/chatrooms/${roomId}/messages/${messageId}/pinned?${query}`,
    );

  static getRepliesOnThread = (
    roomId: string,
    threadRootId: string,
    query: string,
  ) =>
    API.get<{
      lastSeenReply: string;
      unreadCount: number;
      replies: ICommunityMessage[];
    }>(
      `${this.root}/chatrooms/${roomId}/messages/${threadRootId}/thread?${query}`,
    );

  static getSeenAndReactionUserList = (roomId: string, messageId: string) =>
    API.get<ICommunityMessageSender[]>(
      `${this.root}/chatrooms/${roomId}/messages/${messageId}/seen-and-reacted-users`,
    );
}
