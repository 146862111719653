import React, { memo } from 'react';

import { PiSlideshowFill } from 'react-icons/pi';
import { RiLayout4Fill } from 'react-icons/ri';
import { BottomSheet } from 'react-spring-bottom-sheet';

import Header from '../../../components/Header/Header';
import { IPostMediaLayout } from '../../../types/communityTypes';

type Props = {
  show: boolean;
  closeModal: () => void;
  handleSave: (layout: IPostMediaLayout) => void;
  selectedLayout: IPostMediaLayout;
};

const CommunityPostChooseLayoutModel: React.FC<Props> = ({
  show,
  closeModal,
  handleSave,
  selectedLayout,
}) => {
  return (
    <BottomSheet
      open={show}
      onDismiss={() => {
        closeModal();
      }}
      className="mango__selection__modal"
      header={<Header title={'Choose layout'} canGoBack={false} noBorder />}>
      <div className="layout_container">
        <button
          className={`layout_container_button ${
            selectedLayout === 'carousel'
              ? 'layout_container_active-button'
              : ''
          }`}
          onClick={() => {
            handleSave('carousel');
            closeModal();
          }}>
          <div
            className={`layout_container_button_wrapper ${
              selectedLayout === 'carousel'
                ? 'layout_container_active-button_wrapper'
                : ''
            }`}>
            <PiSlideshowFill />
            <p>Carousel</p>
          </div>
        </button>
        <button
          className={`layout_container_button ${
            selectedLayout === 'collage' ? 'layout_container_active-button' : ''
          }`}
          onClick={() => {
            handleSave('collage');
            closeModal();
          }}>
          <div
            className={`layout_container_button_wrapper ${
              selectedLayout === 'collage'
                ? 'layout_container_active-button_wrapper'
                : ''
            }`}>
            <RiLayout4Fill style={{ transform: 'rotate(-90deg)' }} />
            <p>Collage</p>
          </div>
        </button>
      </div>
    </BottomSheet>
  );
};

export default memo(CommunityPostChooseLayoutModel);
