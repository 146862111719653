import './styles.scss';

import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Input, message } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { PiGear, PiSmileyFill } from 'react-icons/pi';

import CommunityAPI from '../../api/CommunityAPI';
import EmojiPickerModel from '../../components/EmojiPickerModel/EmojiPickerModel';
import HeaderIcon from '../../components/Header/actions/HeaderIcon';
import HeaderTextAction from '../../components/Header/actions/HeaderTextAction';
import Header from '../../components/Header/Header';
import { updateCommunitPost } from '../../context/communityReducer';
import showAppError from '../../shared/error';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  IChannel,
  IChannelPost,
  IEditPostReqBody,
  IPostReqBodyPostSettings,
} from '../../types/communityTypes';
import CommunityPostSettingsModel from './components/CommunityPostSettings';
import CreatePostAttachment from './components/CreateCommunityPostAttachment';
import CreateCommunityPostHeader from './components/CreateCommunityPostHeader';

interface Props {
  post: IChannelPost;
  onEditCallback?: (newPost: any) => void;
  handleBack: () => void;
  canEditSubscriberPost?: boolean;
  postId: string;
}

const EditCommunityPost: React.FC<Props> = ({
  post,
  handleBack,
  canEditSubscriberPost = false,
  postId,
}) => {
  const dispatch = useAppDispatch();
  const { selectedChannelData } = useAppSelector((state) => state.community);
  const channelId = useMemo(() => {
    return selectedChannelData?._id;
  }, [selectedChannelData?._id]);

  const [description, setDescription] = useState(post.caption || '');
  const [openSettingsModel, setOpenSettingsModel] = useState(false);
  const [openEmojiModel, setOpenEmojiModel] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedChannelList, setSelectedChannelList] = useState<IChannel[]>(
    post?.channels,
  );

  const [postSettings, setPostSettings] = useState<IPostReqBodyPostSettings>(
    post.postSettings,
  );

  const textareaRef = useRef<TextAreaRef | null>(null);

  const onSelectEmoji = useCallback((emoji: string) => {
    if (!textareaRef.current) return;

    const input = textareaRef.current;

    const textarea = input?.resizableTextArea?.textArea;

    if (!textarea) return;

    let selectionStart = textarea?.selectionStart;
    let selectionEnd = textarea?.selectionEnd;

    if (selectionStart === null || selectionEnd === null) return;

    if (
      selectionStart === 0 &&
      selectionEnd === 0 &&
      textarea?.textLength > 0
    ) {
      selectionStart = textarea?.textLength;
      selectionEnd = textarea?.textLength;
    }
    setDescription(
      (prev) =>
        prev.slice(0, selectionStart) + emoji + prev.slice(selectionEnd),
    );
    const newCursor = selectionStart + emoji.length;
    setTimeout(() => textarea.setSelectionRange(newCursor, newCursor), 10);
    textarea.focus();
  }, []);

  const handleCloseEmojiModel = () => {
    setOpenEmojiModel(false);
  };

  const handleClickOnEmoji = () => {
    setOpenEmojiModel(true);
  };

  const handleClickOnSettings = () => {
    setOpenSettingsModel(true);
  };

  const handleCloseSettingsModel = () => {
    setOpenSettingsModel(false);
  };
  const handleSaveSettings = (updatedSettings: IPostReqBodyPostSettings) => {
    setPostSettings(updatedSettings);
  };

  const submit = async () => {
    setSaving(true);

    try {
      const reqBody: IEditPostReqBody = {
        channels: selectedChannelList?.map((each) => each?._id),
        caption: description,
        postSettings,
      };
      const res = await CommunityAPI.editPost(
        channelId as string,
        postId,
        reqBody,
      );
      if (res.status === 200) {
        dispatch(updateCommunitPost(res?.data?.result?.[0]));

        setSaving(false);
        handleBack();
      } else {
        showAppError(res.data);
      }
    } catch (err) {
      showAppError(err);
    } finally {
      setSaving(false);
    }
  };

  const submitPost = () => {
    if (description.length > 0 && selectedChannelList.length > 0) {
      submit();
    } else {
      if (selectedChannelList.length === 0 || post?.mediaContent?.length) {
        message.error(`Please select channel to publish`);
        return;
      }
      message.error(
        'Please add some description as original post has no media',
      );
    }
  };

  const postContentOptions: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
  }[] = useMemo(() => {
    const iconSize = 24;
    const items = [
      {
        icon: <PiSmileyFill size={iconSize} fill="#FFB700" />,
        label: 'Emoji',
        backgroundColor: '#FFF3D0',
        onClick: () => {
          handleClickOnEmoji();
        },
      },
    ];
    return items;
  }, []);

  const isDisabled = useMemo(
    (): boolean =>
      !(description || post?.mediaContent?.length) ||
      saving ||
      selectedChannelList.length === 0,
    [
      description,
      post?.mediaContent?.length,
      saving,
      selectedChannelList.length,
    ],
  );

  return (
    <>
      <div className="createpost__container">
        <Header
          title="Edit Post"
          backType="cross"
          handleBack={handleBack}
          actionItems={[
            <HeaderIcon
              icon={
                <div className="header-icon-wrapper">
                  <PiGear />
                </div>
              }
              onClick={handleClickOnSettings}
            />,
            <HeaderTextAction
              text="Save"
              disabled={isDisabled}
              onClick={submitPost}
              loading={saving}
              loadingText="Saving..."
              style={{ margin: '0 20px' }}
            />,
          ]}
        />

        <div className="scroll__container">
          <CreateCommunityPostHeader
            selectedChannelList={selectedChannelList}
            setSelectedChannelList={setSelectedChannelList}
            profilePicUrl={post?.postedBy?.profilePicUrl}
            creatorName={post?.postedBy?.name}
            canEditSubscriberPost={canEditSubscriberPost}
          />

          <div className="createpost__description__container">
            <Input.TextArea
              className="createpost__description"
              bordered={false}
              placeholder="Add a caption..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoSize={{ minRows: 3 }}
              disabled={canEditSubscriberPost}
              style={{
                opacity: canEditSubscriberPost ? 0.25 : 1,
              }}
              ref={(ref) => (textareaRef.current = ref)}
            />
          </div>
        </div>

        <CreatePostAttachment options={postContentOptions} />
        <CommunityPostSettingsModel
          show={openSettingsModel}
          closeModal={handleCloseSettingsModel}
          handleSave={handleSaveSettings}
          postSettings={postSettings}
        />
        <EmojiPickerModel
          show={openEmojiModel}
          onSelectEmoji={onSelectEmoji}
          closeModal={handleCloseEmojiModel}
        />
      </div>
    </>
  );
};

export default React.memo(EditCommunityPost);
