import React, { memo, useCallback, useMemo, useState } from 'react';

import NoDataFound from '../../../screens/Feed/components/NoDataFound/NoDataFound';
import MangoSelectionModal from '../../MangoSelectionModal/MangoSelectionModal';
import FilterButton from './FilterButtonWrapper';

type Props = {
  filteredDataList: any[];
  setFilteredDataAction: (dataList: any[]) => void;
  dateList: any[];
  searchKey: string;
  customHeaderTitle?: string;
  customTitle?: string;
  customDescription?: string;
  noDataFoundTitle?: string;
  buttonTitle: string;
  showSearchBar?: boolean;
  showSelectAllAndClearSection?: boolean;
  multiple?: boolean;
  allowClear?: boolean;
};

const FilterButtonWithBottomSheet: React.FC<Props> = ({
  filteredDataList,
  setFilteredDataAction,
  dateList,
  searchKey,
  customHeaderTitle,
  customTitle,
  customDescription,
  noDataFoundTitle,
  buttonTitle,
  showSearchBar = false,
  showSelectAllAndClearSection = false,
  multiple = false,
  allowClear = true,
}) => {
  const [isMangoModalOpen, setIsMangoModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const searchedDataList = useMemo(() => {
    const tempMangoList = [...dateList];

    const newFilteredMangoList = tempMangoList.reduce((acc: any[], each) => {
      if (
        each[searchKey] &&
        each[searchKey].toLowerCase().match(searchQuery.toLowerCase())
      ) {
        return [...acc, each];
      }
      return acc;
    }, []);
    return newFilteredMangoList;
  }, [dateList, searchKey, searchQuery]);

  const handleSaveAction = useCallback(
    (selectedDataIds: string[]) => {
      const newfilteredDataList = dateList.filter((data: any) =>
        selectedDataIds.includes(data._id),
      );
      setFilteredDataAction(newfilteredDataList);
    },
    [dateList, setFilteredDataAction],
  );

  return (
    <FilterButton
      buttonTitle={buttonTitle}
      filteredDataList={filteredDataList}
      modalOpenAction={() => setIsMangoModalOpen(true)}>
      <MangoSelectionModal
        mangoes={searchedDataList}
        selectedMangoes={filteredDataList}
        handleSave={(m, closeModel = false) => {
          setIsMangoModalOpen(closeModel);
          handleSaveAction(m as string[]);
          if (closeModel) {
            setSearchQuery('');
          }
        }}
        customHeaderTitle={customHeaderTitle}
        customTitle={customTitle}
        customDescription={customDescription}
        show={isMangoModalOpen}
        closeModal={() => setIsMangoModalOpen(false)}
        noMangoComponent={<NoDataFound title={noDataFoundTitle} />}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        showSearchBar={showSearchBar}
        showSelectAllAndClearSection={showSelectAllAndClearSection}
        type={multiple ? 'multiple' : 'single'}
        allowClear={allowClear}
      />
    </FilterButton>
  );
};

export default memo(FilterButtonWithBottomSheet);
