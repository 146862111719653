import React, { useMemo, useState } from 'react';

import { Button, message, Space } from 'antd';
import moment from 'moment';
import { HiChevronRight } from 'react-icons/hi2';

import CommunityAPI from '../../../../api/CommunityAPI';
import { updateCommunitPost } from '../../../../context/communityReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import showAppError from '../../../../shared/error';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { nFormat, timeAgo } from '../../../../shared/utils';
import { IChannelPostDetails } from '../../../../types/communityTypes';
import { Onboarding } from '../../../../types/userTypes';

type Props = {
  communityPostDetails: IChannelPostDetails;
};

const CommunityPostPoll: React.FC<Props> = ({ communityPostDetails }) => {
  const dispatch = useAppDispatch();
  const { selectedChannelData } = useAppSelector((state) => state.community);
  const channelId = useMemo(() => {
    return selectedChannelData?._id;
  }, [selectedChannelData?._id]);
  const userDetails = useAppSelector((state) => state.user);
  const { post, ...postDetails } = communityPostDetails;
  const { poll } = post;
  const { colors } = useTheme();
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);

  // const isAnswered = useMemo(() => {
  //   return Boolean(communityPostDetails?.post?.poll?.votedOption);
  // }, [communityPostDetails?.post?.poll]);

  const vote = async (optionId: string) => {
    if (!optionId) {
      message.warning('Something want wrong, please refresh and try again');
      return;
    }
    if (postDetails?.votedOption) {
      if (poll?.allowVoteChange) {
        message.info(
          'You alreay voted, but if you wish to change your vote click undo',
        );
        return;
      }
      message.info('You alreay voted');
      return;
    }
    try {
      setLoading(true);
      const resp = await CommunityAPI.addVoteOnCommunityPoll(
        channelId as string,
        communityPostDetails?._id,
        { option: optionId },
      );
      if (resp?.status === 200) {
        const newVoteCountObj = postDetails?.pollVotesPerOption;
        if (newVoteCountObj?.[optionId]) {
          newVoteCountObj[optionId] = (newVoteCountObj?.[optionId] || 0) + 1;
        }
        const newTotalVotes = (postDetails?.totalPollVotes || 0) + 1;

        dispatch(
          updateCommunitPost({
            ...communityPostDetails,
            votedOption: optionId,
            pollVotesPerOption: newVoteCountObj,
            totalPollVotes: newTotalVotes,
          }),
        );
      } else {
        showAppError(resp?.data);
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setLoading(false);
    }
  };

  const viewVotedByUsersList = () => {};

  const undoVote = async (optionId: string) => {
    if (!optionId) {
      message.warning('Something want wrong, please refresh and try again');
      return;
    }
    try {
      setLoading(true);
      const resp = await CommunityAPI.removeVoteOnCommunityPoll(
        channelId as string,
        communityPostDetails?._id,
        { option: optionId },
      );
      if (resp?.status === 200) {
        const newVoteCountObj = postDetails?.pollVotesPerOption;
        if (newVoteCountObj?.[optionId]) {
          newVoteCountObj[optionId] = newVoteCountObj?.[optionId] - 1;
        }
        const newTotalVotes = (postDetails?.totalPollVotes || 0) - 1;

        dispatch(
          updateCommunitPost({
            ...communityPostDetails,
            votedOption: false,
            pollVotesPerOption: newVoteCountObj,
            totalPollVotes: newTotalVotes,
          }),
        );
      } else {
        showAppError(resp?.data);
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setLoading(false);
    }
  };

  const isUserAllowViewVote = useMemo(() => {
    return (
      userDetails?.id === post?.postedBy?._id ||
      poll?.isResultVisibleToMembers ||
      userDetails?.type === Onboarding.CREATOR_COMPLETED
    );
  }, [
    poll?.isResultVisibleToMembers,
    post?.postedBy?._id,
    userDetails?.id,
    userDetails?.type,
  ]);

  const votingPerObj: { [key: string]: string } = useMemo(() => {
    const perObj = poll?.pollOptions.reduce((acc, eachOptio) => {
      const optionPerVote =
        postDetails?.pollVotesPerOption?.[eachOptio?.optionId] || 0;
      const totalVotes = postDetails?.totalPollVotes || 1;
      const per = (optionPerVote / totalVotes) * 100;
      Object.assign(acc, { [eachOptio?.optionId]: per.toFixed(2) });
      return acc;
    }, {});
    return perObj || {};
  }, [
    poll?.pollOptions,
    postDetails?.pollVotesPerOption,
    postDetails?.totalPollVotes,
  ]);

  return (
    <div className="community-feed-poll_contailer">
      <div className="community-feed-poll_contailer_wrapper">
        <h1>{poll?.question?.trim()}</h1>
        {showResult ? (
          <div className="community-feed-poll_contailer_poll-answer-wrapper">
            {poll?.pollOptions.map((option) => (
              <div
                key={option.optionId}
                className="community-feed-poll_contailer_poll-answer-wrapper_poll-answer">
                <div
                  className="community-feed-poll_contailer_poll-answer-wrapper_poll-answer_option-progress"
                  style={{
                    width: `${votingPerObj?.[option?.optionId] || 0}%`,
                  }}
                />
                <div className="community-feed-poll_contailer_poll-answer-wrapper_poll-answer_option-text">
                  {option.optionContent?.trim()}
                </div>
                <div className="community-feed-poll_contailer_poll-answer-wrapper_poll-answer_option-per">
                  {votingPerObj?.[option?.optionId] || 0}% <HiChevronRight />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="community-feed-poll_contailer_poll-option-wrapper">
            {poll?.pollOptions.map((option) => (
              <Button
                key={option.optionId}
                className={`siteBtn noStyle community-feed-poll_contailer_poll-option-wrapper_poll-option ${
                  postDetails?.votedOption === option.optionId ? 'active' : ''
                }`}
                block
                disabled={
                  loading ||
                  (Boolean(postDetails?.votedOption) &&
                    postDetails?.votedOption !== option.optionId)
                }
                loading={loading}
                onClick={() => {
                  if (moment(poll.pollEndAt || new Date()).isAfter(moment())) {
                    vote(option.optionId);
                  } else {
                    message.error('Poll has ended');
                  }
                }}>
                {option.optionContent?.trim()}
              </Button>
            ))}
          </div>
        )}
        <Space
          align="center"
          split={<span>•</span>}
          size={4}
          style={{ fontSize: 12, color: colors.TEXT3 }}>
          <div onClick={viewVotedByUsersList}>
            {nFormat(postDetails?.totalPollVotes || 0, 999)} votes
          </div>
          {moment().isBefore(poll?.pollEndAt) ? (
            <div>{timeAgo(poll?.pollEndAt)} left</div>
          ) : null}
          {poll?.allowVoteChange && moment().isBefore(poll?.pollEndAt) ? (
            <Button
              className="siteBtn siteBtnLink sm"
              onClick={() => undoVote(postDetails?.votedOption as string)}>
              Undo
            </Button>
          ) : null}
          {isUserAllowViewVote ? (
            <Button
              className="siteBtn siteBtnLink blue sm"
              onClick={() => setShowResult((prev) => !prev)}>
              {showResult ? 'Hide' : 'View'} result
            </Button>
          ) : null}
        </Space>
      </div>
    </div>
  );
};

export default CommunityPostPoll;
