import React from 'react';

import { Typography } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';

import PlusSvg from '../../../assets/svg/PlusSvg';
import TickSvg from '../../../assets/svg/TickSvg';
import { DayMap } from '../../../shared/constants';

type Props = {
  show: boolean;
  closeModel: () => void;
  saveChanges: (option: string | number) => void;
  selectedValue: string;
};

const CommunityPollSelectDurationModel: React.FC<Props> = ({
  show,
  closeModel,
  saveChanges,
  selectedValue,
}) => {
  return (
    <BottomSheet
      open={show}
      onDismiss={() => closeModel()}
      className="select__bottomSheet">
      <ul className="select__options">
        {Object.keys(DayMap).map((option) => (
          <li
            className="select__option"
            onClick={() => {
              saveChanges(option);
              closeModel();
            }}>
            <Typography.Text className="select__option__text">
              {DayMap[option]}
            </Typography.Text>
            {selectedValue.includes(option) ? <TickSvg /> : <PlusSvg />}
          </li>
        ))}
      </ul>
    </BottomSheet>
  );
};

export default CommunityPollSelectDurationModel;
