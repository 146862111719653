export interface IChannelGroup {
  _id: string;
  host: string;
  creator: string;
  name: string;
  hideFromNonChannelMember: boolean;
  rank: number;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  channelArr: IChannel[];
}

export enum CommunityChannelTabs {
  FEED = 'feed',
  MESSAGES = 'messages',
}

export type ICommunityChannelTabTypes = `${CommunityChannelTabs}`;

export interface IChannel {
  _id: string;
  name: string;
  description: string;
  creator: string;
  mangoArr: string[];
  badgeArr: string[];
  groupArr: string[];
  visibility: string;
  features: ICommunityChannelTabTypes[];
  archived: boolean;
  communityProfilePicture: string;
  communityProfilePictureType: string;
  createdAt: string;
  featureSettings: IFeatureSettings;
  updatedAt: string;
  chatRoom?: string;
}

export interface IComment {
  _id: string;
  creator: string;
  channel: string;
  post: string;
  mentions: string[];
  repliedComment: string;
  comment: string;
  images: string[];
  totalLikes: number;
  totalReplies: number;
  updatedAt: string;
  createdAt: string;
  commentedBy: ICommentedBy;
  isLiked: boolean;
  parentCommentId?: string;
}

export interface ICommentedBy {
  _id: string;
  name: string;
  profilePicUrl: string;
  isBlockedFromCommunityEngagement: boolean;
}

export interface ILink {
  _id: string;
  host: string;
  creator: string;
  link: string;
  title: string;
  rank: number;
}

interface IFeatureSettings {
  feed: IFeedSettings;
  messages: IMessagesSettings;
}

interface IMessagesSettings {
  allowEveryoneToMessage: boolean;
  allowPeerToPeerMessaging: boolean;
}

interface IFeedSettings {
  allowMemberPosts: boolean;
}

export interface IPostState {
  loading: boolean;
  page: number;
  hasMore: boolean;
  posts: IChannelPostDetails[];
  refreshing: boolean;
}

export interface IPostProgressData {
  post?: any;
  message: string;
  loaded: number;
  total: number;
  showProgress: boolean;
}

export enum SortBy {
  Latest = 'latest',
  Oldest = 'oldest',
  TrendingNow = 'trending_now',
  MostPopular = 'most_popular',
}

export type ISortBy = `${SortBy}`;

export enum Showing {
  MyPosts = 'my_posts',
  PostsFromSubscribers = 'posts_from_subscribers',
  Everything = 'everything',
}

export type IShowing = `${Showing}`;

export interface ICommunitySlice {
  filteredMangoes: any[];
  showing: { title: string; _id: IShowing }[];
  sortBy: { title: string; _id: ISortBy }[];
  activeSection: string;
  headerTitle: string;
  headerIcon: Element | null;
  groupChannelList: IChannelGroup[];
  channelPageActiveTab: ICommunityChannelTabTypes;
  selectedChannelData?: IChannel | null;
  communityLinkList: ILink[];
  communityPostState: IPostState;
  postProgressData: IPostProgressData;
  mediaProgressData: IPostProgressData;
  communityViewedPostIds: string[];
  pdpCommunityPostData: null | IChannelPostDetails;
  communityPostComments: IComment[];
  replyComment: IComment | null;
  replayCommentsObj: { [key: string]: IComment[] };
}

export interface IPollOption {
  value: string;
  _id: string;
}

export interface IChannelPostDetails {
  _id: string;
  postedBy: string;
  channel: string;
  isPinned: boolean;
  totalViews: number;
  totalLikes: number;
  totalComments: number;
  totalParentComments: number;
  totalRepost: number;
  scheduleAt: string;
  updatedAt: string;
  createdAt: string;
  post: IChannelPost;
  isLiked: boolean;
  totalPollVotes?: number;
  pollVotesPerOption?: { [key: string]: number };
  votedOption?: string | boolean;
}

export type IPostDetailsWithoutPost = Pick<
  IChannelPostDetails,
  | '_id'
  | 'postedBy'
  | 'channel'
  | 'isPinned'
  | 'totalViews'
  | 'totalLikes'
  | 'totalComments'
  | 'totalParentComments'
  | 'totalRepost'
  | 'scheduleAt'
  | 'updatedAt'
  | 'createdAt'
  | 'isLiked'
>;

interface IPostPollOption {
  optionContent: string;
  optionNumber: number;
  optionId: string;
}

interface IPostedBy {
  _id: string;
  name: string;
  email: string;
  phone: number;
  profilePicUrl: string;
}

interface INotificationSettings {
  isLiveNotificationEnabled: boolean;
  isWhatsappNotificationEnabled: boolean;
  isEmailNotificationEnabled: boolean;
}

export interface IPostSettings {
  disableComment: boolean;
  hideComment: boolean;
  hideLikes: boolean;
}

interface IMediaContent4 {
  _id: string;
  contentType: string;
  contentUrl: string;
}

interface IMediaContent3 {
  _id: string;
  contentType: string;
  contentUrl: string;
  thumbnail: string;
  compressedVideoUrl: string;
  compressedVideoUrlDash: string;
  compressedVideoUrlHls: string;
  isCompressDone: boolean;
  transcodingJob: string;
}

interface IMediaContent2 {
  contentType: string;
  contentUrl: string;
}

interface IMediaContent {
  _id: string;
  contentType: string;
  contentUrl: string;
  thumbnail?: string;
  compressedVideoUrl?: string;
  compressedVideoUrlDash?: string;
  compressedVideoUrlHls?: string;
  isCompressDone?: boolean;
  transcodingJob?: string;
}

export interface IChannelPoll {
  _id: string;
  creator: string;
  question: string;
  pollOptions: IPostPollOption[];
  pollStartAt: string;
  pollEndAt: string;
  isResultVisibleToMembers: boolean;
  allowVoteChange: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IChannelPost {
  _id: string;
  creator: string;
  caption: string;
  layoutType?: IPostMediaLayout;
  mediaContent: (
    | IMediaContent
    | IMediaContent2
    | IMediaContent3
    | IMediaContent4
  )[];
  postSettings: IPostSettings;
  notificationSettings: INotificationSettings;
  scheduleAt: string;
  updatedAt: string;
  createdAt: string;
  channels: IChannel[];
  postedBy: IPostedBy;
  poll?: IChannelPoll;
  postRef?: IChannelPost;
}

export interface IPostReqBody {
  channels: string[];
  caption: string;
  postRef?: string;
  layoutType?: IPostMediaLayout;
  mediaContent?: IPostReqBodyMediaContent[];
  postSettings?: IPostReqBodyPostSettings;
  pollOptions?: IPostReqBodyPollOption[];
  pollSettings?: IPostReqBodyPollSettings;
  scheduleAt?: string;
}

export type IPostMediaLayout = 'carousel' | 'collage';

interface IPostReqBodyPollSettings {
  isResultVisibleToMembers: boolean;
  allowVoteChange: boolean;
  startDate: string;
  endDate: string;
}

interface IPostReqBodyPollOption {
  optionContent: string;
  optionNumber: number;
}

export interface IPostReqBodyPostSettings {
  disableComment: boolean;
  hideComment: boolean;
  hideLikes: boolean;
}

export interface IPostReqBodyMediaContent {
  type: string;
  contentUrl: string;
  _id: string;
}

export type TFileTypes = 'image' | 'audio' | 'video' | 'doc';

export interface ISelFile {
  file?: File;
  name?: string;
  ext?: string;
  type: TFileTypes;
  url: string;
}

export interface ICommunityUploadedMedia {
  type: string;
  filename: string;
  path: string;
  assetUrl: string;
  _id: string;
}

export interface IEditPostReqBody {
  channels: string[];
  caption: string;
  postSettings: IPostSettings;
}

export interface ICommentReqBody {
  repliedComment?: string;
  comment: string;
  mentions?: string;
  images?: string[];
}
