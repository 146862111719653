import React, { useState } from 'react';

import { Avatar, Typography } from 'antd';

import MenuButton from '../../../../components/MenuButton/MenuButton';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { useTimeAgoHook } from '../../../../shared/hooks';
import { IChannelPostDetails } from '../../../../types/communityTypes';
import CommunityChannelTitle from './CommunityChannelTitle';
import CommunityPostMenuModel from './CommunityPostMenuModel';

type Props = {
  communityPostDetails: IChannelPostDetails;
  isRepost: boolean;
  isPDP?: boolean;
};

const CommunityPostHeader: React.FC<Props> = ({
  communityPostDetails,
  isRepost,
  isPDP,
}) => {
  const { post } = communityPostDetails;
  const [showRemovePostWarning, setShowRemovePostWarning] = useState(false);
  const [showMenuModel, setShowMenuModel] = useState(false);
  return (
    <div className="community-post_container">
      <Avatar
        src={post?.postedBy?.profilePicUrl}
        size={45}
        // onClick={allowRedirectToUserProfile ? goToUserProfile : undefined}
        style={{ cursor: 'pointer' }}
      />

      <div className="community-post_container_wrapper">
        <Typography.Text
          ellipsis={true}
          className="community-post_container_wrapper_user-title"
          style={{ cursor: 'pointer' }}
          //   onClick={allowRedirectToUserProfile ? goToUserProfile : undefined}
        >
          {/* {postStatus === 'denied'
            ? UNKNOWN_USER
            : (isMyPost ? userDetails.name : post.creatorName || '') ||
              DELETED_USER} */}
          {post?.postedBy?.name}
        </Typography.Text>

        <div className="community-post_container_wrapper_channels">
          <CommunityChannelTitle channels={post?.channels || []}>
            {useTimeAgoHook(
              post.updatedAt || post.scheduleAt || post.createdAt,
            )}
            &nbsp;•&nbsp;
          </CommunityChannelTitle>
        </div>
      </div>
      {isRepost || isPDP ? null : (
        <MenuButton onClick={() => setShowMenuModel(true)} />
      )}

      <CommunityPostMenuModel
        showMenu={showMenuModel}
        closeModel={() => setShowMenuModel(false)}
        communityPostDetails={communityPostDetails}
        onTogglePinPost={() => {}}
      />
      <ConfirmationModal
        title="Remove"
        open={showRemovePostWarning}
        handleOk={() => {}}
        handleCancel={() => {
          setShowRemovePostWarning(false);
        }}
        message="Do you really want to delete the post?"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
      />
    </div>
  );
};

export default CommunityPostHeader;
