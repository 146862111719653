import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import CommunityAPI from '../../../../api/CommunityAPI';
import Header from '../../../../components/Header/Header';
import MenuButton from '../../../../components/MenuButton/MenuButton';
import {
  setCommunityPostComments,
  setPDPCommunityPostData,
  setReplyToComment,
} from '../../../../context/communityReducer';
import showAppError from '../../../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import {
  IChannelPostDetails,
  ISelFile,
} from '../../../../types/communityTypes';
import { ROUTES } from '../../../../types/routes';
import CreatePostMediaRender from '../../../CreateCommunityPost/components/CreatePostMediaRender';
import CommunityPostCommentInput from './CommunityPostCommentInput';
import CommunityPostCommentsList from './CommunityPostCommentsList';
import CommunityPostContent from './CommunityPostContent';
import CommunityPostHeader from './CommunityPostHeader';
import CommunityPostItem from './CommunityPostItem';
import CommunityPostMenuModel from './CommunityPostMenuModel';
import CommunityPostStats from './CommunityPostStats';

type Props = {};

const CommunityPostDetails: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const { postId, channelId } = useParams();
  const navigate = useAppNavigate();

  const { pdpCommunityPostData: communityPostData } = useAppSelector(
    (state) => state?.community,
  );

  const [loadingPost, setLoadingPost] = useState(false);
  const [showMenuModel, setShowMenuModel] = useState(false);

  const getPost = useCallback(
    async (cId: string, pId: string) => {
      try {
        const res = await CommunityAPI.getCommunityPostById(cId, pId);
        if (res.status === 200) {
          dispatch(setPDPCommunityPostData(res.data.result.post));
          setLoadingPost(false);
        } else {
          showAppError(res.data);
        }
      } catch (err: any) {
        showAppError(err);
        if (err?.response && err?.response.status === 403) {
          dispatch(setPDPCommunityPostData(err.response.data.result));
          setLoadingPost(false);
        }
      }
    },
    [dispatch],
  );

  const handleBack = () => {
    navigate.goBack();
  };

  const post = useMemo(() => {
    return communityPostData?.post;
  }, [communityPostData]);

  const postDetails = useMemo(() => {
    const newData = { ...communityPostData };
    delete newData.post;
    return newData;
  }, [communityPostData]);

  useEffect(() => {
    if (postId && channelId) {
      getPost(channelId as string, postId as string);
    }
  }, [postId, channelId, getPost]);

  useEffect(() => {
    return () => {
      dispatch(setPDPCommunityPostData(null));
      dispatch(setCommunityPostComments([]));
      dispatch(setReplyToComment({}));
    };
  }, [dispatch]);

  if (
    (loadingPost && !communityPostData?._id) ||
    !(communityPostData && post && postDetails)
  ) {
    return <div>Loading....</div>;
  }

  return (
    <>
      <div className="pdp-wrapper">
        <div className="community-post-item_wrapper community-feed-post community-post-item">
          <Header
            title={`Post`}
            handleBack={handleBack}
            actionItems={[
              <MenuButton
                onClick={() => {
                  setShowMenuModel(true);
                }}
              />,
            ]}
          />
          <div className="scroll">
            <CommunityPostHeader
              communityPostDetails={communityPostData}
              isRepost={false}
              isPDP
            />
            {post.caption ? (
              <CommunityPostContent
                caption={post.caption}
                maxLines={post.mediaContent?.length ? 2 : 5}
                onCaptionPressed={() => {}}
              />
            ) : null}
            {post?.mediaContent?.length ? (
              <CreatePostMediaRender
                files={post?.mediaContent.map(
                  (eachData) =>
                    ({
                      type: eachData?.contentType,
                      url: eachData?.contentUrl,
                    } as ISelFile),
                )}
                selectedLayout={post?.layoutType}
              />
            ) : null}
            {post?.postRef ? (
              <div className="creatorPostsWrapper__repost-card">
                <CommunityPostItem
                  key={post?.postRef?._id}
                  // index={0}
                  communityPostDetails={
                    { post: post?.postRef } as IChannelPostDetails
                  }
                  isRepost={Boolean(post?.postRef?._id)}
                />
              </div>
            ) : null}

            <CommunityPostStats
              postId={post?._id}
              communityPostDetails={communityPostData}
              channelId={channelId}
              onRepostPressed={() =>
                navigate(
                  ROUTES.COMMUNITY_CREATE_POST,
                  {
                    postRefId:
                      communityPostData?.post?.postRef?._id ||
                      communityPostData._id,
                    channelId: channelId as string,
                  }, // If reposted post is getting reposted, then postRefId and postRefDetails will be the of original post which is being reposted in the first place
                  {
                    state: {
                      postRefDetails:
                        communityPostData?.post?.postRef || communityPostData,
                    },
                  },
                )
              }
            />
            <CommunityPostCommentsList />
          </div>
        </div>
        {post?.postSettings?.disableComment ? (
          <div className="disable-comment_wrapper">
            <p className="disable-comment_wrapper_text">
              Comments are disabled for this post.
            </p>
          </div>
        ) : (
          <CommunityPostCommentInput />
        )}
      </div>

      <CommunityPostMenuModel
        showMenu={showMenuModel}
        closeModel={() => setShowMenuModel(false)}
        communityPostDetails={communityPostData as IChannelPostDetails}
        isPDP
        onTogglePinPost={() => {}}
      />
    </>
  );
};

export default CommunityPostDetails;
