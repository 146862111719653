import React, { useCallback, useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import CommunityAPI from '../../api/CommunityAPI';
import {
  setCommunityLinkList,
  setGroupChannelList,
} from '../../context/communityReducer';
import showAppError from '../../shared/error';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import CommunityChannelInfo from './components/CommunityChannelInfo';

type Props = {
  children?: React.ReactNode;
};

const CommunityWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();

  const { selectedChannelData } = useAppSelector((state) => state.community);
  const { chatRoomDetails } = useAppSelector(
    (state) => state?.communityMessage,
  );
  const searchParams = new URLSearchParams(window.location.search);
  const queryInfoValue = searchParams.get('info');

  const fetchAllChannelData = useCallback(async () => {
    try {
      const resp = await CommunityAPI.getAllChannelGroups();
      if (resp.status === 200) {
        dispatch(setGroupChannelList(resp?.data?.result));
      }
    } catch (error) {
      showAppError(error);
    }
  }, [dispatch]);

  const fetchAllLinkData = useCallback(async () => {
    try {
      const resp = await CommunityAPI.getAllCommunityLinks();
      if (resp.status === 200) {
        dispatch(setCommunityLinkList(resp?.data?.result));
      }
    } catch (error) {
      showAppError(error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchAllChannelData();
    fetchAllLinkData();
  }, [fetchAllChannelData, fetchAllLinkData]);

  return (
    <>
      <Outlet />
      {children}
      {queryInfoValue === 'true' &&
      (selectedChannelData?._id || chatRoomDetails?._id) ? (
        <CommunityChannelInfo isChatRoomInfo={Boolean(chatRoomDetails?._id)} />
      ) : null}
    </>
  );
};

export default CommunityWrapper;
