import React, { memo, useEffect, useMemo, useState } from 'react';

import { message } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import { MouseDownEvent } from 'emoji-picker-react/dist/config/config';
import {
  PiChatCircleText,
  PiInfo,
  PiPencilSimpleLight,
  PiPushPin,
  PiPushPinSlash,
  PiTrash,
} from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { SocketAPI } from '../../../api/SocketAPI';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import {
  changeChatsState,
  setEditMessageData,
  setReplyMessageData,
  setTouchedMessageData,
  updateChatRoomDetails,
  updateOrDeleteSelectedMessage,
  updateReplyState,
} from '../../../context/communityMessageReducer';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { ICommunityMessage } from '../../../types/communityMessagesTypes';
import { SOCKET_ACTION_EVENTS } from '../../../types/socketTypes';

type Props = {
  setInfoMessageId: (arg0: string) => void;
};

const TouchedMode: React.FC<Props> = ({ setInfoMessageId }) => {
  const dispatch = useAppDispatch();

  const { roomId } = useParams();

  const { touchedMessageData, chatRoomDetails, replayMessageData, replyState } =
    useAppSelector((state) => state?.communityMessage);

  const isPinned = useMemo(() => {
    const chat = chatRoomDetails?.pinnedMessages?.find(
      (each) => each?._id === touchedMessageData?._id,
    );
    return Boolean(chat?._id);
  }, [chatRoomDetails?.pinnedMessages, touchedMessageData?._id]);

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [selecteMessageForDelete, setSelecteMessageForDelete] =
    useState<ICommunityMessage | null>(null);

  const closeModal = () => {
    dispatch(setTouchedMessageData(null));
  };

  const handleOnEmojiClick: MouseDownEvent = (event) => {
    if (!chatRoomDetails?._id || !touchedMessageData?._id) return;
    SocketAPI.emit(
      SOCKET_ACTION_EVENTS.ROOM_MESSAGE_REACT,
      {
        room: chatRoomDetails?._id,
        messageId: touchedMessageData?._id,
        reaction: event.emoji,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (resp: any) => {
        if (replayMessageData) {
          const newReplyState = replyState?.replies?.map((each) => {
            if (each?._id === touchedMessageData?._id) {
              return resp.result;
            }
            return each;
          });
          dispatch(updateReplyState({ replies: newReplyState }));
        } else {
          dispatch(updateOrDeleteSelectedMessage(resp.result, false, () => {}));
        }
        closeModal();
      },
    );
  };

  const handleReplyThread = () => {
    dispatch(setReplyMessageData(touchedMessageData));
    closeModal();
  };

  const afterPinnedAction = () => {
    closeModal();
    if (!touchedMessageData) return;
    const currentPinnedMessages = chatRoomDetails?.pinnedMessages;
    let newUpdatedPinnedMessage = [...(currentPinnedMessages || [])];
    if (isPinned) {
      newUpdatedPinnedMessage = newUpdatedPinnedMessage.filter(
        (each) => each?._id !== touchedMessageData?._id,
      );
    } else {
      newUpdatedPinnedMessage = [
        touchedMessageData,
        ...newUpdatedPinnedMessage,
      ];
    }
    dispatch(
      updateChatRoomDetails({ pinnedMessages: newUpdatedPinnedMessage }),
    );
    message.success(
      isPinned
        ? 'Unpinned message successfully'
        : 'Pinned message successfully',
    );
  };

  const handlePinToChat = () => {
    // select this message and edit it
    if (!roomId || !touchedMessageData?._id) return;
    const actionType = isPinned ? 'unpinned' : 'pinned';
    dispatch(
      changeChatsState(
        actionType,
        roomId,
        touchedMessageData?._id,
        () => {},
        () => afterPinnedAction(),
        () => {},
        {},
        isPinned ? 'action=unpin' : 'action=pin',
      ),
    );
  };

  const handleMessageInfo = () => {
    if (!touchedMessageData?._id) return;
    // open message info page
    setInfoMessageId(touchedMessageData?._id);
    closeModal();
  };

  const handleEditMessage = () => {
    dispatch(setEditMessageData(touchedMessageData));
    closeModal();
  };

  const handleDeleteMessage = () => {
    //
    if (selecteMessageForDelete) {
      SocketAPI.emit(
        'room.message.delete',
        {
          room: selecteMessageForDelete.room,
          messageId: selecteMessageForDelete._id,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        () => {
          if (replayMessageData) {
            const newReplyState = replyState?.replies?.filter(
              (each) => each?._id !== selecteMessageForDelete?._id,
            );
            dispatch(updateReplyState({ replies: newReplyState }));
          } else {
            dispatch(
              updateOrDeleteSelectedMessage(selecteMessageForDelete, true),
            );
          }
          message.success('Delete message successfully');
          setIsDialogVisible(false);
        },
      );
    }
  };

  useEffect(() => {
    if (Boolean(touchedMessageData?._id)) {
      setSelecteMessageForDelete(null);
    }
  }, [touchedMessageData?._id]);

  return (
    <>
      <BottomSheet
        open={Boolean(touchedMessageData?._id)}
        onDismiss={() => closeModal()}
        style={{ zIndex: 0 }}>
        {null}
        <div className="touched">
          <EmojiPicker
            className="emoji_container_wrapper"
            onEmojiClick={handleOnEmojiClick}
            autoFocusSearch={false}
            reactionsDefaultOpen
            lazyLoadEmojis
            onReactionClick={handleOnEmojiClick}
          />
          <div className="touched_btn-wrapper">
            {replayMessageData ? null : (
              <button
                className="touched_btn-wrapper_button"
                onClick={handleReplyThread}>
                <div className="touched_btn-wrapper_button_emoji">
                  <PiChatCircleText />
                </div>
                Reply in thread
              </button>
            )}
            <button
              className="touched_btn-wrapper_button"
              onClick={handleEditMessage}>
              <div className="touched_btn-wrapper_button_emoji">
                <PiPencilSimpleLight />
              </div>
              Edit message
            </button>
            <button
              className="touched_btn-wrapper_button"
              onClick={handleMessageInfo}>
              <div className="touched_btn-wrapper_button_emoji">
                <PiInfo />
              </div>
              Message info
            </button>
            {replayMessageData ? null : (
              <button
                className="touched_btn-wrapper_button"
                onClick={handlePinToChat}>
                <div className="touched_btn-wrapper_button_emoji">
                  {isPinned ? <PiPushPinSlash /> : <PiPushPin />}
                </div>
                {isPinned ? 'Unpin to chat' : 'Pin to chat'}
              </button>
            )}
            <button
              className="touched_btn-wrapper_button danger"
              onClick={() => {
                setSelecteMessageForDelete(touchedMessageData);
                setIsDialogVisible(true);
                closeModal();
              }}>
              <div className="touched_btn-wrapper_button_emoji">
                <PiTrash />
              </div>
              Delete message
            </button>
          </div>
        </div>
      </BottomSheet>
      <ConfirmationModal
        open={isDialogVisible}
        message="Do you really want to delete this comment?"
        title="Delete message"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
        handleOk={() => {
          setIsDialogVisible(false);
          handleDeleteMessage();
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
          setSelecteMessageForDelete(null);
        }}
      />
    </>
  );
};

export default memo(TouchedMode);
