import '../community.scss';

import React from 'react';

import { PiMegaphoneFill, PiNewspaperFill } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { ROUTES } from '../../../types/routes';

type Props = {
  show: boolean;
  closeModal: () => void;
};

const CreatePostChannelBottomSheet: React.FC<Props> = ({
  show,
  closeModal,
}) => {
  const navigate = useNavigate();

  const handlePostFeedClick = () => {
    navigate(ROUTES.CREATE_POST);
  };

  const handleChannelPostClick = () => {
    navigate(ROUTES.COMMUNITY_CREATE_POST);
  };

  return (
    <BottomSheet
      open={show}
      onDismiss={() => {
        closeModal();
      }}
      className="create_post_channel_modal">
      <div className="create_post_channel_modal-wrapper">
        <button className="create_post_channel_modal-wrapper-button">
          <div
            className="create_post_channel_modal-wrapper-button-card"
            onClick={handlePostFeedClick}>
            <div className="create_post_channel_modal-wrapper-button-card-icon">
              <PiNewspaperFill />
            </div>
            <div className="create_post_channel_modal-wrapper-button-card-content">
              <p className="create_post_channel_modal-wrapper-button-card-content-title">
                Post on Feed
              </p>
              <p className="create_post_channel_modal-wrapper-button-card-content-subtitle">
                Share with everyone in your community
              </p>
            </div>
          </div>
        </button>
        <button
          className="create_post_channel_modal-wrapper-button"
          onClick={handleChannelPostClick}>
          <div className="create_post_channel_modal-wrapper-button-card">
            <div className="create_post_channel_modal-wrapper-button-card-icon">
              <PiMegaphoneFill />
            </div>
            <div className="create_post_channel_modal-wrapper-button-card-content">
              <p className="create_post_channel_modal-wrapper-button-card-content-title">
                Post in a Channel
              </p>
              <p className="create_post_channel_modal-wrapper-button-card-content-subtitle">
                Engage with specific audience
              </p>
            </div>
          </div>
        </button>
      </div>
    </BottomSheet>
  );
};

export default CreatePostChannelBottomSheet;
