import '../communityChatRoom.scss';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Skeleton } from 'antd';
import { PiPushPin } from 'react-icons/pi';
import InfiniteScroll from 'react-infinite-scroller';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import CommunityMessagesAPI from '../../../api/CommunityMessageAPI';
import { SocketAPI } from '../../../api/SocketAPI';
import Header from '../../../components/Header/Header';
import {
  getMessageRoomChats,
  initialChatState,
  setActiveUserInSelectedRoom,
  setChatRoomDetails,
  setTouchedMessageData,
  updateChatState,
} from '../../../context/communityMessageReducer';
import showAppError from '../../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../shared/hooks';
import { SOCKET_ACTION_EVENTS } from '../../../types/socketTypes';
import CommunityChatbackgroundLayer from './CommunityChatbackgroundLayer';
import CommunityInputWithEmojiPicker from './CommunityInputWithEmojiPicker';
import InfoMessageModel from './InfoMessageModel';
import MessagesCard from './MessagesCard';
import PinnedMessageList from './PinnedMessageList';
import ReplyThreadModel from './ReplyThreadModel';
import TouchedMode from './TouchedMode';

type Props = {
  channelRoomId?: string;
  hideHeader?: boolean;
};

const CommunityChatRoom: React.FC<Props> = ({ channelRoomId, hideHeader }) => {
  const navigate = useAppNavigate();
  const rootNavigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { roomId } = useParams();

  const { chatRoomDetails, chatsState } = useAppSelector(
    (state) => state?.communityMessage,
  );

  const [loading, setLoading] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [showPinnedMessage, setShowPinnedMessage] = useState(false);
  const [selectMessageForInfo, setSelectMessageForInfo] = useState<
    string | null
  >(null);

  const handleBack = () => {
    setTimeout(() => {
      navigate.goBack();
    }, 200);
  };

  const fetchMessageList = useCallback(async () => {
    if (chatsState?.hasMore && !chatsState?.loading && chatRoomDetails?._id) {
      dispatch(getMessageRoomChats(chatsState?.chats, chatRoomDetails?._id));
    }
  }, [
    chatRoomDetails?._id,
    chatsState?.chats,
    chatsState?.hasMore,
    chatsState?.loading,
    dispatch,
  ]);

  const displayMessages = useCallback(() => {
    return (
      <InfiniteScroll
        loadMore={fetchMessageList}
        hasMore={chatsState.hasMore}
        reversed
        loader={
          (chatsState.loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
                paddingTop: 0,
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // gap: 12,
                  paddingTop: 0,
                }}>
                {Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton avatar paragraph={{ rows: 0 }} key={index} />
                  ))}
              </div>
            </div>
          ) : null) as JSX.Element
        }
        useWindow={false}
        getScrollParent={() => {
          return scrollContainerRef.current as HTMLElement;
        }}>
        <>
          {chatsState?.chats.map((eachMessage) => (
            <MessagesCard message={eachMessage} />
          ))}
        </>
      </InfiniteScroll>
    );
  }, [
    chatsState?.chats,
    chatsState.hasMore,
    chatsState.loading,
    fetchMessageList,
  ]);

  const handleChannelTitleClick = useCallback(() => {
    const searchParams = new URLSearchParams(location?.search);
    searchParams.append('info', 'true');
    rootNavigate(`${location?.pathname}?${searchParams?.toString()}`);
  }, [location?.pathname, location?.search, rootNavigate]);

  const displayMessagesOrLoader = () => {
    if (loading) {
      return (
        <>
          {Array(10)
            .fill(1)
            .map(() => (
              <Skeleton avatar paragraph={{ rows: 0 }} />
            ))}
        </>
      );
    }
    return displayMessages();
  };

  const customTitle = useMemo(() => {
    return (
      <button
        className="chat-room-header"
        onClick={() => handleChannelTitleClick()}>
        {chatRoomDetails?.channel.communityProfilePictureType === 'emoji' ? (
          <div className="chat-room-header_emoji-wrapper">
            {chatRoomDetails?.channel.communityProfilePicture}
          </div>
        ) : (
          <div className="chat-room-header_logo-wrapper">
            <img
              src={chatRoomDetails?.channel.communityProfilePicture}
              alt="logo"
            />
          </div>
        )}
        <div className="chat-room-header_text-wrapper">
          <p className="chat-room-header_text-wrapper_title">
            {chatRoomDetails?.channel.name} sdhvsh sdhjsdb ghds vg gs vghjdsg hj
          </p>
          <span className="chat-room-header_text-wrapper_subtitle">
            Tap here for info
          </span>
        </div>
      </button>
    );
  }, [
    chatRoomDetails?.channel.communityProfilePicture,
    chatRoomDetails?.channel.communityProfilePictureType,
    chatRoomDetails?.channel.name,
    handleChannelTitleClick,
  ]);

  const joinRoom = useCallback(
    (room: string) => {
      SocketAPI.emit(SOCKET_ACTION_EVENTS.ROOM_JOIN, { room: room }, (resp) => {
        dispatch(
          setActiveUserInSelectedRoom([
            ...(resp.result.activeParticipants || []),
          ]),
        );
      });
    },
    [dispatch],
  );

  const getRoomDetails = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const resp = await CommunityMessagesAPI.getCommuiniyMessageRoomDetails(
          id,
        );
        if (resp?.status === 200) {
          dispatch(setChatRoomDetails(resp?.data?.result));
          joinRoom(resp?.data?.result?._id);
          // dispatch(
          //   updateChatList(
          //     resp?.data?.result?.lastMessage
          //       ? [resp?.data?.result?.lastMessage]
          //       : [],
          //   ),
          // );
        } else {
          showAppError(resp.data);
        }
      } catch (error) {
        showAppError(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, joinRoom],
  );

  useEffect(() => {
    if (roomId || channelRoomId) {
      getRoomDetails(roomId || (channelRoomId as string));
    }
  }, [getRoomDetails, roomId, channelRoomId]);

  useEffect(() => {
    return () => {
      dispatch(setChatRoomDetails(null));
      dispatch(updateChatState(initialChatState));
      dispatch(setTouchedMessageData(null));
    };
  }, [dispatch]);

  return (
    <div className="chat-room_container">
      {!hideHeader ? (
        <Header
          title={chatRoomDetails?.channel.name as string}
          customTitle={customTitle}
          handleBack={handleBack}
          customHeaderClass="chat-room-header-wrapper"
          actionItems={
            chatRoomDetails?.pinnedMessages?.length
              ? [
                  <button
                    className="chat-room-pinned"
                    onClick={() => {
                      const searchParams = new URLSearchParams();
                      searchParams?.append('pinnedMessage', 'true');
                      rootNavigate({ search: searchParams?.toString() });
                      setShowPinnedMessage(true);
                    }}>
                    <PiPushPin /> {chatRoomDetails?.pinnedMessages?.length}
                  </button>,
                ]
              : []
          }
        />
      ) : null}
      <div
        className="chat-room_container_wrapper"
        ref={(ref) => (scrollContainerRef.current = ref)}
        id="chat-room_container_wrapper_1234">
        {displayMessagesOrLoader()}
      </div>
      <CommunityInputWithEmojiPicker />
      <TouchedMode setInfoMessageId={(id) => setSelectMessageForInfo(id)} />
      <PinnedMessageList
        showModal={showPinnedMessage}
        closeModal={() => {
          setShowPinnedMessage(false);
          navigate.goBack();
        }}
      />
      <InfoMessageModel
        roomId={chatRoomDetails?._id as string}
        messageId={selectMessageForInfo}
        closeModal={() => setSelectMessageForInfo(null)}
      />
      <ReplyThreadModel />
      <CommunityChatbackgroundLayer />
    </div>
  );
};

export default CommunityChatRoom;
