import React, { memo } from 'react';

type Props = {
  title: string;
};

const ListTitle: React.FC<Props> = ({ title }) => {
  return (
    <div className="list-title">
      <p>{title}</p>
    </div>
  );
};

export default memo(ListTitle);
