import { CurrencyType } from '../types/baseTypes';

export const CURRENCY_SYMBOLS_MAP: Record<CurrencyType, string> = {
  INR: '₹',
  USD: '$',
  EUR: '€',
};

export const EUR_COUNTRIES = [
  'MT',
  'LU',
  'CY',
  'EE',
  'LV',
  'SI',
  'LT',
  'IE',
  'SK',
  'FI',
  'AT',
  'PT',
  'GR',
  'BE',
  'NL',
  'ES',
  'IT',
  'FR',
  'DE',
];

export const ErrorStatusMessages = {
  400: 'Oops! Something went wrong. Please try again later.',
  401: 'You are not signed in. Please log in to continue.',
  403: 'Access denied. You do not have permission to perform this action.',
  404: 'We couldn’t find what you are looking for.',
  405: 'This action is not allowed. Please check and try again.',
  408: 'The request took too long. Please try again.',
  409: 'This action is not allowed at the moment. Please check and try again later.',
  410: 'This content is no longer available.',
  413: 'The file size is too large. Please use a smaller file and try again.',
  415: 'Unsupported file format. Please use a different file type.',
  429: 'Too many requests. Please wait and try again later.',
  500: 'Something went wrong on our end. Please try again later.',
  501: 'This request is not supported. Please check and try again.',
  502: 'There’s an issue with the server. Please try again later.',
  503: 'We’re experiencing high traffic. Please try again soon.',
  504: 'The server took too long to respond. Please try again in a moment.',
} as const;

export const TM_SUPPORT_EMAIL = 'support@tagmango.com';
export const SEARCH_THRESHOLD = 3;

export const DELETED_POST_MESSAGE = `This content is no longer available as it has been deleted by the owner.`;

// export const DELETED_POST_MESSAGE = `This content isn't available at the moment
// When this happens, it's usually because the owner only shared it with a small group of people, changed who can see it, or it's been deleted.`;

export const POST_MIN_VIEW_THRESHOLD = 0.5; // Trigger when 50% of the post is visible
export const POST_MIN_VIEW_TIME = 3000; // 3 seconds

export const COURSES_PAGE_LIMIT = 6;

export const DayMap: Record<number | string, string> = {
  1: '1 day',
  3: '3 days',
  7: '1 week',
  14: '2 weeks',
  custom: 'Custom',
};

enum RouteTitle {
  FEED = 'feed',
  MESSAGES = 'messages',
  TM_TEAM = 'team-tagmango',
}

export type RouteVsTitleKeys = `${RouteTitle}`;

export const routeVsTitle = {
  [RouteTitle.FEED]: 'Feed',
  [RouteTitle.MESSAGES]: 'Messages',
  [RouteTitle.TM_TEAM]: 'From the Tagmango Team',
};

export const COUNTRY_FLAGS_MAP = {
  AF: {
    primary: 'Afghanistan',
    secondary: '+93',
    code: 'AF',
  },
  AX: {
    primary: 'Aland Islands',
    secondary: '+358',
    code: 'AX',
  },
  AL: {
    primary: 'Albania',
    secondary: '+355',
    code: 'AL',
  },
  DZ: {
    primary: 'Algeria',
    secondary: '+213',
    code: 'DZ',
  },
  AS: {
    primary: 'AmericanSamoa',
    secondary: '+1684',
    code: 'AS',
  },
  AD: {
    primary: 'Andorra',
    secondary: '+376',
    code: 'AD',
  },
  AO: {
    primary: 'Angola',
    secondary: '+244',
    code: 'AO',
  },
  AI: {
    primary: 'Anguilla',
    secondary: '+1264',
    code: 'AI',
  },
  AQ: {
    primary: 'Antarctica',
    secondary: '+672',
    code: 'AQ',
  },
  AG: {
    primary: 'Antigua and Barbuda',
    secondary: '+1268',
    code: 'AG',
  },
  AR: {
    primary: 'Argentina',
    secondary: '+54',
    code: 'AR',
  },
  AM: {
    primary: 'Armenia',
    secondary: '+374',
    code: 'AM',
  },
  AW: {
    primary: 'Aruba',
    secondary: '+297',
    code: 'AW',
  },
  AU: {
    primary: 'Australia',
    secondary: '+61',
    code: 'AU',
  },
  AT: {
    primary: 'Austria',
    secondary: '+43',
    code: 'AT',
  },
  AZ: {
    primary: 'Azerbaijan',
    secondary: '+994',
    code: 'AZ',
  },
  BS: {
    primary: 'Bahamas',
    secondary: '+1242',
    code: 'BS',
  },
  BH: {
    primary: 'Bahrain',
    secondary: '+973',
    code: 'BH',
  },
  BD: {
    primary: 'Bangladesh',
    secondary: '+880',
    code: 'BD',
  },
  BB: {
    primary: 'Barbados',
    secondary: '+1246',
    code: 'BB',
  },
  BY: {
    primary: 'Belarus',
    secondary: '+375',
    code: 'BY',
  },
  BE: {
    primary: 'Belgium',
    secondary: '+32',
    code: 'BE',
  },
  BZ: {
    primary: 'Belize',
    secondary: '+501',
    code: 'BZ',
  },
  BJ: {
    primary: 'Benin',
    secondary: '+229',
    code: 'BJ',
  },
  BM: {
    primary: 'Bermuda',
    secondary: '+1441',
    code: 'BM',
  },
  BT: {
    primary: 'Bhutan',
    secondary: '+975',
    code: 'BT',
  },
  BO: {
    primary: 'Bolivia, Plurinational State of',
    secondary: '+591',
    code: 'BO',
  },
  BA: {
    primary: 'Bosnia and Herzegovina',
    secondary: '+387',
    code: 'BA',
  },
  BW: {
    primary: 'Botswana',
    secondary: '+267',
    code: 'BW',
  },
  BR: {
    primary: 'Brazil',
    secondary: '+55',
    code: 'BR',
  },
  IO: {
    primary: 'British Indian Ocean Territory',
    secondary: '+246',
    code: 'IO',
  },
  BN: {
    primary: 'Brunei Darussalam',
    secondary: '+673',
    code: 'BN',
  },
  BG: {
    primary: 'Bulgaria',
    secondary: '+359',
    code: 'BG',
  },
  BF: {
    primary: 'Burkina Faso',
    secondary: '+226',
    code: 'BF',
  },
  BI: {
    primary: 'Burundi',
    secondary: '+257',
    code: 'BI',
  },
  KH: {
    primary: 'Cambodia',
    secondary: '+855',
    code: 'KH',
  },
  CM: {
    primary: 'Cameroon',
    secondary: '+237',
    code: 'CM',
  },
  CA: {
    primary: 'Canada',
    secondary: '+1',
    code: 'CA',
  },
  CV: {
    primary: 'Cape Verde',
    secondary: '+238',
    code: 'CV',
  },
  KY: {
    primary: 'Cayman Islands',
    secondary: '+ 345',
    code: 'KY',
  },
  CF: {
    primary: 'Central African Republic',
    secondary: '+236',
    code: 'CF',
  },
  TD: {
    primary: 'Chad',
    secondary: '+235',
    code: 'TD',
  },
  CL: {
    primary: 'Chile',
    secondary: '+56',
    code: 'CL',
  },
  CN: {
    primary: 'China',
    secondary: '+86',
    code: 'CN',
  },
  CX: {
    primary: 'Christmas Island',
    secondary: '+61',
    code: 'CX',
  },
  CC: {
    primary: 'Cocos (Keeling) Islands',
    secondary: '+61',
    code: 'CC',
  },
  CO: {
    primary: 'Colombia',
    secondary: '+57',
    code: 'CO',
  },
  KM: {
    primary: 'Comoros',
    secondary: '+269',
    code: 'KM',
  },
  CG: {
    primary: 'Congo',
    secondary: '+242',
    code: 'CG',
  },
  CD: {
    primary: 'Congo, The Democratic Republic of the Congo',
    secondary: '+243',
    code: 'CD',
  },
  CK: {
    primary: 'Cook Islands',
    secondary: '+682',
    code: 'CK',
  },
  CR: {
    primary: 'Costa Rica',
    secondary: '+506',
    code: 'CR',
  },
  CI: {
    primary: "Cote d'Ivoire",
    secondary: '+225',
    code: 'CI',
  },
  HR: {
    primary: 'Croatia',
    secondary: '+385',
    code: 'HR',
  },
  CU: {
    primary: 'Cuba',
    secondary: '+53',
    code: 'CU',
  },
  CY: {
    primary: 'Cyprus',
    secondary: '+357',
    code: 'CY',
  },
  CZ: {
    primary: 'Czech Republic',
    secondary: '+420',
    code: 'CZ',
  },
  DK: {
    primary: 'Denmark',
    secondary: '+45',
    code: 'DK',
  },
  DJ: {
    primary: 'Djibouti',
    secondary: '+253',
    code: 'DJ',
  },
  DM: {
    primary: 'Dominica',
    secondary: '+1767',
    code: 'DM',
  },
  DO: {
    primary: 'Dominican Republic',
    secondary: '+1849',
    code: 'DO',
  },
  EC: {
    primary: 'Ecuador',
    secondary: '+593',
    code: 'EC',
  },
  EG: {
    primary: 'Egypt',
    secondary: '+20',
    code: 'EG',
  },
  SV: {
    primary: 'El Salvador',
    secondary: '+503',
    code: 'SV',
  },
  GQ: {
    primary: 'Equatorial Guinea',
    secondary: '+240',
    code: 'GQ',
  },
  ER: {
    primary: 'Eritrea',
    secondary: '+291',
    code: 'ER',
  },
  EE: {
    primary: 'Estonia',
    secondary: '+372',
    code: 'EE',
  },
  ET: {
    primary: 'Ethiopia',
    secondary: '+251',
    code: 'ET',
  },
  FK: {
    primary: 'Falkland Islands (Malvinas)',
    secondary: '+500',
    code: 'FK',
  },
  FO: {
    primary: 'Faroe Islands',
    secondary: '+298',
    code: 'FO',
  },
  FJ: {
    primary: 'Fiji',
    secondary: '+679',
    code: 'FJ',
  },
  FI: {
    primary: 'Finland',
    secondary: '+358',
    code: 'FI',
  },
  FR: {
    primary: 'France',
    secondary: '+33',
    code: 'FR',
  },
  GF: {
    primary: 'French Guiana',
    secondary: '+594',
    code: 'GF',
  },
  PF: {
    primary: 'French Polynesia',
    secondary: '+689',
    code: 'PF',
  },
  GA: {
    primary: 'Gabon',
    secondary: '+241',
    code: 'GA',
  },
  GM: {
    primary: 'Gambia',
    secondary: '+220',
    code: 'GM',
  },
  GE: {
    primary: 'Georgia',
    secondary: '+995',
    code: 'GE',
  },
  DE: {
    primary: 'Germany',
    secondary: '+49',
    code: 'DE',
  },
  GH: {
    primary: 'Ghana',
    secondary: '+233',
    code: 'GH',
  },
  GI: {
    primary: 'Gibraltar',
    secondary: '+350',
    code: 'GI',
  },
  GR: {
    primary: 'Greece',
    secondary: '+30',
    code: 'GR',
  },
  GL: {
    primary: 'Greenland',
    secondary: '+299',
    code: 'GL',
  },
  GD: {
    primary: 'Grenada',
    secondary: '+1473',
    code: 'GD',
  },
  GP: {
    primary: 'Guadeloupe',
    secondary: '+590',
    code: 'GP',
  },
  GU: {
    primary: 'Guam',
    secondary: '+1671',
    code: 'GU',
  },
  GT: {
    primary: 'Guatemala',
    secondary: '+502',
    code: 'GT',
  },
  GG: {
    primary: 'Guernsey',
    secondary: '+44',
    code: 'GG',
  },
  GN: {
    primary: 'Guinea',
    secondary: '+224',
    code: 'GN',
  },
  GW: {
    primary: 'Guinea-Bissau',
    secondary: '+245',
    code: 'GW',
  },
  GY: {
    primary: 'Guyana',
    secondary: '+595',
    code: 'GY',
  },
  HT: {
    primary: 'Haiti',
    secondary: '+509',
    code: 'HT',
  },
  VA: {
    primary: 'Holy See (Vatican City State)',
    secondary: '+379',
    code: 'VA',
  },
  HN: {
    primary: 'Honduras',
    secondary: '+504',
    code: 'HN',
  },
  HK: {
    primary: 'Hong Kong',
    secondary: '+852',
    code: 'HK',
  },
  HU: {
    primary: 'Hungary',
    secondary: '+36',
    code: 'HU',
  },
  IS: {
    primary: 'Iceland',
    secondary: '+354',
    code: 'IS',
  },
  IN: {
    primary: 'India',
    secondary: '+91',
    code: 'IN',
  },
  ID: {
    primary: 'Indonesia',
    secondary: '+62',
    code: 'ID',
  },
  IR: {
    primary: 'Iran, Islamic Republic of Persian Gulf',
    secondary: '+98',
    code: 'IR',
  },
  IQ: {
    primary: 'Iraq',
    secondary: '+964',
    code: 'IQ',
  },
  IE: {
    primary: 'Ireland',
    secondary: '+353',
    code: 'IE',
  },
  IM: {
    primary: 'Isle of Man',
    secondary: '+44',
    code: 'IM',
  },
  IL: {
    primary: 'Israel',
    secondary: '+972',
    code: 'IL',
  },
  IT: {
    primary: 'Italy',
    secondary: '+39',
    code: 'IT',
  },
  JM: {
    primary: 'Jamaica',
    secondary: '+1876',
    code: 'JM',
  },
  JP: {
    primary: 'Japan',
    secondary: '+81',
    code: 'JP',
  },
  JE: {
    primary: 'Jersey',
    secondary: '+44',
    code: 'JE',
  },
  JO: {
    primary: 'Jordan',
    secondary: '+962',
    code: 'JO',
  },
  KZ: {
    primary: 'Kazakhstan',
    secondary: '+77',
    code: 'KZ',
  },
  KE: {
    primary: 'Kenya',
    secondary: '+254',
    code: 'KE',
  },
  KI: {
    primary: 'Kiribati',
    secondary: '+686',
    code: 'KI',
  },
  KP: {
    primary: "Korea, Democratic People's Republic of Korea",
    secondary: '+850',
    code: 'KP',
  },
  KR: {
    primary: 'Korea, Republic of South Korea',
    secondary: '+82',
    code: 'KR',
  },
  KW: {
    primary: 'Kuwait',
    secondary: '+965',
    code: 'KW',
  },
  KG: {
    primary: 'Kyrgyzstan',
    secondary: '+996',
    code: 'KG',
  },
  LA: {
    primary: 'Laos',
    secondary: '+856',
    code: 'LA',
  },
  LV: {
    primary: 'Latvia',
    secondary: '+371',
    code: 'LV',
  },
  LB: {
    primary: 'Lebanon',
    secondary: '+961',
    code: 'LB',
  },
  LS: {
    primary: 'Lesotho',
    secondary: '+266',
    code: 'LS',
  },
  LR: {
    primary: 'Liberia',
    secondary: '+231',
    code: 'LR',
  },
  LY: {
    primary: 'Libyan Arab Jamahiriya',
    secondary: '+218',
    code: 'LY',
  },
  LI: {
    primary: 'Liechtenstein',
    secondary: '+423',
    code: 'LI',
  },
  LT: {
    primary: 'Lithuania',
    secondary: '+370',
    code: 'LT',
  },
  LU: {
    primary: 'Luxembourg',
    secondary: '+352',
    code: 'LU',
  },
  MO: {
    primary: 'Macao',
    secondary: '+853',
    code: 'MO',
  },
  MK: {
    primary: 'Macedonia',
    secondary: '+389',
    code: 'MK',
  },
  MG: {
    primary: 'Madagascar',
    secondary: '+261',
    code: 'MG',
  },
  MW: {
    primary: 'Malawi',
    secondary: '+265',
    code: 'MW',
  },
  MY: {
    primary: 'Malaysia',
    secondary: '+60',
    code: 'MY',
  },
  MV: {
    primary: 'Maldives',
    secondary: '+960',
    code: 'MV',
  },
  ML: {
    primary: 'Mali',
    secondary: '+223',
    code: 'ML',
  },
  MT: {
    primary: 'Malta',
    secondary: '+356',
    code: 'MT',
  },
  MH: {
    primary: 'Marshall Islands',
    secondary: '+692',
    code: 'MH',
  },
  MQ: {
    primary: 'Martinique',
    secondary: '+596',
    code: 'MQ',
  },
  MR: {
    primary: 'Mauritania',
    secondary: '+222',
    code: 'MR',
  },
  MU: {
    primary: 'Mauritius',
    secondary: '+230',
    code: 'MU',
  },
  YT: {
    primary: 'Mayotte',
    secondary: '+262',
    code: 'YT',
  },
  MX: {
    primary: 'Mexico',
    secondary: '+52',
    code: 'MX',
  },
  FM: {
    primary: 'Micronesia, Federated States of Micronesia',
    secondary: '+691',
    code: 'FM',
  },
  MD: {
    primary: 'Moldova',
    secondary: '+373',
    code: 'MD',
  },
  MC: {
    primary: 'Monaco',
    secondary: '+377',
    code: 'MC',
  },
  MN: {
    primary: 'Mongolia',
    secondary: '+976',
    code: 'MN',
  },
  ME: {
    primary: 'Montenegro',
    secondary: '+382',
    code: 'ME',
  },
  MS: {
    primary: 'Montserrat',
    secondary: '+1664',
    code: 'MS',
  },
  MA: {
    primary: 'Morocco',
    secondary: '+212',
    code: 'MA',
  },
  MZ: {
    primary: 'Mozambique',
    secondary: '+258',
    code: 'MZ',
  },
  MM: {
    primary: 'Myanmar',
    secondary: '+95',
    code: 'MM',
  },
  NA: {
    primary: 'Namibia',
    secondary: '+264',
    code: 'NA',
  },
  NR: {
    primary: 'Nauru',
    secondary: '+674',
    code: 'NR',
  },
  NP: {
    primary: 'Nepal',
    secondary: '+977',
    code: 'NP',
  },
  NL: {
    primary: 'Netherlands',
    secondary: '+31',
    code: 'NL',
  },
  AN: {
    primary: 'Netherlands Antilles',
    secondary: '+599',
    code: 'AN',
  },
  NC: {
    primary: 'New Caledonia',
    secondary: '+687',
    code: 'NC',
  },
  NZ: {
    primary: 'New Zealand',
    secondary: '+64',
    code: 'NZ',
  },
  NI: {
    primary: 'Nicaragua',
    secondary: '+505',
    code: 'NI',
  },
  NE: {
    primary: 'Niger',
    secondary: '+227',
    code: 'NE',
  },
  NG: {
    primary: 'Nigeria',
    secondary: '+234',
    code: 'NG',
  },
  NU: {
    primary: 'Niue',
    secondary: '+683',
    code: 'NU',
  },
  NF: {
    primary: 'Norfolk Island',
    secondary: '+672',
    code: 'NF',
  },
  MP: {
    primary: 'Northern Mariana Islands',
    secondary: '+1670',
    code: 'MP',
  },
  NO: {
    primary: 'Norway',
    secondary: '+47',
    code: 'NO',
  },
  OM: {
    primary: 'Oman',
    secondary: '+968',
    code: 'OM',
  },
  PK: {
    primary: 'Pakistan',
    secondary: '+92',
    code: 'PK',
  },
  PW: {
    primary: 'Palau',
    secondary: '+680',
    code: 'PW',
  },
  PS: {
    primary: 'Palestinian Territory, Occupied',
    secondary: '+970',
    code: 'PS',
  },
  PA: {
    primary: 'Panama',
    secondary: '+507',
    code: 'PA',
  },
  PG: {
    primary: 'Papua New Guinea',
    secondary: '+675',
    code: 'PG',
  },
  PY: {
    primary: 'Paraguay',
    secondary: '+595',
    code: 'PY',
  },
  PE: {
    primary: 'Peru',
    secondary: '+51',
    code: 'PE',
  },
  PH: {
    primary: 'Philippines',
    secondary: '+63',
    code: 'PH',
  },
  PN: {
    primary: 'Pitcairn',
    secondary: '+872',
    code: 'PN',
  },
  PL: {
    primary: 'Poland',
    secondary: '+48',
    code: 'PL',
  },
  PT: {
    primary: 'Portugal',
    secondary: '+351',
    code: 'PT',
  },
  PR: {
    primary: 'Puerto Rico',
    secondary: '+1939',
    code: 'PR',
  },
  QA: {
    primary: 'Qatar',
    secondary: '+974',
    code: 'QA',
  },
  RO: {
    primary: 'Romania',
    secondary: '+40',
    code: 'RO',
  },
  RU: {
    primary: 'Russia',
    secondary: '+7',
    code: 'RU',
  },
  RW: {
    primary: 'Rwanda',
    secondary: '+250',
    code: 'RW',
  },
  RE: {
    primary: 'Reunion',
    secondary: '+262',
    code: 'RE',
  },
  BL: {
    primary: 'Saint Barthelemy',
    secondary: '+590',
    code: 'BL',
  },
  SH: {
    primary: 'Saint Helena, Ascension and Tristan Da Cunha',
    secondary: '+290',
    code: 'SH',
  },
  KN: {
    primary: 'Saint Kitts and Nevis',
    secondary: '+1869',
    code: 'KN',
  },
  LC: {
    primary: 'Saint Lucia',
    secondary: '+1758',
    code: 'LC',
  },
  MF: {
    primary: 'Saint Martin',
    secondary: '+590',
    code: 'MF',
  },
  PM: {
    primary: 'Saint Pierre and Miquelon',
    secondary: '+508',
    code: 'PM',
  },
  VC: {
    primary: 'Saint Vincent and the Grenadines',
    secondary: '+1784',
    code: 'VC',
  },
  WS: {
    primary: 'Samoa',
    secondary: '+685',
    code: 'WS',
  },
  SM: {
    primary: 'San Marino',
    secondary: '+378',
    code: 'SM',
  },
  ST: {
    primary: 'Sao Tome and Principe',
    secondary: '+239',
    code: 'ST',
  },
  SA: {
    primary: 'Saudi Arabia',
    secondary: '+966',
    code: 'SA',
  },
  SN: {
    primary: 'Senegal',
    secondary: '+221',
    code: 'SN',
  },
  RS: {
    primary: 'Serbia',
    secondary: '+381',
    code: 'RS',
  },
  SC: {
    primary: 'Seychelles',
    secondary: '+248',
    code: 'SC',
  },
  SL: {
    primary: 'Sierra Leone',
    secondary: '+232',
    code: 'SL',
  },
  SG: {
    primary: 'Singapore',
    secondary: '+65',
    code: 'SG',
  },
  SK: {
    primary: 'Slovakia',
    secondary: '+421',
    code: 'SK',
  },
  SI: {
    primary: 'Slovenia',
    secondary: '+386',
    code: 'SI',
  },
  SB: {
    primary: 'Solomon Islands',
    secondary: '+677',
    code: 'SB',
  },
  SO: {
    primary: 'Somalia',
    secondary: '+252',
    code: 'SO',
  },
  ZA: {
    primary: 'South Africa',
    secondary: '+27',
    code: 'ZA',
  },
  SS: {
    primary: 'South Sudan',
    secondary: '+211',
    code: 'SS',
  },
  GS: {
    primary: 'South Georgia and the South Sandwich Islands',
    secondary: '+500',
    code: 'GS',
  },
  ES: {
    primary: 'Spain',
    secondary: '+34',
    code: 'ES',
  },
  LK: {
    primary: 'Sri Lanka',
    secondary: '+94',
    code: 'LK',
  },
  SD: {
    primary: 'Sudan',
    secondary: '+249',
    code: 'SD',
  },
  SR: {
    primary: 'Suriprimary',
    secondary: '+597',
    code: 'SR',
  },
  SJ: {
    primary: 'Svalbard and Jan Mayen',
    secondary: '+47',
    code: 'SJ',
  },
  SZ: {
    primary: 'Swaziland',
    secondary: '+268',
    code: 'SZ',
  },
  SE: {
    primary: 'Sweden',
    secondary: '+46',
    code: 'SE',
  },
  CH: {
    primary: 'Switzerland',
    secondary: '+41',
    code: 'CH',
  },
  SY: {
    primary: 'Syrian Arab Republic',
    secondary: '+963',
    code: 'SY',
  },
  TW: {
    primary: 'Taiwan',
    secondary: '+886',
    code: 'TW',
  },
  TJ: {
    primary: 'Tajikistan',
    secondary: '+992',
    code: 'TJ',
  },
  TZ: {
    primary: 'Tanzania, United Republic of Tanzania',
    secondary: '+255',
    code: 'TZ',
  },
  TH: {
    primary: 'Thailand',
    secondary: '+66',
    code: 'TH',
  },
  TL: {
    primary: 'Timor-Leste',
    secondary: '+670',
    code: 'TL',
  },
  TG: {
    primary: 'Togo',
    secondary: '+228',
    code: 'TG',
  },
  TK: {
    primary: 'Tokelau',
    secondary: '+690',
    code: 'TK',
  },
  TO: {
    primary: 'Tonga',
    secondary: '+676',
    code: 'TO',
  },
  TT: {
    primary: 'Trinidad and Tobago',
    secondary: '+1868',
    code: 'TT',
  },
  TN: {
    primary: 'Tunisia',
    secondary: '+216',
    code: 'TN',
  },
  TR: {
    primary: 'Turkey',
    secondary: '+90',
    code: 'TR',
  },
  TM: {
    primary: 'Turkmenistan',
    secondary: '+993',
    code: 'TM',
  },
  TC: {
    primary: 'Turks and Caicos Islands',
    secondary: '+1649',
    code: 'TC',
  },
  TV: {
    primary: 'Tuvalu',
    secondary: '+688',
    code: 'TV',
  },
  UG: {
    primary: 'Uganda',
    secondary: '+256',
    code: 'UG',
  },
  UA: {
    primary: 'Ukraine',
    secondary: '+380',
    code: 'UA',
  },
  AE: {
    primary: 'United Arab Emirates',
    secondary: '+971',
    code: 'AE',
  },
  GB: {
    primary: 'United Kingdom',
    secondary: '+44',
    code: 'GB',
  },
  US: {
    primary: 'United States',
    secondary: '+1',
    code: 'US',
  },
  UY: {
    primary: 'Uruguay',
    secondary: '+598',
    code: 'UY',
  },
  UZ: {
    primary: 'Uzbekistan',
    secondary: '+998',
    code: 'UZ',
  },
  VU: {
    primary: 'Vanuatu',
    secondary: '+678',
    code: 'VU',
  },
  VE: {
    primary: 'Venezuela, Bolivarian Republic of Venezuela',
    secondary: '+58',
    code: 'VE',
  },
  VN: {
    primary: 'Vietnam',
    secondary: '+84',
    code: 'VN',
  },
  VG: {
    primary: 'Virgin Islands, British',
    secondary: '+1284',
    code: 'VG',
  },
  VI: {
    primary: 'Virgin Islands, U.S.',
    secondary: '+1340',
    code: 'VI',
  },
  WF: {
    primary: 'Wallis and Futuna',
    secondary: '+681',
    code: 'WF',
  },
  YE: {
    primary: 'Yemen',
    secondary: '+967',
    code: 'YE',
  },
  ZM: {
    primary: 'Zambia',
    secondary: '+260',
    code: 'ZM',
  },
  ZW: {
    primary: 'Zimbabwe',
    secondary: '+263',
    code: 'ZW',
  },
};
