import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button, Input, message } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import EmojiPicker from 'emoji-picker-react';
import { MouseDownEvent } from 'emoji-picker-react/dist/config/config';
import moment from 'moment';
import { FaPlus, FaRegKeyboard } from 'react-icons/fa6';
import { ImPlay2 } from 'react-icons/im';
import { IoCloseCircle, IoCloseCircleOutline } from 'react-icons/io5';
import {
  PiChartBarFill,
  PiFileText,
  PiFolderFill,
  PiHeadphonesFill,
  PiImageFill,
  PiPaperPlaneRightFill,
  PiPlayCircleFill,
  PiSmileyLight,
} from 'react-icons/pi';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { SocketAPI } from '../../../api/SocketAPI';
import audioWave from '../../../assets/img/audio-wave.jpg';
import {
  addNewMessage,
  setEditMessageData,
  updateOrDeleteSelectedMessage,
  updateReplyState,
} from '../../../context/communityMessageReducer';
import { uploadFilesForCommunityPost } from '../../../context/communityReducer';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  checkVideoFileValidation,
  convertImage,
  getFileExtension,
} from '../../../shared/utils';
import { ICreateMessageReqBody } from '../../../types/communityMessagesTypes';
import { ISelFile, TFileTypes } from '../../../types/communityTypes';
import { SOCKET_ACTION_EVENTS } from '../../../types/socketTypes';
import CreateCommunityPostAttachment from '../../CreateCommunityPost/components/CreateCommunityPostAttachment';

const MAX_MEDIA_COUNT = 10;

type Props = {};

const CommunityInputWithEmojiPicker: React.FC<Props> = () => {
  // store related date or params or query params data
  const dispatch = useAppDispatch();
  const { editMessageData, chatRoomDetails, replayMessageData, replyState } =
    useAppSelector((state) => state?.communityMessage);

  // component states
  const [openEmoji, setOpenEmoji] = useState(false);
  const [openSelectMediaCard, setOpenSelectMediaCard] = useState(false);
  const [selFiles, setSelFiles] = useState<ISelFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');

  // componenet refs
  const textareaRef = useRef<TextAreaRef | null>(null);

  const closeModal = () => {
    setOpenEmoji(false);
  };

  const addedIcon = useMemo(
    () => (
      <div className="addon">
        <button
          className="addon_button"
          disabled={loading}
          onClick={() => {
            if (openEmoji) {
              textareaRef.current?.focus();
            }
            setOpenEmoji((prev) => !prev);
          }}>
          {openEmoji ? <FaRegKeyboard /> : <PiSmileyLight />}
        </button>
      </div>
    ),
    [loading, openEmoji],
  );

  const setImgFile = (imageFile: File, url: string) => {
    const ext = getFileExtension(imageFile.name)?.[1].toLowerCase() || '';
    const name = imageFile.name.substring(
      0,
      imageFile.name.length - ext.length - 1,
    );
    setSelFiles((prev) => {
      const oldState = prev?.length ? prev : [];
      return [
        ...oldState,
        {
          file: imageFile,
          name,
          ext,
          type: 'image',
          url,
        },
      ];
    });
  };

  const beforeUpload = async (fileData: File, fileType: TFileTypes | 'doc') => {
    let clickedFileType = fileType;
    let isValid = true;
    let url: string | null = null;

    // when image
    if (clickedFileType === 'image') {
      // const isValidImageExtension =
      //     validImageExtensions.indexOf(file.type) !== -1;
      const type = getFileExtension(fileData.name)?.[1].toLowerCase();
      const isImage =
        type === 'jpg' ||
        type === 'png' ||
        type === 'gif' ||
        type === 'webp' ||
        type === 'tiff' ||
        type === 'jpeg' ||
        type === 'heic' ||
        type === 'svg';
      if (!isImage) {
        isValid = false;
        message.error('You can only put a valid image here!');
        return false;
      }

      const isImageLt2M = fileData.size / 1024 / 1024 < 1024;

      if (!isImageLt2M) {
        isValid = false;
        message.error('Image must smaller than 1024MB!');
        return false;
      }

      convertImage(type, fileData, setImgFile);
      return;
    }

    // when video
    if (clickedFileType === 'video') {
      const isVideo = checkVideoFileValidation(fileData);
      if (!isVideo) {
        isValid = false;
        message.error('You can only upload valid video file!');
      }

      if (fileData?.size > 5 * 1024 * 1024 * 1024) {
        message.error('File limit is upto 5GB');
        return false;
      }

      // create video preview
      url = URL.createObjectURL(fileData);
      // videoParentRef?.current?.load();
    }

    // when audio
    if (clickedFileType === 'audio') {
      const fileExtension = getFileExtension(fileData.name)?.[1];
      if (fileExtension === undefined || fileExtension === null) {
        isValid = false;
        message.error('Sorry, something went wrong. Try another audio file.');
      } else {
        const type = fileExtension.toLowerCase();
        const isAudio =
          type === 'mp3' ||
          type === 'm4a' ||
          type === 'aac' ||
          type === 'm3u' ||
          type === 'oga' ||
          type === 'wav';
        if (!isAudio) {
          isValid = false;
          message.error('You can only upload audio file!');
        }
        url = URL.createObjectURL(fileData);
      }
    }

    if (clickedFileType === 'doc') {
      isValid = true;
      url = URL.createObjectURL(fileData);
    }

    if (isValid && url && typeof url === 'string') {
      // message.success("File added successfully.");
      const ext = getFileExtension(fileData.name)?.[1].toLowerCase() || '';
      const name = fileData.name.substring(
        0,
        fileData.name.length - ext.length - 1,
      );
      setSelFiles((prev) => {
        const oldState = prev?.length ? prev : [];
        return [
          ...oldState,
          {
            file: fileData,
            name,
            ext,
            type: clickedFileType,
            url: typeof url === 'string' ? url : '',
          } as ISelFile,
        ];
      });
    } else {
      message.error('File upload failed.');
    }
  };

  const onSelect = async (type: TFileTypes | 'doc') => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', true as unknown as string);
    if (type === 'image')
      input.setAttribute(
        'accept',
        '.jpg, .jpeg, .png, .svg, .gif, .bmp, .webp, .tiff, .heic',
      );
    else if (type === 'video')
      input.setAttribute(
        'accept',
        '.video/mp4, .mp4, .video/webm, .webm, .video/quicktime, .quicktime, .video/mov, .mov, .video/mkv, .mkv',
      );
    else if (type === 'audio')
      input.setAttribute('accept', '.mp3, .m4a, .aac, .m3u, .oga, .wav');
    input.click();
    input.addEventListener('change', (e: any) => {
      let evt: React.ChangeEvent<HTMLInputElement> = e as any;
      if (!evt.target?.files) return;
      if (
        Array.from(e.target.files).length >
        MAX_MEDIA_COUNT - (selFiles?.length || 0)
      ) {
        e.preventDefault();
        alert(`Cannot upload files more than ${MAX_MEDIA_COUNT}`);
        return;
      }
      console.log(e.target.files);
      Array.from(e.target.files).map((eachFile) => {
        beforeUpload(eachFile as File, type);
      });
      setOpenSelectMediaCard(false);
    });
  };

  const postContentOptions: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    backgroundColor?: string;
  }[] = useMemo(() => {
    const iconSize = 26;
    const items = [
      {
        icon: <PiFolderFill size={iconSize} fill="#000" />,
        label: 'File',
        backgroundColor: '#F2F2F5',
        onClick: () => {
          onSelect('doc');
        },
      },
      {
        icon: <PiImageFill size={iconSize} fill="#0091FF" />,
        backgroundColor: '#EDF6FF',
        label: 'Photo',
        onClick: () => {
          onSelect('image');
        },
      },
      {
        icon: <PiPlayCircleFill size={iconSize} fill="#E5484D" />,
        label: 'Video',
        backgroundColor: '#FFEFEF',
        onClick: () => {
          onSelect('video');
        },
      },
      {
        icon: <PiHeadphonesFill size={iconSize} fill="#8E4EC6" />,
        label: 'Audio',
        backgroundColor: '#F9F1FE',
        onClick: () => {
          onSelect('audio');
        },
      },
      {
        icon: <PiChartBarFill size={iconSize} fill="#30A46C" />,
        label: 'Polls',
        backgroundColor: '#E9F9EE',
        onClick: () => {
          // navigate(ROUTES.COMMUNITY_CREATE_POLL, undefined, {
          //   replace: true,
          // });
        },
      },
    ];
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectEmoji = useCallback((emoji: string) => {
    if (!textareaRef.current) return;

    const input = textareaRef.current;

    const textarea = input?.resizableTextArea?.textArea;

    if (!textarea) return;

    let selectionStart = textarea?.selectionStart;
    let selectionEnd = textarea?.selectionEnd;

    if (selectionStart === null || selectionEnd === null) return;

    if (
      selectionStart === 0 &&
      selectionEnd === 0 &&
      textarea?.textLength > 0
    ) {
      selectionStart = textarea?.textLength;
      selectionEnd = textarea?.textLength;
    }
    setText(
      (prev) =>
        prev.slice(0, selectionStart) + emoji + prev.slice(selectionEnd),
    );
    const newCursor = selectionStart + emoji.length;
    setTimeout(() => textarea.setSelectionRange(newCursor, newCursor), 10);
    textarea.focus();
  }, []);

  const addMessage = async () => {
    if (!chatRoomDetails?._id) return;
    if (!text?.trim() && !selFiles?.length) return;
    setLoading(true);
    let assets: string[] = [];
    if (selFiles?.length) {
      const resp = await dispatch(
        uploadFilesForCommunityPost(
          selFiles.map((each) => each?.file) as File[],
        ),
      );
      if (resp && resp?.length > 0) {
        assets = resp.map((each) => each._id);
      }
    }
    const reqBody: ICreateMessageReqBody = {
      room: chatRoomDetails?._id,
      message: text || ' ',
      assets,
    };
    if (replayMessageData?._id) {
      reqBody.threadRoot = replayMessageData?._id;
    }
    SocketAPI.emit(
      SOCKET_ACTION_EVENTS.ROOM_MESSAGE_CREATE,
      reqBody,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (resp: any) => {
        if (resp?.status === 'success') {
          if (replayMessageData?._id) {
            dispatch(
              updateReplyState({
                replies: [...replyState?.replies, resp.result],
              }),
            );
            const currentThreadMetadata =
              replayMessageData?.threadRootMetadata || {
                totalReplies: 0,
                lastRepliedAt: moment().toISOString(),
              };
            const currentReplyCount = currentThreadMetadata?.totalReplies || 0;
            const newReplyCount = currentReplyCount + 1;
            dispatch(
              updateOrDeleteSelectedMessage(
                {
                  ...replayMessageData,
                  threadRootMetadata: {
                    ...currentThreadMetadata,
                    totalReplies: newReplyCount,
                  },
                },
                false,
                () => {},
              ),
            );
          } else {
            dispatch(addNewMessage(resp.result));
          }
          setText('');
          setSelFiles([]);
          message.success('Message update succesfully');
          setTimeout(() => {
            // if (scrollContainerRef.current) {
            //   scrollContainerRef.current.scrollTop = 0;
            // }
          }, 500);
        } else {
          message.error('Failed to send message');
        }
      },
    );
    setLoading(false);
  };

  const handleCloseEditMessage = () => {
    setText('');
    dispatch(setEditMessageData(null));
  };

  const editMessage = () => {
    if (!editMessageData?._id || !chatRoomDetails?._id) return;
    setLoading(true);
    SocketAPI.emit(
      SOCKET_ACTION_EVENTS.ROOM_MESSAGE_EDIT,
      {
        room: chatRoomDetails?._id,
        messageId: editMessageData?._id,
        message: text,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (resp: any) => {
        if (resp?.status === 'success') {
          if (replayMessageData?._id) {
            const newReplyState = replyState?.replies?.map((each) => {
              if (each?._id === replayMessageData?._id) {
                return resp.result;
              }
              return each;
            });
            dispatch(updateReplyState({ replies: newReplyState }));
            message.success('Edited message successfully');
          } else {
            dispatch(
              updateOrDeleteSelectedMessage(resp.result, false, () =>
                message.success('Edited message successfully'),
              ),
            );
          }
          handleCloseEditMessage();
        } else {
          message.error(resp?.message || 'Failed to edit message');
        }
      },
    );
    message.error('Failed to edit message');
    setLoading(false);
  };

  const displayMedia = (fileData: ISelFile) => {
    if (fileData?.type === 'image') {
      return (
        <div className="seleted-media_wrapper_media">
          <img src={fileData?.url} />
        </div>
      );
    }
    if (fileData?.type === 'video') {
      return (
        <div className="seleted-media_wrapper_media video">
          <ImPlay2 />
        </div>
      );
    }
    if (fileData?.type === 'audio') {
      return (
        <div className="seleted-media_wrapper_media">
          <img src={audioWave} />
        </div>
      );
    }
    if (fileData?.type === 'doc') {
      return (
        <div className="seleted-media_wrapper_media">
          <PiFileText />
          <p>{fileData?.name}</p>
        </div>
      );
    }
    return null;
  };

  const handleOnEmojiClick: MouseDownEvent = (event) => {
    onSelectEmoji(event?.emoji);
  };

  const handleAddFile = () => {
    setOpenSelectMediaCard(true);
  };

  useEffect(() => {
    if (editMessageData) {
      setText(editMessageData?.message);
    } else {
      setText('');
    }
  }, [editMessageData]);

  return (
    <>
      <div className="chat-input_wrapper">
        <div className="chat-input_wrapper_">
          <div className="chat-input_wrapper__plus">
            <button
              onClick={
                editMessageData?._id ? handleCloseEditMessage : handleAddFile
              }>
              {editMessageData?._id ? <IoCloseCircleOutline /> : <FaPlus />}
            </button>
          </div>
          <div className="chat-input_wrapper__input">
            <Input.TextArea
              // suffix={addedIcon}
              className="chat-input_wrapper__input_textarea"
              placeholder="Type a message"
              value={text}
              disabled={loading}
              autoSize={{ minRows: 1 }}
              onChange={(e) => {
                setText(e?.target?.value);
              }}
              ref={(ref) => (textareaRef.current = ref)}
            />
            {addedIcon}
          </div>
          <div className="chat-input_wrapper__send">
            <button
              disabled={loading}
              onClick={editMessageData?._id ? editMessage : addMessage}>
              <PiPaperPlaneRightFill />
            </button>
          </div>
        </div>
        {openSelectMediaCard ? (
          <div className="chat-media-wrapper">
            <CreateCommunityPostAttachment
              noBorder
              options={postContentOptions}
            />
          </div>
        ) : null}
        <div className="seleted-media_wrapper">
          {selFiles.map((each, index) => (
            <div className="seleted-media_wrapper_media">
              {displayMedia(each)}
              <div className="seleted-media_wrapper_media_close">
                <Button
                  onClick={() => {
                    setSelFiles((prev) => {
                      const newFileList = [...prev];
                      newFileList.splice(index, 1);
                      return newFileList;
                    });
                  }}>
                  <IoCloseCircle size={24} />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <BottomSheet
        open={openEmoji}
        onDismiss={() => closeModal()}
        style={{ zIndex: 0 }}>
        <>
          {openEmoji ? (
            <div className="chat-input_wrapper">
              <div className="chat-input_wrapper_">
                <div className="chat-input_wrapper__input">
                  <Input.TextArea
                    // addonAfter={addedIcon}
                    className="chat-input_wrapper__input_textarea"
                    placeholder="Write a comment..."
                    value={text}
                    disabled={loading}
                    onChange={(e) => {
                      setText(e?.target?.value);
                    }}
                    ref={(ref) => (textareaRef.current = ref)}
                  />
                  {addedIcon}
                </div>
                <div className="chat-input_wrapper__send">
                  <button
                    disabled={loading}
                    onClick={editMessageData?._id ? editMessage : addMessage}>
                    <PiPaperPlaneRightFill />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
        <EmojiPicker
          className="emoji_container_wrapper"
          onEmojiClick={handleOnEmojiClick}
          autoFocusSearch={false}
          lazyLoadEmojis
        />
      </BottomSheet>
    </>
  );
};

export default memo(CommunityInputWithEmojiPicker);
