import React from 'react';

import { Helmet } from 'react-helmet';

import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import CommunityHeader from '../../components/Header/CommunityHeader';
// import { useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import CommunityContent from './CommunityContent';

type Props = {};
const title = `Comunnity page`;
const metaTitle = `Community page`;
const metaDescription = `This is community page. Check your posts, earnings and subscribers.`;

const Community: React.FC<Props> = () => {
  // const { selectedChannelData } = useAppSelector((state) => state.community);
  // const searchParams = new URLSearchParams(window.location.search);
  // const queryInfoValue = searchParams.get('info');

  return (
    <DynamicWrapper path={ROUTES.COMMUNITY}>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="dashboard, tagmango, influencers, influencer marketing, video shoutout, How to make money online, online skills, content, creator, Instagram creator, youtube creator,"
        />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="feed__container">
        <CommunityHeader />
        <CommunityContent />
      </div>
    </DynamicWrapper>
  );
};

export default Community;
