import React, { useEffect, useMemo, useRef } from 'react';

import { Typography } from 'antd';
// import { Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualized from 'easy-react-virtualized';

import CommunityAPI from '../../../api/CommunityAPI';
import {
  getChannelPosts,
  onRefresh,
  setCommunityViewedPostIds,
  updateCommunitPost,
} from '../../../context/communityReducer';
import {
  POST_MIN_VIEW_THRESHOLD,
  POST_MIN_VIEW_TIME,
} from '../../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { IChannelPostDetails } from '../../../types/communityTypes';
import PostSkeleton from '../../Feed/Posts/PostSkeleton';
import CommunityPostItem from './CommunityPostItems/CommunityPostItem';

type Props = {
  noPostsMessage?: string;
};

const noPostsMessageStyle: React.CSSProperties = {
  width: '100%',
  textAlign: 'center',
  margin: '20px 0',
};

const CommunityChannelPostList: React.FC<Props> = ({ noPostsMessage }) => {
  // console.log('this component load');

  // const userDetails = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  // const isMe = true;
  const userDetails = useAppSelector((state) => state.user);
  const {
    communityPostState,
    showing,
    sortBy,
    communityViewedPostIds,
    selectedChannelData,
  } = useAppSelector((state) => state.community);

  const channelId = useMemo(() => {
    return selectedChannelData?._id;
  }, [selectedChannelData?._id]);

  // console.log(postState);
  // const [postList, setPostList] = useState<IChannelPostDetails[]>([]);
  // const getPostList = async () => {
  //   const query = new URLSearchParams();
  //   query.append('page', '1');
  //   query.append('pageSize', '5');
  //   const resp = await CommunityAPI.getPostsByChannelId(
  //     channelId as string,
  //     query?.toString(),
  //   );
  //   setPostList(resp?.data?.result?.posts);
  // };
  // useEffect(() => {
  //   getPostList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [channelId]);

  // const noPostsContent = useMemo(() => {
  //   const title = 'Oops! No posts found!';
  //   const subtitle =
  //     'Looks like your Creator has not put up any content yet. But don’t worry, they’ll be doing it soon! 🙌';

  //   if (noPostsMessage) {
  //     return (
  //       <Typography.Paragraph style={noPostsMessageStyle}>
  //         {noPostsMessage}
  //       </Typography.Paragraph>
  //     );
  //   }

  //   return isMe ? (
  //     <Typography.Paragraph style={noPostsMessageStyle}>
  //       Oops! No posts found!
  //     </Typography.Paragraph>
  //   ) : (
  //     <NoDataFound title={title} subtitle={subtitle} />
  //   );
  // }, [noPostsMessage, isMe]);

  const channelIdRef = useRef<string | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const visibilityTimers = useRef<Map<string, number>>(new Map());

  const increaseAndUpdatePostViews = async (
    postId: string,
    post: IChannelPostDetails,
  ) => {
    if (!channelIdRef.current) return;
    try {
      const resp = await CommunityAPI.increasePostViews(
        channelIdRef.current as string,
        postId,
      );
      if (resp.status === 201) {
        dispatch(
          updateCommunitPost({
            ...post,
            totalViews: (post?.totalViews || 0) + 1,
          }),
        );
        // add to viewed set to avoid multiple view count increase
        dispatch(setCommunityViewedPostIds(postId));
      }
    } catch (error) {
      console.log('Error while increasing post views', error);
    }
  };

  const handlePostView = (postId: string) => {
    const post = communityPostState.posts.find((item) => item._id === postId);
    if (
      post &&
      !communityViewedPostIds.includes(postId) &&
      post?.post?.postedBy?._id !== userDetails.id
    ) {
      console.log('Post viewed:', postId);
      increaseAndUpdatePostViews(postId, post);
    }
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const now = Date.now();
    entries.forEach((entry) => {
      const postId = entry.target.getAttribute('data-post-id');
      if (!postId) return;

      if (entry.isIntersecting) {
        // console.log('Post visible:', postId);
        // Post is visible, add or update its visibility start time
        if (!visibilityTimers.current.has(postId)) {
          visibilityTimers.current.set(postId, now);
        }
      } else {
        // Post is no longer visible, check if it was visible long enough
        const startTime = visibilityTimers.current.get(postId);

        if (startTime) {
          const visibilityDuration = now - startTime;

          // console.log('Post hidden:', postId, startTime);

          if (visibilityDuration >= POST_MIN_VIEW_TIME) {
            handlePostView(postId);
          }
        }

        visibilityTimers.current.delete(postId);
      }
    });
  };

  const loadMore = async () => {
    // console.log(postState);
    if (communityPostState.hasMore) {
      dispatch(
        getChannelPosts({
          pageCount: communityPostState.page,
          postList: communityPostState.posts,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(onRefresh());
    // dispatch(setCommunityPostState(initialPostState));
  }, [dispatch, channelId, showing, sortBy]);

  useEffect(() => {
    const postElements = document.querySelectorAll('.community-post-item');
    postElements.forEach((el) => observer?.current?.observe(el));

    return () => {
      postElements.forEach((el) => observer?.current?.unobserve(el));
    };
  }, [communityPostState.posts, communityViewedPostIds]);

  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
      threshold: POST_MIN_VIEW_THRESHOLD,
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityPostState.posts, communityViewedPostIds]);

  useEffect(() => {
    if (typeof channelId === 'string') {
      channelIdRef.current = channelId;
    }
  }, [channelId]);

  return (
    <div className="feed__content">
      <EasyVirtualized
        onLoadMore={loadMore}
        useParentScrollElement
        hasMore={communityPostState.hasMore}>
        {communityPostState.posts.map((item) => (
          <CommunityPostItem
            key={item._id}
            communityPostDetails={item}
            // index={index}
            // onCommentsPressed={() => {
            //   setSelectedPostId(item._id);
            //   setShowModal(true);
            // }}
          />
        ))}

        {userDetails.type !== 'creator_restricted' ? (
          communityPostState.loading || communityPostState.hasMore ? (
            <>
              <PostSkeleton />
              <PostSkeleton />
            </>
          ) : communityPostState.posts.length === 0 ? (
            noPostsMessage
          ) : (
            <Typography.Paragraph style={noPostsMessageStyle}>
              That’s it! No more posts to show.
            </Typography.Paragraph>
          )
        ) : null}
      </EasyVirtualized>
    </div>
  );
};

export default CommunityChannelPostList;
