import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Divider, Modal } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import CommunityMessagesAPI from '../../../api/CommunityMessageAPI';
import Header from '../../../components/Header/Header';
import {
  initialReplyState,
  setReplyMessageData,
  updateReplyState,
} from '../../../context/communityMessageReducer';
import showAppError from '../../../shared/error';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import CommunityChatbackgroundLayer from './CommunityChatbackgroundLayer';
import CommunityInputWithEmojiPicker from './CommunityInputWithEmojiPicker';
import MessagesCard from './MessagesCard';

type Props = {};

const REPLY_FETCH_COUNT = 10;

const ReplyThreadModel: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { replayMessageData, chatRoomDetails, replyState } = useAppSelector(
    (state) => state?.communityMessage,
  );

  const { selectedChannelData } = useAppSelector((state) => state.community);

  const [showModal, setShowModal] = useState(false);
  const [totalReplies, setTotalReplies] = useState(0);

  const roomId = useMemo(() => {
    if (chatRoomDetails?._id) {
      return chatRoomDetails?._id;
    }
    if (selectedChannelData?.chatRoom) {
      return selectedChannelData?.chatRoom;
    }
    return null;
  }, [chatRoomDetails?._id, selectedChannelData?.chatRoom]);

  const fetchThreadReplies = useCallback(async () => {
    if (!roomId || !replayMessageData?._id) return;
    if (replyState.loading || !replyState.hasMore) return;
    try {
      dispatch(
        updateReplyState({
          ...replyState,
          loading: true,
        }),
      );
      const query = new URLSearchParams();
      query.append('limit', REPLY_FETCH_COUNT.toString());
      // query.append('page', replyState.page.toString())
      if (replyState?.replies?.[0]?._id) {
        query.append('mode', 'before-message');
        query.append('messageId', replyState?.replies?.[0]?._id);
      } else {
        query.append('mode', 'latest');
      }
      const resp = await CommunityMessagesAPI.getRepliesOnThread(
        roomId,
        replayMessageData?._id,
        query.toString(),
      );

      if (resp?.status === 200) {
        dispatch(
          updateReplyState({
            ...replyState,
            hasMore: resp?.data?.result?.replies?.length === REPLY_FETCH_COUNT,
            loading: false,
            replies: [...resp?.data?.result?.replies, ...replyState?.replies],
          }),
        );
      } else {
        showAppError(resp);
        dispatch(
          updateReplyState({
            ...replyState,
            loading: false,
            hasMore: false,
          }),
        );
      }
    } catch (error) {
      dispatch(
        updateReplyState({
          ...replyState,
          loading: false,
          hasMore: false,
        }),
      );
      showAppError(error);
    }
  }, [replayMessageData?._id, dispatch, replyState, roomId]);

  const closeModal = () => {
    dispatch(setReplyMessageData(null));
    dispatch(updateReplyState(initialReplyState));
  };

  useEffect(() => {
    if (replayMessageData) {
      setShowModal(true);
      setTotalReplies(replayMessageData?.threadRootMetadata?.totalReplies || 0);
    } else {
      setShowModal(false);
    }
  }, [replayMessageData]);

  if (!replayMessageData) return null;

  return (
    <Modal
      open={showModal}
      title={null}
      destroyOnClose
      className="full-page-model"
      closable={false}
      footer={null}
      closeIcon={null}>
      <div className="reply-modal_container">
        <Header title={`Replies`} backType="arrow" handleBack={closeModal} />
        <div className="reply-modal_container_wrapper">
          <div className="reply-modal_container_wrapper_messages">
            <div className="reply-modal_container_wrapper_messages_main">
              <MessagesCard showReplyCard={false} message={replayMessageData} />
            </div>
            {totalReplies ? (
              <Divider orientation="left" plain style={{ color: '#1C2024' }}>
                {totalReplies} {totalReplies === 1 ? 'reply' : 'replies'}
              </Divider>
            ) : null}
            <EasyVirtualizedScroller
              useParentScrollElement
              scrollReverse
              hasMore={replyState?.hasMore}
              onLoadMore={() => fetchThreadReplies()}>
              <div className="reply-modal_container_wrapper_messages_replies">
                {replyState?.replies.map((each) => (
                  <MessagesCard key={each?._id} message={each} />
                ))}
              </div>
            </EasyVirtualizedScroller>
          </div>
          <div className="reply-modal_container_wrapper_input">
            <CommunityInputWithEmojiPicker />
          </div>
        </div>
      </div>
      <CommunityChatbackgroundLayer />
    </Modal>
  );
};

export default memo(ReplyThreadModel);
