import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';

import CommunityMessagesAPI from '../../api/CommunityMessageAPI';
import SearchBar from '../../components/Header/SearchBar';
import { setMessageRoomList } from '../../context/communityMessageReducer';
import { useTheme } from '../../context/ThemeProvider';
import showAppError from '../../shared/error';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import CommunityMessageCard from './components/CommunityRoomCard';

type Props = {};

const CommunityMessage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { colors } = useTheme();

  const dispatch = useAppDispatch();
  const { rooms } = useAppSelector((state) => state.communityMessage);

  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchMessageRoomList = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await CommunityMessagesAPI.getMessageRoomList();
      if (resp?.status === 200) {
        dispatch(setMessageRoomList(resp?.data?.result));
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const filterRooms = useMemo(() => {
    if (searchQuery?.trim()) {
      return rooms.filter((eachRoom) =>
        eachRoom?.channel?.name
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()),
      );
    }
    return rooms;
  }, [rooms, searchQuery]);

  const displayRoomList = useCallback(() => {
    return filterRooms.map((eachRoomData) => (
      <CommunityMessageCard
        roomData={eachRoomData}
        onClick={() => {
          navigate(
            ROUTES.COMMUNITY_MESSAGES_ROOM.replace(
              ':roomId',
              eachRoomData?._id,
            ),
          );
        }}
      />
    ));
  }, [filterRooms, navigate]);

  useEffect(() => {
    fetchMessageRoomList();
  }, [fetchMessageRoomList]);

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          padding: 10,
        }}>
        <div className="new-message_container">
          {Array(10)
            .fill(1)
            .map(() => (
              <Skeleton avatar paragraph={{ rows: 1 }} />
            ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {/* {newMessage === 'true' ? <CommunityNewMessage /> : null} */}
      <div
        style={{
          width: '100%',
        }}>
        <div className="new-message_search">
          <SearchBar
            onDebounce={(value) => {
              if (setSearchQuery) {
                setSearchQuery(value);
              }
            }}
            placeholder="Search"
            style={{
              width: '100%',
              borderColor: colors.BORDER,
              padding: 0,
            }}
          />
        </div>
        <div className="new-message_container">{displayRoomList()}</div>
      </div>
    </>
  );
};

export default CommunityMessage;
