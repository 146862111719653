import React, { useCallback } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { ROUTES } from '../../types/routes';
import FeedContent from '../Feed/FeedContent';
import CommunityChannel from './CommunityChannel';
import CommunityMessage from './CommunityMessage';
import CommunityTagmangoTeam from './CommunityTagmangoTeam';

type Props = {};

const CommunityContent: React.FC<Props> = () => {
  const location = useLocation();

  const displayCommunityContent = useCallback(() => {
    if (location.pathname === ROUTES.COMMUNITY) {
      return <Navigate to={ROUTES.COMMUNITY_FEED} />;
    }
    if (location.pathname === ROUTES.COMMUNITY_FEED) {
      return <FeedContent />;
    }
    if (location.pathname === ROUTES.COMMUNITY_MESSAGES) {
      return <CommunityMessage />;
    }
    if (location.pathname === ROUTES.COMMUNITY_TAGMANGO_TEAM) {
      return <CommunityTagmangoTeam />;
    }
    if (
      location.pathname.includes(
        ROUTES.COMMUNITY_CHANNEL.replace(':channelId', ''),
      )
    ) {
      return <CommunityChannel />;
    }
    return <Navigate to={ROUTES.COMMUNITY_FEED} />;
  }, [location?.pathname]);

  return displayCommunityContent();
};

export default CommunityContent;
