import React from 'react';

import { Badge } from 'antd';

type Props = {
  user: {
    _id: string;
    name: string;
    profilePicUrl: string;
    profilePicType: string;
    reaction?: string;
    onboarding?: string;
  };
  isOnline?: boolean;
  isClickable: boolean;
  onClick: () => void;
};

const CommunityUserCard: React.FC<Props> = ({
  user,
  isClickable,
  onClick,
  isOnline,
}) => {
  return (
    <div className="user-card">
      <div
        className={`user-card_user-wrapper`}
        onClick={isClickable ? onClick : () => {}}>
        <div className={`user-card_user-wrapper_avatar`}>
          {user?.profilePicType === 'image' ? (
            <img src={user?.profilePicUrl} alt="" />
          ) : (
            <div className="user-card_user-wrapper_avatar_emoji">
              {user?.profilePicUrl}
            </div>
          )}
          {isOnline ? (
            <Badge status="success" size="default" offset={[-10, 25]} />
          ) : null}

          <h4>{user?.name}</h4>
        </div>
      </div>
      {user?.reaction ? <div>{user?.reaction}</div> : null}
    </div>
  );
};

export default CommunityUserCard;
