import './styles.scss';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Input, message } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import {
  PiChartBarFill,
  PiClock,
  PiGear,
  PiHeadphonesFill,
  PiImageFill,
  PiPencilSimpleLight,
  PiPlayCircleFill,
  PiSmileyFill,
  PiSquaresFourFill,
} from 'react-icons/pi';
import { useLocation, useSearchParams } from 'react-router-dom';

import CommunityAPI from '../../api/CommunityAPI';
import FeedAPI from '../../api/FeedAPI';
import EmojiPickerModel from '../../components/EmojiPickerModel/EmojiPickerModel';
import HeaderIcon from '../../components/Header/actions/HeaderIcon';
import HeaderTextAction from '../../components/Header/actions/HeaderTextAction';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader';
import { createCommunityPost } from '../../context/communityReducer';
import showAppError from '../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import {
  checkVideoFileValidation,
  convertImage,
  getFileExtension,
  reorderItemInList,
} from '../../shared/utils';
import {
  IChannel,
  IChannelPostDetails,
  IPostMediaLayout,
  IPostReqBody,
  IPostReqBodyPostSettings,
  ISelFile,
  TFileTypes,
} from '../../types/communityTypes';
// import { IMango, IPost } from '../../types/feedTypes';
import { ROUTES } from '../../types/routes';
import CommunityPostItem from '../Community/CommunityPost/CommunityPostItems/CommunityPostItem';
import CommunityPostChooseLayoutModel from './components/CommunityPostChooseLayoutModel';
import CommunityPostScheduleDetails from './components/CommunityPostScheduleDetails';
import CommunityPostScheduleModel from './components/CommunityPostScheduleModel';
import CommunityPostSettingsModel from './components/CommunityPostSettings';
import CreateCommunityPostAttachment from './components/CreateCommunityPostAttachment';
import CreateCommunityPostHeader from './components/CreateCommunityPostHeader';
import CreatePostMediaRender from './components/CreatePostMediaRender';
import PostMediaEditModel from './components/PostMediaEditModel';

// type TFileTypes = 'image' | 'audio' | 'video';

// interface ISelFile {
//   file: File;
//   name: string;
//   ext: string;
//   type: TFileTypes;
//   url: string;
// }

// const mb16 = 16 * 1024 * 1024;

const MAX_MEDIA_COUNT = 10;

const CreateCommunityPost = () => {
  const navigate = useAppNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const {
    hostMetadata: { offeringTitle },
  } = useAppSelector((state) => state.app);
  // const { creatorMangoes, subscribedMangoes, ...userDetails } = useAppSelector(
  //   (state) => state.user,
  // );
  const [searchParams] = useSearchParams();

  const postRefId = searchParams.get('postRefId');
  const channelRefId = searchParams.get('channelId');

  const [initLoading, setInitLoading] = useState(false);
  const [hasVideoPermissions, setHasVideoPermissions] = useState(false);
  const [description, setDescription] = useState('');
  const [selFile, setSelFile] = useState<ISelFile[]>([]);
  // const [selectedMangoes, setSelectedMangoes] = useState<IMango[]>([]);
  const [postRefDetails, setPostRefDetails] =
    useState<IChannelPostDetails | null>(
      location.state?.postRefDetails as IChannelPostDetails | null,
    );
  const [isLoadingPostRef, setIsLoadingPostRef] = useState(false);
  const [openSettingsModel, setOpenSettingsModel] = useState(false);
  const [openScheduleModel, setOpenScheduleModel] = useState(false);
  const [openLayoutModel, setOpenLayoutModel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openEmojiModel, setOpenEmojiModel] = useState(false);
  const [openMediaEdit, setOpenMediaEdit] = useState(false);

  const [selectedChannelList, setSelectedChannelList] = useState<IChannel[]>(
    [],
  );
  const [postSettings, setPostSettings] = useState<IPostReqBodyPostSettings>({
    disableComment: false,
    hideComment: false,
    hideLikes: false,
  });

  const [scheduleDateTime, setScheduleDateTime] = useState<string | null>(null);

  const textareaRef = useRef<TextAreaRef | null>(null);

  const [selectedLayout, setSelectedLayout] =
    useState<IPostMediaLayout>('carousel');

  // const mangoesToDisplay = useMemo(() => {
  //   if (
  //     ['creator_completed', 'dual', 'creator_restricted'].includes(
  //       userDetails.type || '',
  //     )
  //   ) {
  //     return creatorMangoes;
  //   } else if (
  //     subscribedMangoes.length > 0 &&
  //     communityEnabledMangoes &&
  //     communityEnabledMangoes.length > 0 &&
  //     ['fan_completed'].includes(userDetails.type || '')
  //   ) {
  //     const subscribedMangoesWithCommunityEnabled = subscribedMangoes.filter(
  //       (mango) => communityEnabledMangoes.includes(mango._id),
  //     );
  //     return subscribedMangoesWithCommunityEnabled;
  //   }
  //   return [];
  // }, [
  //   creatorMangoes,
  //   userDetails.type,
  //   subscribedMangoes,
  //   communityEnabledMangoes,
  // ]);

  const isDisabled = useMemo(() => {
    return (
      (description.length === 0 && !selFile?.length) ||
      selectedChannelList.length === 0
    );
  }, [description.length, selFile?.length, selectedChannelList.length]);

  const fetchPostDetails = async () => {
    if (!postRefId && !channelRefId) return;

    setIsLoadingPostRef(true);

    try {
      const res = await CommunityAPI.getCommunityPostById(
        channelRefId as string,
        postRefId as string,
      );
      if (res.status === 200) {
        setPostRefDetails(res.data.result.posts[0]);
      } else {
        showAppError(res.data);
      }
    } catch (err: any) {
      showAppError(err);
      navigate.goBack();
    }
  };

  useEffect(() => {
    if (!postRefDetails?._id) {
      fetchPostDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRefId, postRefDetails]);

  useEffect(() => {
    setInitLoading(true);
    FeedAPI.getVideoUploadPermissions()
      .then((res) => {
        if (res.data.result.permissionEnabled) {
          setHasVideoPermissions(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setInitLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   if (
  //     selectedMangoes.length === 0 &&
  //     mangoesToDisplay &&
  //     mangoesToDisplay.length === 1
  //   ) {
  //     setSelectedMangoes([mangoesToDisplay[0]]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mangoesToDisplay]);

  const setFileAndThumbnail = (imageFile: File, url: string) => {
    const ext = getFileExtension(imageFile.name)?.[1].toLowerCase() || '';
    const name = imageFile.name.substring(
      0,
      imageFile.name.length - ext.length - 1,
    );
    setSelFile((prev) => {
      const oldState = prev?.length ? prev : [];
      return [
        ...oldState,
        {
          file: imageFile,
          name,
          ext,
          type: 'image',
          url,
        },
      ];
    });
  };

  const beforeUpload = async (fileData: File, fileType: TFileTypes) => {
    let clickedFileType = fileType;
    let isValid = true;
    let url: string | null = null;

    // when image
    if (clickedFileType === 'image') {
      // const isValidImageExtension =
      //     validImageExtensions.indexOf(file.type) !== -1;
      const type = getFileExtension(fileData.name)?.[1].toLowerCase();
      const isImage =
        type === 'jpg' ||
        type === 'png' ||
        type === 'gif' ||
        type === 'webp' ||
        type === 'tiff' ||
        type === 'jpeg' ||
        type === 'heic' ||
        type === 'svg';
      if (!isImage) {
        isValid = false;
        // message.error("You can only valid image type!");
        message.error('You can only put a valid image here!');
        return false;
      }

      const isImageLt2M = fileData.size / 1024 / 1024 < 1024;

      if (!isImageLt2M) {
        isValid = false;
        message.error('Image must smaller than 1024MB!');
        return false;
      }

      convertImage(type, fileData, setFileAndThumbnail);
      return;
    }

    // when video
    if (clickedFileType === 'video') {
      const isVideo = checkVideoFileValidation(fileData);
      if (!isVideo) {
        isValid = false;
        message.error('You can only upload valid video file!');
      }

      if (fileData?.size > 5 * 1024 * 1024 * 1024) {
        message.error('File limit is upto 5GB');
        return false;
      }

      // create video preview
      url = URL.createObjectURL(fileData);
      // videoParentRef?.current?.load();
    }

    // when audio
    if (clickedFileType === 'audio') {
      const fileExtension = getFileExtension(fileData.name)?.[1];
      if (fileExtension === undefined || fileExtension === null) {
        isValid = false;
        message.error('Sorry, something went wrong. Try another audio file.');
      } else {
        const type = fileExtension.toLowerCase();
        const isAudio =
          type === 'mp3' ||
          type === 'm4a' ||
          type === 'aac' ||
          type === 'm3u' ||
          type === 'oga' ||
          type === 'wav';
        if (!isAudio) {
          isValid = false;
          message.error('You can only upload audio file!');
        }
        url = URL.createObjectURL(fileData);
      }
    }

    if (isValid && url && typeof url === 'string') {
      // message.success("File added successfully.");
      const ext = getFileExtension(fileData.name)?.[1].toLowerCase() || '';
      const name = fileData.name.substring(
        0,
        fileData.name.length - ext.length - 1,
      );
      setSelFile((prev) => {
        const oldState = prev?.length ? prev : [];
        return [
          ...oldState,
          {
            file: fileData,
            name,
            ext,
            type: clickedFileType,
            url: typeof url === 'string' ? url : '',
          },
        ];
      });
    } else {
      message.error('File upload failed.');
    }
  };

  const onSelect = async (type: TFileTypes | 'emoji') => {
    // setSelFile(null);
    if (type === 'emoji') {
      setOpenEmojiModel(true);
      return;
    }
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', true as unknown as string);
    if (type === 'image')
      input.setAttribute(
        'accept',
        '.jpg, .jpeg, .png, .svg, .gif, .bmp, .webp, .tiff, .heic',
      );
    else if (type === 'video')
      input.setAttribute(
        'accept',
        '.video/mp4, .mp4, .video/webm, .webm, .video/quicktime, .quicktime, .video/mov, .mov, .video/mkv, .mkv',
      );
    else if (type === 'audio')
      input.setAttribute('accept', '.mp3, .m4a, .aac, .m3u, .oga, .wav');
    input.click();
    input.addEventListener('change', (e: any) => {
      let evt: React.ChangeEvent<HTMLInputElement> = e as any;
      if (!evt.target?.files) return;
      if (
        Array.from(e.target.files).length >
        MAX_MEDIA_COUNT - (selFile?.length || 0)
      ) {
        e.preventDefault();
        alert(`Cannot upload files more than ${MAX_MEDIA_COUNT}`);
        return;
      }
      Array.from(e.target.files).map((eachFile) => {
        beforeUpload(eachFile as File, type);
      });
    });
  };

  const onSelectEmoji = useCallback((emoji: string) => {
    if (!textareaRef.current) return;

    const input = textareaRef.current;

    const textarea = input?.resizableTextArea?.textArea;

    if (!textarea) return;

    let selectionStart = textarea?.selectionStart;
    let selectionEnd = textarea?.selectionEnd;

    if (selectionStart === null || selectionEnd === null) return;

    if (
      selectionStart === 0 &&
      selectionEnd === 0 &&
      textarea?.textLength > 0
    ) {
      selectionStart = textarea?.textLength;
      selectionEnd = textarea?.textLength;
    }
    setDescription(
      (prev) =>
        prev.slice(0, selectionStart) + emoji + prev.slice(selectionEnd),
    );
    const newCursor = selectionStart + emoji.length;
    setTimeout(() => textarea.setSelectionRange(newCursor, newCursor), 10);
    textarea.focus();
  }, []);

  const postContentOptions: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    backgroundColor?: string;
  }[] = useMemo(() => {
    const iconSize = 26;
    const items = [
      {
        icon: <PiImageFill size={iconSize} fill="#0091FF" />,
        backgroundColor: '#EDF6FF',
        label: 'Photo',
        onClick: () => {
          onSelect('image');
        },
      },
      {
        icon: <PiHeadphonesFill size={iconSize} fill="#8E4EC6" />,
        label: 'Audio',
        backgroundColor: '#F9F1FE',
        onClick: () => {
          onSelect('audio');
        },
      },
      {
        icon: <PiChartBarFill size={iconSize} fill="#30A46C" />,
        label: 'Polls',
        backgroundColor: '#E9F9EE',
        onClick: () => {
          navigate(ROUTES.COMMUNITY_CREATE_POLL, undefined, {
            replace: true,
          });
        },
      },
      {
        icon: <PiSmileyFill size={iconSize} fill="#FFB700" />,
        label: 'Emoji',
        backgroundColor: '#FFF3D0',
        onClick: () => {
          onSelect('emoji');
        },
      },
    ];
    if (hasVideoPermissions) {
      items.splice(1, 0, {
        icon: <PiPlayCircleFill size={iconSize} fill="#E5484D" />,
        label: 'Video',
        backgroundColor: '#FFEFEF',
        onClick: () => {
          onSelect('video');
        },
      });
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasVideoPermissions, navigate]);

  // const renderAttachment = useCallback((file: ISelFile) => {
  //   if (file.type === 'image') {
  //     return (
  //       <div className="createpost__attachment createpost__attachment__image">
  //         <div className="createpost__attachment__actions">
  //           <Button
  //             className="createpost__attachment__actions__button"
  //             onClick={() => {
  //               onSelect('image');
  //             }}>
  //             <IoPencil size={24} />
  //           </Button>
  //           <Button
  //             className="createpost__attachment__actions__button"
  //             onClick={() => {
  //               setSelFile([]);
  //             }}>
  //             <IoTrash size={24} />
  //           </Button>
  //         </div>
  //         <img src={file.url} alt={file.name} />
  //       </div>
  //     );
  //   } else if (file.type === 'video') {
  //     return (
  //       <div className="createpost__attachment createpost__attachment__video">
  //         <div className="createpost__attachment__actions">
  //           <Button
  //             className="createpost__attachment__actions__button"
  //             onClick={() => {
  //               setSelFile([]);
  //             }}>
  //             <IoTrash size={24} />
  //           </Button>
  //         </div>
  //         <BitmovinPlayer
  //           mediaUrl={file.url}
  //           isCompressDone
  //           mediaId="create-post-video"
  //         />
  //       </div>
  //     );
  //   } else if (file.type === 'audio') {
  //     return (
  //       <div className="createpost__attachment createpost__attachment__audio">
  //         <div className="createpost__attachment__actions">
  //           <Button
  //             className="createpost__attachment__actions__button"
  //             onClick={() => {
  //               setSelFile([]);
  //             }}>
  //             <IoTrash size={24} />
  //           </Button>
  //         </div>
  //         <AudioPlayer id="create-post-audio" url={file.url as string} />
  //       </div>
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // console.log(renderAttachment);

  const submit = () => {
    setInitLoading(true);
    setIsSaving(true);
    try {
      const reqBody: IPostReqBody = {
        channels: selectedChannelList?.map((each) => each?._id),
        caption: description,
        postSettings,
      };
      if (selFile?.length > 1) {
        reqBody.layoutType = selectedLayout;
      }
      if (scheduleDateTime) {
        reqBody.scheduleAt = scheduleDateTime;
      }
      if (postRefDetails?._id) {
        reqBody.postRef = postRefDetails?._id;
      }
      // const formData = new FormData();

      // if (selFile) {
      //   formData.append('file', selFile.file);
      //   formData.append('contentType', selFile.type);
      // } else {
      //   formData.append('contentType', 'text');
      // }

      // formData.append('description', description);

      // // if (selectedMangoes.length > 0) {
      // //   formData.append(
      // //     'mangoArr',
      // //     // [...selectedMangoes.map((mango) => mango._id)],
      // //     selectedMangoes.map((mango) => mango._id).join(','),
      // //   );
      // //   // formData.append('scheduleTime', 'Hello');
      // // }

      // if (selFile?.file.type === '') {
      //   formData.append('messageType', 'text');
      // } else if (selFile?.type === 'image' || selFile?.type === 'video') {
      //   formData.append('messageType', 'document');
      // } else if (selFile && selFile.file.size > mb16) {
      //   formData.append('messageType', 'document');
      // } else {
      //   formData.append('messageType', 'audio');
      // }

      // if (description && description.trim().length > 0) {
      //   formData.append('caption', description);
      // }

      // if (postRefDetails?._id) {
      //   formData.append('postRef', postRefDetails._id);
      // }
      dispatch(
        createCommunityPost(
          reqBody,
          selectedChannelList,
          description,
          selFile.map((each) => each?.file as File),
          () => {
            setIsSaving(false);
            navigate.goBack();
          },
        ),
      );
      // navigate.goBack();
    } catch (error) {
      showAppError(error);
    } finally {
      setInitLoading(false);
    }
  };

  const handleCloseSettingsModel = () => {
    setOpenSettingsModel(false);
  };

  const handleCloseSheduleModel = () => {
    setOpenScheduleModel(false);
  };

  const handleLayoutChooseClick = () => {
    setOpenLayoutModel(true);
  };

  const handleSaveSettings = (updatedSettings: IPostReqBodyPostSettings) => {
    setPostSettings(updatedSettings);
  };

  const handleClickOnSettings = () => {
    setOpenSettingsModel(true);
  };

  const handleClickOnSchedule = () => {
    setOpenScheduleModel(true);
  };

  const handleCloseLayoutModel = () => {
    setOpenLayoutModel(false);
  };

  const handleSaveShedule = (isoUTCDate: string | null) => {
    setScheduleDateTime(isoUTCDate);
    handleCloseSheduleModel();
  };

  const handleSaveLayoutChoose = (updatedLayout: IPostMediaLayout) => {
    setSelectedLayout(updatedLayout);
  };

  const handleCloseEmojiModel = () => {
    setOpenEmojiModel(false);
  };

  const handleCloseMediaEditModel = () => {
    setOpenMediaEdit(false);
  };
  const handleSaveMediaEditChanges = () => {
    handleCloseMediaEditModel();
  };

  const handleRemoveFile = (index: number) => {
    setSelFile((prev) => {
      const newFileList = [...prev];
      newFileList.splice(index, 1);
      return newFileList;
    });
  };

  const handleDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }
      const items = reorderItemInList(
        selFile,
        result.source.index,
        result.destination.index,
      );
      setSelFile(items);
    },
    [selFile],
  );

  const submitPost = async () => {
    if (
      (description?.trim().length > 0 || selFile?.length) &&
      selectedChannelList.length > 0
    ) {
      submit();
    } else {
      if (selectedChannelList.length === 0) {
        message.error(`Please select ${offeringTitle} to publish`);
        return;
      }
      // setProgressData((oldState) => ({ ...oldState, showProgress: false }));
      message.error('Please add some description/ media to your post');
    }
  };

  useEffect(() => {
    if (selFile?.length === 0 && openMediaEdit) {
      handleCloseMediaEditModel();
      setSelectedLayout('carousel');
    }
    if (selFile.length === 1 && selectedLayout === 'collage') {
      setSelectedLayout('carousel');
    }
  }, [openMediaEdit, selFile.length, selectedLayout]);

  useEffect(() => {
    let list = [...(document.getElementsByTagName('audio') ?? [])];
    list = [...list, ...(document.getElementsByTagName('video') ?? [])];
    list.forEach((element) => {
      element.pause();
    });
    return () => {
      list.forEach((element) => {
        element.pause();
      });
    };
  }, []);

  return (
    <>
      <div className="createpost__container">
        <Header
          title="Create"
          backType="cross"
          actionItems={[
            <HeaderIcon
              icon={
                <div className="header-icon-wrapper">
                  <PiGear />
                </div>
              }
              onClick={handleClickOnSettings}
            />,
            <HeaderIcon
              icon={
                <div className="header-icon-wrapper">
                  <PiClock />
                </div>
              }
              onClick={handleClickOnSchedule}
            />,
            <HeaderTextAction
              text="Post"
              disabled={isDisabled}
              onClick={submitPost}
              loading={isSaving}
              loadingText="Posting..."
              style={{ margin: '0 20px' }}
            />,
          ]}
        />
        {scheduleDateTime ? (
          <CommunityPostScheduleDetails
            scheduleDateTime={scheduleDateTime}
            handleSaveShedule={handleSaveShedule}
            handleClickOnSchedule={handleClickOnSchedule}
          />
        ) : null}
        <div className="scroll__container">
          <CreateCommunityPostHeader
            selectedChannelList={selectedChannelList}
            setSelectedChannelList={setSelectedChannelList}
          />

          <div className="createpost__description__container">
            <Input.TextArea
              className="createpost__description"
              bordered={false}
              placeholder="Add a caption..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoSize={{ minRows: 3 }}
              id="post-description-textarea"
              ref={(ref) => (textareaRef.current = ref)}
            />
          </div>

          {postRefDetails?._id ? (
            <div className="createpost__repost-card">
              <CommunityPostItem
                key={postRefDetails?._id}
                communityPostDetails={postRefDetails}
                isRepost={Boolean(postRefDetails?._id)}
                // allowRedirectToUserProfile={false}
              />
            </div>
          ) : null}

          {selFile?.length ? (
            <div
              className="createpost__media-card"
              style={{ position: 'relative' }}>
              <CreatePostMediaRender
                selectedLayout={selectedLayout}
                files={selFile}
              />
              <button
                className="createpost__media-card_edit-button"
                onClick={() => setOpenMediaEdit(true)}>
                <PiPencilSimpleLight />
                Edit
              </button>
            </div>
          ) : null}
        </div>
        {selFile?.length >= 2 ? (
          <div className="choose-layout_wrapper">
            <button
              className="choose-layout_wrapper_button"
              onClick={handleLayoutChooseClick}>
              <PiSquaresFourFill /> Choose layout
            </button>
          </div>
        ) : null}

        {!initLoading || !isLoadingPostRef ? (
          <CreateCommunityPostAttachment
            options={
              postRefDetails?._id
                ? postContentOptions.filter(
                    (option) => option.label === 'Emoji',
                  )
                : postContentOptions
            }
          />
        ) : null}
      </div>

      {initLoading || isLoadingPostRef ? (
        <div className="loading__overlay">
          <Loader size="large" />
        </div>
      ) : null}

      <CommunityPostSettingsModel
        show={openSettingsModel}
        closeModal={handleCloseSettingsModel}
        handleSave={handleSaveSettings}
        postSettings={postSettings}
      />
      <CommunityPostScheduleModel
        show={openScheduleModel}
        closeModal={handleCloseSheduleModel}
        handleSave={handleSaveShedule}
        scheduleDateTime={scheduleDateTime}
      />
      <CommunityPostChooseLayoutModel
        show={openLayoutModel}
        closeModal={handleCloseLayoutModel}
        handleSave={handleSaveLayoutChoose}
        selectedLayout={selectedLayout}
      />
      <EmojiPickerModel
        show={openEmojiModel}
        onSelectEmoji={onSelectEmoji}
        closeModal={handleCloseEmojiModel}
      />
      <PostMediaEditModel
        show={openMediaEdit}
        saveChanges={handleSaveMediaEditChanges}
        closeModal={handleCloseMediaEditModel}
        selFile={selFile}
        handleRemoveFile={handleRemoveFile}
        handleDragEnd={handleDragEnd}
      />
    </>
  );
};

export default CreateCommunityPost;
