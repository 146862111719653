import '../community.scss';

import React, { memo, useCallback } from 'react';

import { Collapse, Drawer } from 'antd';
import { BsCaretRightFill } from 'react-icons/bs';
import {
  PiArrowUpRightLight,
  PiChatsCircleFill,
  PiCompassFill,
} from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import tmLogo from '../../../assets/svg/TagMangoWithoutBorder.svg';
import {
  setActiveSection,
  setSelectedChannelData,
} from '../../../context/communityReducer';
import { routeVsTitle } from '../../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { IChannel, IChannelGroup, ILink } from '../../../types/communityTypes';
import { ROUTES } from '../../../types/routes';

type Props = {
  open: boolean;
  onClose: () => void;
};

const CommunityChannelDrawer: React.FC<Props> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeSection, groupChannelList, communityLinkList } = useAppSelector(
    (state) => state.community,
  );
  const handleSelectSection = useCallback(
    (
      section: string,
      channelData: IChannel | { name: string },
      url: string,
      isChannelSelected = false,
    ) => {
      dispatch(
        setActiveSection({
          activeSection: section,
          headerTitle: channelData?.name,
        }),
      );
      if (isChannelSelected) {
        dispatch(setSelectedChannelData(channelData as IChannel));
      } else {
        dispatch(setSelectedChannelData(null));
      }
      navigate(url);
      onClose();
    },
    [dispatch, navigate, onClose],
  );

  const displayChannel = useCallback(
    (channelData: IChannel) => {
      return (
        <button
          className={`community-drawer_static-list_button ${
            activeSection === channelData?._id
              ? 'community-drawer_static-list_active-section'
              : ''
          }`}
          key={channelData?._id}
          onClick={() =>
            handleSelectSection(
              channelData?._id,
              channelData,
              ROUTES.COMMUNITY_CHANNEL.replace(':channelId', channelData?._id),
              true,
            )
          }>
          <div className="community-drawer_static-list_button_wrapper">
            {channelData?.communityProfilePictureType === 'emoji' ? (
              <div className="community-drawer_static-list_button_wrapper_svg-wrapper">
                <div className="community-drawer_static-list_button_wrapper_svg-wrapper_emoji">
                  {channelData?.communityProfilePicture}
                </div>
              </div>
            ) : (
              <div className="community-drawer_static-list_button_wrapper_img-wrapper">
                <img src={channelData?.communityProfilePicture} alt="logo" />
              </div>
            )}
            {channelData?.name}
          </div>
        </button>
      );
    },
    [activeSection, handleSelectSection],
  );

  const handleLinkClick = (link: string) => {
    window.open(link);
  };

  const displayLink = useCallback((linkData: ILink) => {
    if (!(linkData?.link && linkData?.title)) return null;
    return (
      <button
        className={`community-drawer_static-list_button `}
        key={linkData?._id}
        onClick={() => handleLinkClick(linkData?.link)}>
        <div className="community-drawer_static-list_button_link-wrapper">
          <div className="community-drawer_static-list_button_link-wrapper_logo">
            <PiArrowUpRightLight />
          </div>
          {linkData?.title}
        </div>
      </button>
    );
  }, []);

  const displayChannelList = useCallback(
    (groupData: IChannelGroup) => {
      if (!(groupData?.channelArr?.length && groupData?.name)) return null;
      return (
        <Collapse.Panel header={groupData?.name} key={groupData?._id}>
          {(groupData?.channelArr || []).map((each) => displayChannel(each))}
        </Collapse.Panel>
      );
    },
    [displayChannel],
  );

  const displayLinkList = useCallback(
    (LinkList: ILink[]) => {
      return (
        <Collapse.Panel header={'Link'} key={'link'}>
          {LinkList.map((linkData) => displayLink(linkData))}
        </Collapse.Panel>
      );
    },
    [displayLink],
  );

  const displayGroupList = useCallback(() => {
    return (
      <Collapse expandIcon={() => <BsCaretRightFill />}>
        {groupChannelList.map((each) => displayChannelList(each))}
        {displayLinkList(communityLinkList)}
      </Collapse>
    );
  }, [
    communityLinkList,
    displayChannelList,
    displayLinkList,
    groupChannelList,
  ]);

  return (
    <Drawer title="" placement="left" onClose={onClose} open={open}>
      <div className="community-drawer">
        <div className="community-drawer_static-list">
          <button
            className={`community-drawer_static-list_button ${
              activeSection === 'tagmango-team'
                ? 'community-drawer_static-list_active-section'
                : ''
            }`}
            key="tagmango-team"
            onClick={() =>
              handleSelectSection(
                'tagmango-team',
                { name: routeVsTitle['team-tagmango'] },
                ROUTES.COMMUNITY_TAGMANGO_TEAM,
              )
            }>
            <div className="community-drawer_static-list_button_wrapper">
              <div className="community-drawer_static-list_button_wrapper_icon-wrapper">
                <img src={tmLogo} alt="tmlogo" />
              </div>
              {routeVsTitle['team-tagmango']}
            </div>
          </button>
          <button
            className={`community-drawer_static-list_button ${
              activeSection === 'feed'
                ? 'community-drawer_static-list_active-section'
                : ''
            }`}
            key="feed"
            onClick={() =>
              handleSelectSection(
                'feed',
                { name: routeVsTitle.feed },
                ROUTES.COMMUNITY_FEED,
              )
            }>
            <div className="community-drawer_static-list_button_wrapper">
              <div className="community-drawer_static-list_button_wrapper_svg-wrapper">
                <PiCompassFill />
              </div>
              {routeVsTitle.feed}
            </div>
          </button>
          <button
            className={`community-drawer_static-list_button ${
              activeSection === 'message'
                ? 'community-drawer_static-list_active-section'
                : ''
            }`}
            key="messages"
            onClick={() =>
              handleSelectSection(
                'messages',
                { name: routeVsTitle.messages },
                ROUTES.COMMUNITY_MESSAGES,
              )
            }>
            <div className="community-drawer_static-list_button_wrapper">
              <div className="community-drawer_static-list_button_wrapper_svg-wrapper">
                <PiChatsCircleFill />
              </div>
              {routeVsTitle.messages}
            </div>
          </button>
        </div>
        <div className="community-drawer_dynamic-list">
          {displayGroupList()}
        </div>
        <div className="community-drawer_empty-space" />
      </div>
    </Drawer>
  );
};

export default memo(CommunityChannelDrawer);
