import './styles.scss';
import './Posts/styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import FeedAPI from '../../api/FeedAPI';
import { checkForPermissions } from '../../context/userReducer';
import { useAppSelector } from '../../shared/hooks';
import { UAMPermissions } from '../../types/userTypes';
import AccountWarningCard from './components/AccountWarningCard/AccountWarningCard';
import CreateAPostCard from './components/CreateAPostCard/CreateAPostCard';
import FeedBannersSlider from './components/FeedBannersSlider/FeedBannersSlider';
import NoMangoComponent from './components/NoMangoComponent/NoMangoComponent';
import RenewMangoes from './components/RenewMangoes/RenewMangoes';
import { IRenewMango } from './components/RenewMangoes/types';
import UnbookedSlotsForConsultation from './components/UnbookedSlotsForConsultation/UnbookedSlotsForConsultation';
import UploadingComponent from './components/UploadingComponent/UploadingComponent';
import PostsList from './Posts/PostsList';
import UpcomingWorkshops from './VideoCall/UpcomingWorkshops';

type Props = {};

const FeedContent: React.FC<Props> = () => {
  const { hostMetadata, isCommunityEnabled, isTagMango } = useAppSelector(
    (state) => state.app,
  );
  const { creatorMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );
  const { postState, selected, progressData } = useAppSelector(
    (state) => state.activity,
  );
  const [renewMangoes, setRenewMangoes] = useState<IRenewMango[]>([]);

  const showCreatePostButton = useMemo(
    () =>
      Boolean(
        isTagMango
          ? userDetails.type === 'creator_completed' ||
              userDetails.type === 'dual'
          : userDetails.id === hostMetadata.creator._id,
      ) ||
      (userDetails.type === 'creator_restricted' &&
        checkForPermissions(
          [UAMPermissions.FEED_WRITE],
          userDetails.userAccessPermissions,
        )) ||
      (userDetails.type === 'fan_completed' &&
        isCommunityEnabled &&
        !userDetails.isBlockedFromCommunityEngagement),
    [
      hostMetadata.creator._id,
      isCommunityEnabled,
      isTagMango,
      userDetails.id,
      userDetails.isBlockedFromCommunityEngagement,
      userDetails.type,
      userDetails.userAccessPermissions,
    ],
  );

  const hasNoMangoes = useMemo(
    () =>
      (!creatorMangoes || creatorMangoes.length === 0) &&
      !postState.loading &&
      selected &&
      (userDetails.type === 'creator_completed' || userDetails.type === 'dual'),
    [creatorMangoes, postState.loading, selected, userDetails.type],
  );

  const isMe = useMemo(
    () =>
      isTagMango
        ? userDetails.id === selected
        : userDetails.id === hostMetadata.creator._id ||
          userDetails.type === 'creator_restricted' ||
          (userDetails.type === 'fan_completed' &&
            isCommunityEnabled &&
            !userDetails.isBlockedFromCommunityEngagement),
    [
      hostMetadata.creator._id,
      isCommunityEnabled,
      isTagMango,
      selected,
      userDetails.id,
      userDetails.isBlockedFromCommunityEngagement,
      userDetails.type,
    ],
  );

  const loadRenewMangoes = async () => {
    if (selected) {
      try {
        const resp = await FeedAPI.getRenewMangoes(
          selected === 'all' ? undefined : selected,
        );
        if (resp.status === 200 && resp.data.result) {
          setRenewMangoes(resp.data.result);
        }
      } catch (err) {
        console.log('renew mangoes err', err);
      }
    }
  };

  useEffect(() => {
    loadRenewMangoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className="feed__content">
      {isTagMango ? null : <FeedBannersSlider />}

      {showCreatePostButton ? <CreateAPostCard /> : null}

      {userDetails.type === 'creator_restricted' ? null : (
        <AccountWarningCard />
      )}

      {userDetails.type !== 'creator_restricted' &&
      isTagMango &&
      (userDetails.type === 'creator_completed' ||
        userDetails.type === 'dual') ? (
        <>
          <NoMangoComponent />
        </>
      ) : null}

      {renewMangoes.length > 0 && <RenewMangoes renewMangoes={renewMangoes} />}

      {(userDetails.type !== 'creator_restricted' ||
        checkForPermissions(
          [UAMPermissions.DASHBOARD_WORKSHOP_ACCESS],
          userDetails.userAccessPermissions,
        )) &&
      !hasNoMangoes ? (
        <UpcomingWorkshops />
      ) : null}

      <UnbookedSlotsForConsultation />

      {isMe || selected === 'all' ? (
        <UploadingComponent {...progressData} type="activity" />
      ) : null}

      {userDetails.type !== 'creator_restricted' ||
      checkForPermissions(
        [UAMPermissions.FEED_READ],
        userDetails.userAccessPermissions,
      ) ? (
        <PostsList />
      ) : null}
    </div>
  );
};

export default FeedContent;
