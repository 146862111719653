import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import CommunityMessagesAPI from '../api/CommunityMessageAPI';
import showAppError from '../shared/error';
import {
  ICommunityMessage,
  ICommunityMessageRoom,
  ICommunityMessageSlice,
  IInitialChatState,
  IMessageRoomParticipant,
  INewMessageUserState,
  IReplyState,
} from '../types/communityMessagesTypes';
import { AppThunk } from './store';

const NEW_USER_LIMIT = 10;

const CHAT_LIMIT = 10;

export const newMessageUserInitiateState = {
  users: [],
  hasMore: true,
  loading: false,
  page: 1,
};

export const initialChatState = {
  chats: [],
  hasMore: true,
  loading: false,
};

export const initialReplyState = {
  hasMore: true,
  loading: false,
  replies: [],
};

const initialState: ICommunityMessageSlice = {
  rooms: [],
  newMessageUserState: newMessageUserInitiateState,
  chatRoomDetails: null,
  chatsState: initialChatState,
  touchedMessageData: null,
  editMessageData: null,
  replayMessageData: null,
  currentChannelActiveUser: [],
  replyState: initialReplyState,
};

const communityMessageSlice = createSlice({
  initialState,
  name: 'communityMessage',
  reducers: {
    setMessageRoomList: (
      state,
      action: PayloadAction<ICommunityMessageRoom[]>,
    ) => {
      state.rooms = action?.payload;
    },
    updateNewMessageUserState: (
      state,
      action: PayloadAction<Partial<INewMessageUserState>>,
    ) => {
      Object.assign(state.newMessageUserState, action.payload);
    },
    setChatRoomDetails: (
      state,
      action: PayloadAction<ICommunityMessageRoom | null>,
    ) => {
      state.chatRoomDetails = action.payload;
    },
    updateChatState: (
      state,
      action: PayloadAction<Partial<IInitialChatState>>,
    ) => {
      Object.assign(state.chatsState, action.payload);
    },
    setTouchedMessageData: (
      state,
      action: PayloadAction<ICommunityMessage | null>,
    ) => {
      state.touchedMessageData = action.payload;
    },
    updateChatRoomDetails: (
      state,
      action: PayloadAction<Partial<ICommunityMessageRoom>>,
    ) => {
      Object.assign(
        state.chatRoomDetails as ICommunityMessageRoom,
        action.payload,
      );
    },
    setEditMessageData: (
      state,
      action: PayloadAction<ICommunityMessage | null>,
    ) => {
      state.editMessageData = action?.payload;
    },
    addNewMessage: (state, action: PayloadAction<ICommunityMessage>) => {
      const { chatsState } = state;
      const { chats } = chatsState;
      const newChatState = { ...chatsState, chats: [...chats, action.payload] };
      Object.assign(state.chatsState, newChatState);
    },
    updateActiveUsersInSelectedRoom: (
      state,
      action: PayloadAction<IMessageRoomParticipant[]>,
    ) => {
      const fullUserList = [
        ...state?.currentChannelActiveUser,
        ...action.payload,
      ];
      let uniqueUserIds: string[] = [];
      const onlineUserList = fullUserList.reduce(
        (acc: IMessageRoomParticipant[], eachUser) => {
          if (uniqueUserIds.includes(eachUser?._id)) {
            return [...acc];
          }
          uniqueUserIds.push(eachUser?._id);
          return [...acc, eachUser];
        },
        [],
      );

      state.currentChannelActiveUser = onlineUserList;
    },
    setActiveUserInSelectedRoom: (
      state,
      action: PayloadAction<IMessageRoomParticipant[]>,
    ) => {
      state.currentChannelActiveUser = action.payload;
    },

    setReplyMessageData: (
      state,
      action: PayloadAction<ICommunityMessage | null>,
    ) => {
      state.replayMessageData = action?.payload;
    },
    updateReplyState: (state, action: PayloadAction<Partial<IReplyState>>) => {
      Object.assign(state.replyState, action.payload);
    },
    // setForDeleteMessageData: (
    //   state,
    //   action: PayloadAction<ICommunityMessage | null>,
    // ) => {
    //   state.forDeleteSelectedMessage = action.payload;
    // },
  },
});

export const {
  setMessageRoomList,
  updateNewMessageUserState,
  setChatRoomDetails,
  updateChatState,
  setTouchedMessageData,
  updateChatRoomDetails,
  setEditMessageData,
  addNewMessage,
  updateActiveUsersInSelectedRoom,
  setActiveUserInSelectedRoom,
  setReplyMessageData,
  updateReplyState,
  // setForDeleteMessageData,
  // updateChatList,
  // setChatList,
} = communityMessageSlice.actions;

export default communityMessageSlice.reducer;

export const getNewUsers =
  (
    {
      page,
      users,
    }: {
      page: number;
      users: IMessageRoomParticipant[];
    },
    searchQuery: string = '',
  ): AppThunk =>
  async (dispatch, getState) => {
    const { communityMessage } = getState();
    const currentNewMessageUserState = {
      ...communityMessage?.newMessageUserState,
      ...{ page, users },
    };
    const updatedPage = currentNewMessageUserState?.page + 1;
    try {
      dispatch(updateNewMessageUserState({ loading: true }));
      const query = new URLSearchParams();
      query.append('limit', NEW_USER_LIMIT.toString());
      query.append('page', page.toString());
      if (searchQuery) {
        query.append('search', searchQuery);
      }
      const resp = await CommunityMessagesAPI.getNewMessageUser(
        query?.toString(),
      );
      if (resp?.status === 200 && resp?.data?.result?.length) {
        dispatch(
          updateNewMessageUserState({
            ...currentNewMessageUserState,
            users: [
              ...currentNewMessageUserState?.users,
              ...resp?.data?.result,
            ],
            page: updatedPage,
            hasMore: resp?.data?.result?.length === NEW_USER_LIMIT,
            loading: false,
          }),
        );
      } else {
        showAppError(resp?.data);
        dispatch(updateNewMessageUserState({ hasMore: false, loading: false }));
      }
    } catch (error) {
      showAppError(error);
      dispatch(updateNewMessageUserState({ hasMore: false, loading: false }));
    }
  };

export const getMessageRoomChats =
  (chats: ICommunityMessage[], roomId: string): AppThunk =>
  async (dispatch, getState) => {
    const { communityMessage } = getState();
    const currentChatsState = { ...communityMessage?.chatsState, chats: chats };
    try {
      dispatch(updateChatState({ loading: true }));
      const query = new URLSearchParams();
      query.append('limit', CHAT_LIMIT.toString());
      // const chatLength = currentChatsState?.chats?.length;
      // const lastMessage = currentChatsState?.chats?.[chatLength - 1];
      if (currentChatsState?.chats?.[0]?._id) {
        query.append('mode', 'before-message');
        query.append('messageId', currentChatsState?.chats?.[0]?._id);
      } else {
        query.append('mode', 'latest');
      }
      const resp = await CommunityMessagesAPI.getRoomMessage(
        roomId,
        query?.toString(),
      );
      if (resp?.status === 200) {
        dispatch(
          updateChatState({
            hasMore: resp?.data?.result?.length === CHAT_LIMIT,
            loading: false,
            chats: [...resp?.data?.result, ...currentChatsState?.chats],
          }),
        );
      } else {
        showAppError(resp?.data);
        dispatch(updateChatState({ hasMore: false, loading: false }));
      }
    } catch (error) {
      showAppError(error);
      dispatch(updateChatState({ hasMore: false, loading: false }));
    }
  };

export const updateOrDeleteSelectedMessage =
  (
    selectedMessage: ICommunityMessage,
    isDeleteAction: boolean = false,
    callBack?: () => void,
  ): AppThunk =>
  (dispatch, getState) => {
    const { communityMessage } = getState();
    const { chatsState } = communityMessage;
    const { chats } = chatsState;
    const updatedNewChatList = [...chats];
    // do the Call and on success
    const messageIndex = chats.findIndex(
      (eachMessage) => eachMessage?._id === selectedMessage?._id,
    );
    if (typeof messageIndex === 'number') {
      if (isDeleteAction) {
        updatedNewChatList.splice(messageIndex, 1);
      } else {
        updatedNewChatList.splice(messageIndex, 1, selectedMessage);
      }
    }
    dispatch(updateChatState({ ...chatsState, chats: updatedNewChatList }));
    if (callBack) {
      callBack();
    }
  };

export const changeChatsState =
  (
    action: 'pinned' | 'unpinned',
    roomId: string,
    messageId: string,
    setLoader: (loading?: boolean) => void,
    onSuccess: (data?: any) => void,
    onError: (error?: any) => void,
    reqBody?: Partial<IInitialChatState>,
    queryString?: string,
  ): AppThunk =>
  async () => {
    let actionAPI;
    if (action === 'pinned' || 'unpinned') {
      actionAPI = CommunityMessagesAPI.pinnedMessage;
    }

    if (!actionAPI) return;
    try {
      setLoader(true);
      const resp = await actionAPI(roomId, messageId, queryString as string);
      if (resp?.status < 299) {
        onSuccess(resp?.data);
      } else {
        onError(resp?.data);
      }
    } catch (error) {
      showAppError(error);
    }
  };
