import React, { useCallback, useMemo, useState } from 'react';

import { Button, message } from 'antd';
import { PiChat, PiEye, PiRepeatFill } from 'react-icons/pi';
import TMIcon from 'tm-icons-library';

import ShareBottomSheet from '../../../../components/Modals/ShareBottomSheet/ShareBottomSheet';
import {
  setPDPCommunityPostData,
  updateCommunitPost,
  userInterationAction,
} from '../../../../context/communityReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { handleShareLink } from '../../../../shared/utils';
import { IChannelPostDetails } from '../../../../types/communityTypes';
import { ROUTES } from '../../../../types/routes';
import LikeButton from '../../../Feed/Posts/PostItem/LikeButton';

const iconStyle = { fontSize: 24, color: '#000' };

type Props = {
  communityPostDetails: IChannelPostDetails;
  postId: string;
  channelId?: string;
  onRepostPressed?: () => void;
};

const CommunityPostStats: React.FC<Props> = ({
  communityPostDetails,
  postId,
  channelId: argChannelId,
  onRepostPressed,
}) => {
  const navigate = useAppNavigate();

  const { post, ...postDetails } = communityPostDetails;
  const { selectedChannelData } = useAppSelector((state) => state.community);
  const channelId = useMemo(() => {
    return selectedChannelData?._id;
  }, [selectedChannelData?._id]);
  const dispatch = useAppDispatch();
  const { colors } = useTheme();
  const {
    hostMetadata: { appName },
    isTier3,
    isTier4,
  } = useAppSelector((state) => state.app);

  const [showShareBottomSheet, setShowShareBottomSheet] = useState(false);

  const handleLinkPost = useCallback(
    (isLiked: boolean) => {
      const updatedAttributes = {
        totalLikes: isLiked
          ? postDetails?.totalLikes + 1
          : postDetails?.totalLikes - 1,
        isLiked,
      };
      const actionType = isLiked ? 'addLike' : 'removeLike';
      const thunkAction = window.location?.pathname.includes('/channel/')
        ? updateCommunitPost
        : setPDPCommunityPostData;
      dispatch(
        userInterationAction(
          actionType,
          communityPostDetails?._id,
          argChannelId || (channelId as string),
          communityPostDetails,
          updatedAttributes,
          (updatedData) => {
            dispatch(thunkAction(updatedData));
          },
        ),
      );
    },
    [
      argChannelId,
      channelId,
      communityPostDetails,
      dispatch,
      postDetails?.totalLikes,
    ],
  );

  return (
    <div className="community-post-stats-wrapper">
      <div className="community-post-stats-wrapper_like-wrapper">
        <LikeButton
          isLiked={postDetails?.isLiked}
          like={(like) => handleLinkPost(like)}
        />{' '}
        {post?.postSettings?.hideLikes ? null : (
          <p className="stats-count">{postDetails?.totalLikes}</p>
        )}
      </div>

      <div className="community-post-stats-wrapper_comment-wrapper">
        <Button
          className="siteBtn noStyle"
          onClick={() => {
            navigate(ROUTES.COMMUNITY_POST_DETAILS, {
              postId: communityPostDetails?._id,
              channelId: channelId as string,
            });
          }}>
          <PiChat style={iconStyle} />

          {post?.postSettings?.hideComment ? null : (
            <p className="stats-count">{postDetails?.totalComments}</p>
          )}
        </Button>
      </div>

      <div>
        <Button className="siteBtn noStyle">
          <PiEye style={iconStyle} />
          <p className="stats-count">{postDetails?.totalViews}</p>
        </Button>
      </div>

      <div className="community-post-stats-wrapper_share-wrapper">
        <Button
          className="siteBtn noStyle"
          onClick={() => {
            setShowShareBottomSheet(true);
          }}>
          <TMIcon name="share-outline" size={24} color={colors.ICON} />
        </Button>
      </div>

      {/* {hasCreatePostAccess && !hideRepostOption ? ( */}
      <div className="postRepostWrapper">
        <Button
          className="siteBtn noStyle"
          onClick={() => {
            if (!(isTier3 || isTier4)) {
              message.warning(
                'This feature is not available in the current plan. Upgrade to a higher plan to access this feature.',
                5,
              );
              return;
            }

            // Only available for Advanced and Ultimate plan users
            onRepostPressed?.();
          }}>
          <PiRepeatFill style={iconStyle} />
        </Button>
      </div>
      {/* ) : null} */}
      <ShareBottomSheet
        showModal={showShareBottomSheet}
        closeModal={() => setShowShareBottomSheet(false)}
        shareLink={handleShareLink(
          `web${ROUTES.POST_METADATA_VIEW.replace(':postId', postId)}`,
          false,
          true,
        )}
        title={`Check out the post on ${appName}`}
      />
    </div>
  );
};

export default CommunityPostStats;
