import './styles.scss';

import React, { useMemo, useState } from 'react';

import { Button, Divider, Input, message } from 'antd';
import moment, { Moment } from 'moment';
import { IoAdd } from 'react-icons/io5';
import { PiClock } from 'react-icons/pi';
import { v4 as uuidv4 } from 'uuid';

import HeaderIcon from '../../components/Header/actions/HeaderIcon';
import HeaderTextAction from '../../components/Header/actions/HeaderTextAction';
import Header from '../../components/Header/Header';
// import TextArea from '../../components/Input/TextArea';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import { createCommunityPost } from '../../context/communityReducer';
import { DayMap } from '../../shared/constants';
import showAppError from '../../shared/error';
import { useAppDispatch, useAppNavigate } from '../../shared/hooks';
import { IChannel, IPostReqBody } from '../../types/communityTypes';
import CommunityPollOptions from './components/CommunityPollOptions';
import CommunityPollSelectDurationModel from './components/CommunityPollSelectDurationModel';
import CommunityPollSettings from './components/CommunityPollSettings';
import CommunityPostScheduleDetails from './components/CommunityPostScheduleDetails';
import CommunityPostScheduleModel from './components/CommunityPostScheduleModel';
import CreateCommunityPostHeader from './components/CreateCommunityPostHeader';

const MAX_POLLS = 5;

const CreateCommunityPoll = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  // const { creatorMangoes, subscribedMangoes, } = useAppSelector(
  //   (state) => state.user,
  // );
  // const {
  //   hostMetadata: { offeringTitle },
  // } = useAppSelector((state) => state.app);

  // const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  // const [selectedMangoes, setSelectedMangoes] = useState<IMango[]>([]);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([
    { value: '', _id: uuidv4() },
    { value: '', _id: uuidv4() },
  ]);
  const [duration, setDuration] = useState<keyof typeof DayMap>(1);
  const [isSaving, setIsSaving] = useState(false);
  const [openScheduleModel, setOpenScheduleModel] = useState(false);
  const [isResultVisibleToMembers, setIsResultVisibleToMembers] =
    useState(false);
  const [allowVoteChange, setAllowVoteChange] = useState(false);
  const [showSelectePollDurationModel, setShowSelectePollDurationModel] =
    useState(false);
  const [selectedChannelList, setSelectedChannelList] = useState<IChannel[]>(
    [],
  );
  const [customEndDateTime, setCustomEndDateTime] = useState<Moment | null>(
    null,
  );
  const [scheduleDateTime, setScheduleDateTime] = useState<string | null>(null);

  const submit = async () => {
    try {
      setIsSaving(true);
      const currentTime = scheduleDateTime
        ? moment(scheduleDateTime)
        : moment();
      const endTime = customEndDateTime
        ? customEndDateTime.toISOString()
        : moment().add('days', duration).toISOString();

      const reqBody: IPostReqBody = {
        channels: selectedChannelList?.map((each) => each?._id),
        caption: question,
        pollOptions: options.map((eachOption, index) => ({
          optionContent: eachOption?.value,
          optionNumber: index + 1,
        })),
        pollSettings: {
          isResultVisibleToMembers,
          allowVoteChange,
          startDate: currentTime.toISOString(),
          endDate: endTime,
        },
      };
      if (scheduleDateTime) {
        reqBody.scheduleAt = scheduleDateTime;
      }
      dispatch(
        createCommunityPost(reqBody, selectedChannelList, question, [], () => {
          setIsSaving(false);
          navigate.goBack();
        }),
      );
    } catch (err) {
      showAppError(err);
    } finally {
      setIsSaving(false);
    }
  };

  const submitPost = async () => {
    if (question?.trim().length === 0) {
      message.error('Please enter the question');
      return;
    }
    if (options.length < 2) {
      message.error('Please enter atleast 2 options');
      return;
    }
    if (options.length > MAX_POLLS) {
      message.error(`Please enter maximum ${MAX_POLLS} options`);
      return;
    }
    if (duration === 'custom' && !customEndDateTime) {
      message.error(`Custom Endtime should not be empty`);
      return;
    }
    if (
      scheduleDateTime &&
      duration === 'custom' &&
      moment(scheduleDateTime) > moment(customEndDateTime)
    ) {
      message.error(`Schedule DateTime should not be exceded Custom Endtime`);
      return;
    }
    if (selectedChannelList.length === 0) {
      message.error(`Please select channel to publish`);
      return;
    }
    setIsConfirmDialogVisible(true);
  };

  const isDisabled = useMemo(
    () =>
      selectedChannelList.length === 0 ||
      question?.trim().length === 0 ||
      options.some((option) => option.value?.trim().length === 0),
    [options, question, selectedChannelList.length],
  );

  // const mangoesToDisplay = useMemo(() => {
  //   if (
  //     ['creator_completed', 'dual', 'creator_restricted'].includes(
  //       userDetails.type || '',
  //     )
  //   ) {
  //     return creatorMangoes;
  //   } else if (
  //     subscribedMangoes.length > 0 &&
  //     communityEnabledMangoes &&
  //     communityEnabledMangoes.length > 0 &&
  //     ['fan_completed'].includes(userDetails.type || '')
  //   ) {
  //     const subscribedMangoesWithCommunityEnabled = subscribedMangoes.filter(
  //       (mango) => communityEnabledMangoes.includes(mango._id),
  //     );
  //     return subscribedMangoesWithCommunityEnabled;
  //   }
  //   return [];
  // }, [
  //   creatorMangoes,
  //   userDetails.type,
  //   subscribedMangoes,
  //   communityEnabledMangoes,
  // ]);

  // useEffect(() => {
  //   if (
  //     selectedMangoes.length === 0 &&
  //     mangoesToDisplay &&
  //     mangoesToDisplay.length === 1
  //   ) {
  //     setSelectedMangoes([mangoesToDisplay[0]]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mangoesToDisplay]);

  // const onOptionChange = (index: number, value: string) => {
  //   const newOptions = [...options];
  //   newOptions[index] = value;
  //   setOptions(newOptions);
  // };

  const onAddOption = () => {
    if (options.length >= MAX_POLLS) {
      message.error(`You can only add up to ${MAX_POLLS} options`);
      return;
    }
    setOptions([...options, { value: '', _id: uuidv4() }]);
  };

  // const onOptionDelete = (index: number) => {
  //   const newOptions = [...options];
  //   newOptions.splice(index, 1);
  //   setOptions(newOptions);
  // };

  const handleCloseSheduleModel = () => {
    setOpenScheduleModel(false);
  };

  const handleClickOnSchedule = () => {
    setOpenScheduleModel(true);
  };

  const handleCloseDurationSelectModel = () => {
    setShowSelectePollDurationModel(false);
  };

  const handleSelectDuration = (selectedDuration: number | string) => {
    if (selectedDuration !== 'custom' && customEndDateTime) {
      setCustomEndDateTime(null);
    }
    setDuration(selectedDuration);
  };

  const handleSaveShedule = (isoUTCDate: string | null) => {
    setScheduleDateTime(isoUTCDate);
    handleCloseSheduleModel();
  };

  return (
    <>
      <div className="createpost__container createpoll">
        <Header
          title="Create Poll"
          backType="cross"
          actionItems={[
            <HeaderIcon
              icon={
                <div className="header-icon-wrapper">
                  <PiClock />
                </div>
              }
              onClick={handleClickOnSchedule}
            />,
            <HeaderTextAction
              text="Post"
              disabled={isDisabled}
              onClick={submitPost}
              loading={isSaving}
              loadingText="Saving..."
              style={{ margin: '0 20px' }}
            />,
          ]}
        />
        {scheduleDateTime ? (
          <CommunityPostScheduleDetails
            scheduleDateTime={scheduleDateTime}
            handleSaveShedule={handleSaveShedule}
            handleClickOnSchedule={handleClickOnSchedule}
          />
        ) : null}
        <div className="scroll__container">
          <CreateCommunityPostHeader
            selectedChannelList={selectedChannelList}
            setSelectedChannelList={setSelectedChannelList}
          />
          <div className="createpoll__question">
            <h3>Ask question</h3>
            <Input.TextArea
              maxLength={140}
              placeholder="Type your question here"
              value={question}
              onChange={(e) => setQuestion(e?.target?.value)}
            />
          </div>

          <div className="createpoll_options">
            <h3>Options</h3>
            <CommunityPollOptions options={options} setOptions={setOptions} />
          </div>
          <Button className="createpoll_add-button" onClick={onAddOption}>
            <IoAdd size={16} />
            Add Option
          </Button>
          <Divider />
          {/* <Select<SINGLE>
            label="Poll Duration"
            selected={duration.toString()}
            onChange={(value) => {
              setDuration(parseInt(value));
            }}
            options={Object.keys(DayMap)}
            getLabel={(value) => DayMap[parseInt(value, 10)]}
          /> */}
          <CommunityPollSettings
            isResultVisibleToMembers={isResultVisibleToMembers}
            setIsResultVisibleToMembers={setIsResultVisibleToMembers}
            allowVoteChange={allowVoteChange}
            setAllowVoteChange={setAllowVoteChange}
            duration={duration}
            setShowSelectePollDurationModel={setShowSelectePollDurationModel}
            setCustomEndDateTime={setCustomEndDateTime}
          />
        </div>
      </div>
      <CommunityPostScheduleModel
        show={openScheduleModel}
        closeModal={handleCloseSheduleModel}
        handleSave={handleSaveShedule}
        scheduleDateTime={scheduleDateTime}
      />
      <CommunityPollSelectDurationModel
        show={showSelectePollDurationModel}
        closeModel={handleCloseDurationSelectModel}
        saveChanges={handleSelectDuration}
        selectedValue={duration.toString()}
      />
      <ConfirmationModal
        open={isConfirmDialogVisible}
        message={`Your poll will last till ${DayMap[duration]}. Do you want to continue?`}
        title="Create"
        okayButtonText="Yes"
        cancelButtonText="Cancel"
        handleOk={() => {
          submit();
          setIsConfirmDialogVisible(false);
        }}
        handleCancel={() => {
          setIsConfirmDialogVisible(false);
        }}
      />
    </>
  );
};

export default CreateCommunityPoll;
