import './styles.scss';

import React, { memo } from 'react';

import EmojiPicker from 'emoji-picker-react';
import { MouseDownEvent } from 'emoji-picker-react/dist/config/config';
import { BottomSheet } from 'react-spring-bottom-sheet';

type Props = {
  show: boolean;
  closeModal: () => void;
  onSelectEmoji: (emoji: string) => void;
};

const EmojiPickerModel: React.FC<Props> = ({
  show,
  closeModal,
  onSelectEmoji,
}) => {
  const handleOnEmojiClick: MouseDownEvent = (event) => {
    onSelectEmoji(event?.emoji);
  };

  return (
    <BottomSheet
      open={show}
      onDismiss={() => closeModal()}
      className="select__bottomSheet">
      <div className="emoji_container">
        <EmojiPicker
          className="emoji_container_wrapper"
          onEmojiClick={handleOnEmojiClick}
          autoFocusSearch={false}
          lazyLoadEmojis
        />
      </div>
    </BottomSheet>
  );
};

export default memo(EmojiPickerModel);
