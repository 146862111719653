import React, { memo, useEffect } from 'react';

import { Skeleton } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import {
  getNewUsers,
  newMessageUserInitiateState,
  updateNewMessageUserState,
} from '../../../context/communityMessageReducer';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { IMessageRoomParticipant } from '../../../types/communityMessagesTypes';
import CommunityUserCard from './CommunityUserCard';
import ListTitle from './ListTitle';

type Props = {
  searchQuery: string;
};

const CommunityMembers: React.FC<Props> = ({ searchQuery }) => {
  const dispatch = useAppDispatch();

  const { newMessageUserState } = useAppSelector(
    (state) => state?.communityMessage,
  );
  const fetchUserList = async () => {
    if (newMessageUserState?.hasMore && !newMessageUserState?.loading) {
      dispatch(
        getNewUsers(
          {
            page: newMessageUserState?.page,
            users: newMessageUserState?.users,
          },
          searchQuery,
        ),
      );
    }
  };

  const displayUserCardList = (list: IMessageRoomParticipant[]) => {
    return (
      <>
        {list.map((eachData) => (
          <CommunityUserCard
            user={{
              _id: eachData?._id,
              name: eachData?.name,
              profilePicUrl: eachData?.profilePicUrl,
              profilePicType: 'image',
            }}
            isClickable
            onClick={() => {}}
          />
        ))}
      </>
    );
  };

  useEffect(() => {
    dispatch(updateNewMessageUserState(newMessageUserInitiateState));
  }, [dispatch, searchQuery]);

  return (
    <div>
      <EasyVirtualizedScroller
        useParentScrollElement
        key={'new-message-users'}
        hasMore={newMessageUserState.hasMore}
        onLoadMore={fetchUserList}>
        <div key={'this-is-user-list'}>
          <ListTitle title={'Members'} key={'this-is-member-list'} />
          <>{displayUserCardList(newMessageUserState?.users)}</>
        </div>
      </EasyVirtualizedScroller>
      {newMessageUserState.loading || newMessageUserState?.hasMore ? (
        <>
          {Array(10)
            .fill(1)
            .map(() => (
              <Skeleton avatar paragraph={{ rows: 1 }} />
            ))}
        </>
      ) : null}
    </div>
  );
};

export default memo(CommunityMembers);
