import React, { useCallback, useState } from 'react';

import { Button, Dropdown, MenuProps, Space } from 'antd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { PiArrowBendDownRightLight, PiTrashLight } from 'react-icons/pi';

import CommunityAPI from '../../../../api/CommunityAPI';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal/ConfirmationModal';
import {
  removeComment,
  setReplyToComment,
  updateCommunitComments,
  updateReplyComment,
  userInterationAction,
} from '../../../../context/communityReducer';
import showAppError from '../../../../shared/error';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { IComment } from '../../../../types/communityTypes';
import LikeButton from '../../../Feed/Posts/PostItem/LikeButton';
import CommunityPostCommentsList from './CommunityPostCommentsList';

type Props = {
  comment: IComment;
  channelId?: string;
  postId?: string;
  parentCommentId?: string;
};

const CommentCard: React.FC<Props> = ({
  comment,
  channelId,
  postId,
  parentCommentId,
}) => {
  const dispatch = useAppDispatch();

  const { communityPostComments } = useAppSelector((state) => state?.community);

  const [viewReply, setViewReply] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const handleViewReply = () => {
    setViewReply(true);
  };

  const handleClickLike = useCallback(
    (isLiked: boolean) => {
      if (!(postId && channelId)) return;
      const updatedAttributes = {
        isLiked,
        totalLikes: isLiked ? comment?.totalLikes + 1 : comment?.totalLikes - 1,
      };
      dispatch(
        userInterationAction(
          isLiked ? 'addCommentLike' : 'removeCommentLike',
          postId,
          channelId,
          comment,
          updatedAttributes,
          (updatedComment) =>
            parentCommentId
              ? dispatch(
                  updateReplyComment({
                    parentId: parentCommentId,
                    comment: updatedComment,
                  }),
                )
              : dispatch(updateCommunitComments(updatedComment)),
          comment?._id,
        ),
      );
    },
    [channelId, comment, dispatch, parentCommentId, postId],
  );

  const handelClickOnReply = useCallback(() => {
    if (parentCommentId) {
      const parent = communityPostComments.find(
        (eachComment) => eachComment?._id === parentCommentId,
      );
      dispatch(setReplyToComment({ ...parent, parentCommentId }));
    } else {
      dispatch(setReplyToComment(comment));
    }
    const input = document.getElementById('community-post-comment-input_id');
    if (input) {
      input.focus();
    }
  }, [comment, communityPostComments, dispatch, parentCommentId]);

  const displayImg = useCallback(() => {
    if (!comment?.images?.length) return null;
    return (
      <div className="comment-card_body_media">
        {comment?.images.map((eachImg) => (
          <img src={eachImg} />
        ))}
      </div>
    );
  }, [comment?.images]);

  const onDeleteMessage = async () => {
    if (!channelId || !postId) return;
    try {
      const resp = await CommunityAPI.removeComment(
        channelId,
        postId,
        comment?._id,
      );
      if (resp.status === 204) {
        dispatch(removeComment(comment?._id, parentCommentId));
      } else {
        showAppError(resp?.data);
      }
    } catch (error) {
      showAppError(error);
    }
  };

  const handleCommentDelete = () => {
    setIsDeleteDialogVisible(true);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="danger">
          <button onClick={handleCommentDelete}>
            <div>
              <PiTrashLight />
            </div>
            Delete comment
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="comment-card" key={comment?._id}>
        <div className="comment-card_avtar">
          <img src={comment?.commentedBy?.profilePicUrl} alt="" />
        </div>
        <div className="comment-card_body">
          <div className="comment-card_body_name">
            <h4>{comment.commentedBy?.name}</h4>
            <Dropdown overlayClassName="delete-dropdown" menu={{ items }}>
              <Space>
                <BsThreeDotsVertical />
              </Space>
            </Dropdown>
          </div>
          <div className="comment-card_body_comment">
            <p>{comment?.comment}</p>
          </div>
          {displayImg()}
          <div className="comment-card_body_action">
            <div className="comment-card_body_action_like">
              <LikeButton
                isLiked={comment?.isLiked}
                like={(like) => handleClickLike(like)}
              />{' '}
              <p>{comment?.totalLikes}</p>
            </div>
            <div className="comment-card_body_action_reply">
              <Button className="siteBtn noStyle" onClick={handelClickOnReply}>
                Reply
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {viewReply ? (
          <CommunityPostCommentsList parentCommentId={comment?._id} />
        ) : null}
      </div>
      {comment?.totalReplies && !viewReply ? (
        <Button
          className="siteBtn noStyle commnet-view-more_button"
          onClick={handleViewReply}>
          <PiArrowBendDownRightLight />{' '}
          <span>View reply({comment?.totalReplies})</span>
        </Button>
      ) : null}
      <ConfirmationModal
        open={isDeleteDialogVisible}
        message={`Do you really want to delete the comment?`}
        title="Delete comment"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
        handleOk={() => {
          onDeleteMessage();
          setIsDeleteDialogVisible(false);
        }}
        handleCancel={() => {
          setIsDeleteDialogVisible(false);
        }}
      />
    </>
  );
};

export default CommentCard;
