import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Divider, Switch } from 'antd';
import { PiChatsCircleFill, PiCompassFill } from 'react-icons/pi';

import { SocketAPI } from '../../../api/SocketAPI';
import Header from '../../../components/Header/Header';
import { setActiveUserInSelectedRoom } from '../../../context/communityMessageReducer';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../shared/hooks';
import { SOCKET_ACTION_EVENTS } from '../../../types/socketTypes';
import CommunityChannelMemberList from './CommunityChannelMemberList';

type Props = {
  isChatRoomInfo: boolean;
};

const CommunityChannelInfo: React.FC<Props> = ({ isChatRoomInfo }) => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const { selectedChannelData } = useAppSelector((state) => state.community);
  const { chatRoomDetails, currentChannelActiveUser } = useAppSelector(
    (state) => state?.communityMessage,
  );

  const [isRemoving, setIsRemoveing] = useState(false);

  const infoPageDetails = useMemo(() => {
    if (isChatRoomInfo) {
      return chatRoomDetails?.channel;
    }
    return selectedChannelData;
  }, [chatRoomDetails, isChatRoomInfo, selectedChannelData]);

  const handleBack = () => {
    setIsRemoveing(true);
    setTimeout(() => {
      navigate.goBack();
    }, 200);
  };

  const joinRoom = useCallback(
    (room: string) => {
      SocketAPI.emit(SOCKET_ACTION_EVENTS.ROOM_JOIN, { room: room }, (resp) => {
        dispatch(
          setActiveUserInSelectedRoom([
            ...(resp.result.activeParticipants || []),
          ]),
        );
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (infoPageDetails && infoPageDetails?.chatRoom) {
      joinRoom(infoPageDetails?.chatRoom);
    }
  }, [infoPageDetails, joinRoom]);

  useEffect(() => {
    setIsRemoveing(false);
  }, []);

  return (
    <div
      className={`channel-info_container channel-info-${
        isRemoving ? 'remove' : 'show'
      }`}>
      <Header
        title={isChatRoomInfo ? 'Chat Info' : 'Channel Info'}
        handleBack={handleBack}
      />
      <div className="channel-info_container_scroll">
        <div className="channel-info_container_scroll_logo">
          {infoPageDetails?.communityProfilePictureType === 'emoji' ? (
            <div className="channel-info_container_scroll_logo_emoji">
              {infoPageDetails?.communityProfilePicture}
            </div>
          ) : (
            <img src={infoPageDetails?.communityProfilePicture} alt="logo" />
          )}
        </div>
        <p className="channel-info_container_scroll_channel-name">
          {infoPageDetails?.name}
        </p>
        <div className="channel-info_container_scroll_settings">
          <div className="channel-info_container_scroll_settings_item">
            <div className="channel-info_container_scroll_settings_item_logo">
              <PiCompassFill />
              <p className="channel-info_container_scroll_settings_item_logo_title">
                Mute feed
              </p>
            </div>
            <Switch />
          </div>
          <Divider />
          <div className="channel-info_container_scroll_settings_item">
            <div className="channel-info_container_scroll_settings_item_logo">
              <PiChatsCircleFill />
              <p className="channel-info_container_scroll_settings_item_logo_title">
                Mute messages
              </p>
            </div>
            <Switch />
          </div>
        </div>
        <CommunityChannelMemberList onlineUserList={currentChannelActiveUser} />
      </div>
    </div>
  );
};

export default memo(CommunityChannelInfo);
