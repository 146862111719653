import React, { memo } from 'react';

import moment from 'moment';

import { ICommunityMessageRoom } from '../../../types/communityMessagesTypes';

type Props = {
  roomData: ICommunityMessageRoom;
  onClick: () => void;
};

const CommunityRoomCard: React.FC<Props> = ({ roomData, onClick }) => {
  const { channel, lastMessage, unreadCount } = roomData;
  return (
    <div className="message-card" key={roomData?._id}>
      <div className="message-card_user-wrapper" onClick={() => onClick()}>
        <div
          className={`message-card_user-wrapper_avatar ${
            unreadCount ? 'message-card_user-wrapper_avatar-active' : ''
          }`}>
          {channel?.communityProfilePictureType === 'image' ? (
            <img src={channel?.communityProfilePicture} alt="" />
          ) : (
            <div className="message-card_user-wrapper_avatar_emoji">
              {channel?.communityProfilePicture}
            </div>
          )}
        </div>
        <div className="message-card_user-wrapper_name">
          <h4>{channel?.name}</h4>
          <p>{lastMessage?.message}</p>
        </div>
        <div className="message-card_user-wrapper_time">
          <p className="message-card_user-wrapper_time_number">
            {moment(lastMessage?.createdAt).format('hh:mm A')}
          </p>
          {unreadCount ? (
            <div className="message-card_user-wrapper_time_unread">
              <p className="message-card_user-wrapper_time_unread_count">
                {unreadCount}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default memo(CommunityRoomCard);
