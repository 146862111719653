/* eslint-disable @typescript-eslint/no-use-before-define */
import { message } from 'antd';
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';

import { setToken } from '../context/appReducer';
import { store } from '../context/store';
import { IStorefrontResponse } from '../screens/Storefront/types';
import { ErrorStatusMessages } from '../shared/constants';
import { REFRESH_TOKEN_KEY } from '../shared/data';
import { setLocalData } from '../shared/utils';
import {
  DRMTypes,
  IResponseType,
  ITokens,
  UploadedUris,
} from '../types/baseTypes';
import { HostMetadata } from '../types/HostMetadata';
import {
  IGetOTPRequestType,
  ISuccessfulLoginResponse,
  ISuccessfulLoginResponseV2,
  IVerifyOTPRequestType,
} from '../types/loginTypes';
import { ROUTES } from '../types/routes';
import EndpointConfig from './config';

export default abstract class API {
  private static instance: AxiosInstance;

  private static isTokenRefreshing: boolean = false;

  private static pendingRequests: ((token: string) => void)[] = [];

  private static ongoingRequests: Record<string, AbortController> = {};

  static getRequestKey = (config: InternalAxiosRequestConfig<any>) => {
    const url = config.url?.split('?')[0];
    return `${config.method}-${url}`;
  };

  static addOnGoingRequest = (key: string, abortController: AbortController) =>
    (this.ongoingRequests[key] = abortController);

  static removeOnGoingRequest = (config: InternalAxiosRequestConfig<any>) => {
    const key = this.getRequestKey(config);
    if (!this.ongoingRequests[key]) {
      return;
    }
    delete this.ongoingRequests[key];
  };

  static onRefreshed = (token: string) => {
    this.pendingRequests.map((cb) => cb(token));
    this.pendingRequests = [];
  };

  static subscribeTokenRefresh = (cb: (token: string) => void) => {
    this.pendingRequests.push(cb);
  };

  static refreshToken = async ({
    refreshToken,
    tempTokenTime,
    callback,
    count = 0,
  }: {
    refreshToken?: string;
    tempTokenTime?: number;
    callback?: (token?: string, count?: number) => void;
    count?: number;
  } = {}) => {
    this.isTokenRefreshing = true;
    this.fetchNewToken(refreshToken, tempTokenTime)
      .then((newToken) => {
        this.isTokenRefreshing = false;
        if (newToken?.data.result?.accessToken) {
          localStorage.setItem(
            REFRESH_TOKEN_KEY,
            newToken?.data.result.refreshToken || '',
          );
          setLocalData(newToken?.data.result.accessToken);
          updateToken(newToken?.data.result?.accessToken as string);
          axios.defaults.headers.common.Authorization = `Bearer ${newToken?.data.result?.accessToken}`;
          this.onRefreshed(newToken?.data.result.accessToken || '');

          if (callback) {
            setTimeout(() => {
              callback(newToken.data.result?.accessToken, count + 1);
            }, 300);
          }
        } else {
          localStorage.clear();
          this.onRefreshed('');
          updateToken(null);
        }
      })
      .catch(() => {
        updateToken(null);
        delete axios.defaults.headers.common.Authorization;
        this.onRefreshed('');
        axios.get(`${store.getState().app.mDeeplinkUrl}get-cookie`, {
          withCredentials: true,
        });
        // console.log('token refresh failed', err);
      });
  };

  static create() {
    this.instance = axios.create({
      baseURL: EndpointConfig.API_ENDPOINT,
    });
    if (window.location.hostname === 'localhost') {
      this.instance.defaults.headers.common[
        'Access-Control-Allow-Origin'
      ] = `*`;
      this.instance.defaults.headers.common[
        'Access-Control-Allow-Headers'
      ] = `Origin, X-Requested-With, Content_Type, Accept`;
    }

    this.instance.defaults.headers.common['x-timezone-offset'] =
      -new Date().getTimezoneOffset();

    // store all api calls and cancel them if same api is called when previous is still pending
    // inject a signal from abort controller to axios request config and use it to cancel the request
    this.instance.interceptors.request.use((config) => {
      const key = this.getRequestKey(config);

      if (this.ongoingRequests[key]) {
        // this.ongoingRequests[key].abort();
      }

      const abortController = new AbortController();
      const { signal } = abortController;

      this.addOnGoingRequest(key, abortController);

      config.signal = signal;

      return config;
    });

    this.instance.interceptors.response.use(
      (response) => {
        const { config } = response;
        this.removeOnGoingRequest(config);
        return response;
      },
      (error) => {
        const { config, response } = error;
        const status = response?.status;
        const originalRequest = config;

        this.removeOnGoingRequest(config);

        if (status === 429) {
          message.error(ErrorStatusMessages[429]);
          return Promise.reject(error);
        }
        if (status === 401) {
          console.log('401', originalRequest.isRetry, this.isTokenRefreshing);
          if (originalRequest.isRetry) {
            return Promise.reject(error);
          }
          if (!this.isTokenRefreshing) {
            this.refreshToken();
          }
          const retryOrigReq = new Promise((resolve) => {
            this.subscribeTokenRefresh((token) => {
              // replace the expired token and retry
              originalRequest.headers.Authorization = `Bearer ${token}`;
              originalRequest.isRetry = true;
              resolve(axios(originalRequest));
            });
          });
          return retryOrigReq;
        }

        // if /get-access-token gets error, its probably best to just log out the user
        if (error.config?.url.includes('get-access-token')) {
          updateToken(null);

          localStorage.clear();
        }
        if (status === 400) {
          console.log('error====>>>', error.config.url);
          console.log('Error>>>>>>', JSON.stringify(error.response));
          return Promise.reject(error);
        }

        console.log('unknown error', error.response);
        return Promise.reject(error);
      },
    );
  }

  static setAuthToken = (token: string | null) => {
    if (token) {
      this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete this.instance.defaults.headers.common.Authorization;
    }
  };

  static setHost = (host: string) => {
    this.instance.defaults.headers.common['x-whitelabel-host'] = host;
  };

  static setCreator = (creator: string) => {
    this.instance.defaults.headers.common['x-whitelabel-creator'] = creator;
  };

  static get = <T = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ) => this.instance.get<IResponseType<T>>(url, config);

  static post = <T = any, D = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<D>,
  ) => this.instance.post<IResponseType<T>>(url, data, config);

  static patch = <T = any, D = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<D>,
  ) => this.instance.patch<IResponseType<T>>(url, data, config);

  static delete = <T = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ) => this.instance.delete<IResponseType<T>>(url, config);

  static put = <T = any, D = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<D>,
  ) => this.instance.put<IResponseType<T>>(url, data, config);

  static fetchNewToken = async (token?: string, tempTokenTime?: number) => {
    const refreshToken = token || localStorage.getItem(REFRESH_TOKEN_KEY);
    if (refreshToken !== null) {
      const options: any = { skipAuthRefresh: true };
      let data: any = { refreshToken };
      if (tempTokenTime)
        data = {
          ...data,
          tempTokenTime,
        };
      const resp = await this.post<ITokens>('/get-access-token', data, options);
      if (
        tempTokenTime &&
        resp.status === 200 &&
        resp.data.result?.refreshToken
      ) {
        localStorage.setItem(REFRESH_TOKEN_KEY, resp.data.result.refreshToken);
      }
      return resp;
    }
    return null;
  };

  static uploadPicture = async (
    bucketAccessibility: 'public' | 'private',
    file: string | Blob,
  ) => {
    const data = new FormData();

    data.append('image', file);

    return this.post<UploadedUris>(
      `/upload-image-${bucketAccessibility}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };

  static uploadFileInAssets = async (
    bucketAccessibility: 'public' | 'private',
    file: File,
  ) => {
    const data = new FormData();

    data.append('file', file);

    return this.post<{
      files: string[];
    }>(`/upload-file-generic-${bucketAccessibility}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static fetchPallyconToken = async (contentId: string, drmType: DRMTypes) =>
    this.post<any>('/fetch-pallycon-token', {
      drmType,
      contentId,
    });

  static getVideoCipherDetails = (videoId: string) =>
    this.post(`/get-video-cipher-details`, { videoId });

  static getCurrentCountry = () => this.get('/get-country-through-ip');

  static getOTP = (
    phoneNo: string,
    mode: 'email' | 'phone',
    blockOtp: boolean = false,
    resend: boolean = false,
    supportNumber: string = '',
  ) => {
    let req: IGetOTPRequestType = {};

    if (mode === 'phone') req.resend = resend;

    if (blockOtp)
      req = {
        ...req,
        blockOtp: 'true',
      };
    if (supportNumber?.length > 0) {
      req = {
        ...req,
        supportNumber,
      };
    }

    if (mode === 'email') {
      return this.post('/v2/get-otp-email', {
        ...req,
        email: phoneNo.trim().toLowerCase(),
      });
      // return this.post('/get-otp-email', {
      //   ...req,
      //   email: phoneNo.trim().toLowerCase(),
      // });
    }
    return this.post<any>('/get-otp', {
      ...req,
      phone: phoneNo.trim().toLowerCase(),
    });
  };

  static verifyOTP = ({
    deviceId,
    mode,
    otp,
    phone,
    logoutAll,
    refernEarnId,
  }: {
    phone: string;
    mode: 'phone' | 'email';
    otp: string;
    deviceId: string;
    logoutAll: boolean;
    refernEarnId?: string;
  }) => {
    let req: IVerifyOTPRequestType;
    if (mode === 'phone') {
      req = { phone: phone.trim().toLowerCase(), otp, logoutAll };
    } else {
      req = { email: phone.trim().toLowerCase(), otp, logoutAll };
    }
    if (deviceId?.length > 0) {
      req.deviceId = deviceId;
    }

    if (refernEarnId) {
      req.refernEarnId = refernEarnId;
    }

    return this.post<ISuccessfulLoginResponseV2>('/verify-otp', req);
  };

  static verifySignupOTP = ({
    otp,
    phone,
    email,
    name,
    refernEarnId,
  }: {
    phone: string;
    email: string;
    name: string;
    otp: string;
    refernEarnId?: string;
  }) => {
    const req = {
      phone: phone.trim().toLowerCase(),
      email: email.trim().toLowerCase(),
      name,
      otp,
      refernEarnId,
    };
    return this.post<ISuccessfulLoginResponse>('/signup-verify-otp', req);
  };

  static getNotifications = (getNotificationsParams: {
    page: number;
    perpage: number;
  }) =>
    this.get('/get-all-notifications', {
      params: getNotificationsParams,
    });

  static markNotificationAsRead = (id: string) =>
    this.post(`/mark-notificationasread`, { notification: id });

  static storeLastNotificationOpenTime = (timestamp: Date) => {
    return this.post(`/store-last-notification-open-time`, { timestamp });
  };

  static getHostDetails = async () =>
    this.get<HostMetadata>('/get-host-details');

  static getStorefrontDetails = ({
    storefrontId,
    creatorId,
    currentCountry,
  }: {
    storefrontId?: string;
    creatorId?: string;
    currentCountry?: string | null;
  }) => {
    let slug = [];
    if (storefrontId) {
      slug.push(`storefrontId=${storefrontId}`);
    }
    if (creatorId) {
      slug.push(`creator=${creatorId}`);
    }
    if (currentCountry) {
      slug.push(`country=${currentCountry}`);
    }
    return this.get<IStorefrontResponse>(`/v2/storefront?${slug.join('&')}`);
  };
}

const updateToken = (token: string | null) => {
  if (token) {
    store.dispatch(setToken(token));
  } else {
    // localStorage.setItem(
    //   'afterLoginRedirectTo',
    //   window.location.pathname.replace('/web/', '/') +
    //     (window.location.search ? `?${window.location.search}` : ''),
    // );
    window.location.href = `/web${ROUTES.LOGIN}`;
  }
};

API.create();
