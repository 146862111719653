import React, { useMemo } from 'react';

import { Typography } from 'antd';
import { PiPushPinFill } from 'react-icons/pi';

import { useAppNavigate, useAppSelector } from '../../../../shared/hooks';
// import { useTheme } from '../../../../context/ThemeProvider';
import {
  IChannelPostDetails,
  ISelFile,
} from '../../../../types/communityTypes';
import { ROUTES } from '../../../../types/routes';
import CreatePostMediaRender from '../../../CreateCommunityPost/components/CreatePostMediaRender';
import CommunityPostContent from './CommunityPostContent';
import CommunityPostHeader from './CommunityPostHeader';
import CommunityPostPoll from './CommunityPostPoll';
import CommunityPostStats from './CommunityPostStats';

type Props = {
  communityPostDetails: IChannelPostDetails;
  isRepost?: boolean;
};

const CommunityPostItem: React.FC<Props> = ({
  communityPostDetails,
  isRepost = false,
}) => {
  const { selectedChannelData } = useAppSelector((state) => state.community);
  const channelId = useMemo(() => {
    return selectedChannelData?._id;
  }, [selectedChannelData?._id]);
  const navigate = useAppNavigate();
  const { post, ...postDetails } = communityPostDetails;

  return (
    <div
      data-post-id={postDetails._id} // Add this for IntersectionObserver
      data-post-creator={post?.postedBy?._id} // Add this for IntersectionObserver
      className="community-post-item_wrapper community-feed-post community-post-item"
      style={{ paddingBottom: 3 }}>
      {postDetails.isPinned && !isRepost ? (
        <div className="community-post-item_wrapper_pinned">
          <PiPushPinFill color="#000" size={15} />
          <Typography.Text>Pinned Post</Typography.Text>
        </div>
      ) : null}
      <CommunityPostHeader
        communityPostDetails={communityPostDetails}
        isRepost={isRepost}
      />
      {post.caption ? (
        <CommunityPostContent
          caption={post.caption}
          maxLines={post.mediaContent?.length ? 2 : 5}
          onCaptionPressed={() => {}}
        />
      ) : null}
      {post?.mediaContent?.length ? (
        <CreatePostMediaRender
          files={post?.mediaContent.map(
            (eachData) =>
              ({
                type: eachData?.contentType,
                url: eachData?.contentUrl,
              } as ISelFile),
          )}
          selectedLayout={post?.layoutType}
        />
      ) : null}
      {post?.postRef ? (
        <div className="creatorPostsWrapper__repost-card">
          <CommunityPostItem
            key={post?.postRef?._id}
            // index={0}
            communityPostDetails={
              { post: post?.postRef } as IChannelPostDetails
            }
            isRepost={Boolean(post?.postRef?._id)}
          />
        </div>
      ) : null}
      {post?.poll ? (
        <CommunityPostPoll communityPostDetails={communityPostDetails} />
      ) : null}
      {isRepost ? null : (
        <CommunityPostStats
          postId={post?._id}
          communityPostDetails={communityPostDetails}
          onRepostPressed={() =>
            navigate(
              ROUTES.COMMUNITY_CREATE_POST,
              {
                postRefId:
                  communityPostDetails?.post?.postRef?._id ||
                  communityPostDetails._id,
                channelId: channelId as string,
              }, // If reposted post is getting reposted, then postRefId and postRefDetails will be the of original post which is being reposted in the first place
              {
                state: {
                  postRefDetails:
                    communityPostDetails?.post?.postRef || communityPostDetails,
                },
              },
            )
          }
        />
      )}
    </div>
  );
};

export default CommunityPostItem;
