import React, { useCallback, useEffect, useState } from 'react';

import { Checkbox } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';

import HeaderTextAction from '../../../components/Header/actions/HeaderTextAction';
import Header from '../../../components/Header/Header';
import SearchBar from '../../../components/Header/SearchBar';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import { IChannel, IChannelGroup } from '../../../types/communityTypes';

type Props = {
  show: boolean;
  closeModal: () => void;
  handleSave: (selected: IChannel[]) => void;
  selectedIdList: string[];
};

const CreatePostSelectChannelModel: React.FC<Props> = ({
  show,
  closeModal,
  handleSave,
  selectedIdList,
}) => {
  const { groupChannelList } = useAppSelector((state) => state.community);
  const { colors } = useTheme();

  const [searchQuery, setSearchQuery] = useState('');
  const [selected, setSelected] = useState<string[]>(selectedIdList);
  console.log(searchQuery);

  const resetState = () => {
    setSearchQuery('');
    setSelected([]);
  };
  const handleSaveChannelList = (selectedIds: string[]) => {
    const selectedChannelData = groupChannelList.reduce(
      (acc: IChannel[], eachGroup) => {
        const selectedChannelsData = eachGroup.channelArr.filter(
          (eachChannel) => selectedIds.includes(eachChannel?._id),
        );
        return [...acc, ...selectedChannelsData];
      },
      [],
    );
    resetState();
    handleSave(selectedChannelData);
  };

  const handleSelectCheckbox = (checked: boolean, channelDataId: string) => {
    if (checked) {
      setSelected((prev) => [...prev, channelDataId]);
    } else {
      setSelected((prev) => prev.filter((each) => each !== channelDataId));
    }
  };

  const displayChannel = useCallback(
    (channelData: IChannel) => {
      return (
        <div
          key={channelData?._id}
          onClick={() => {
            handleSelectCheckbox(
              !selected?.includes(channelData?._id),
              channelData?._id,
            );
          }}
          className={`create_post_select-channel_container_modal-wrapper_group-wrapper_channel-wrapper ${
            selected.includes(channelData?._id)
              ? 'create_post_select-channel_container_modal-wrapper_group-wrapper_select-channel-wrapper'
              : ''
          }`}>
          <div
            className={`create_post_select-channel_container_modal-wrapper_group-wrapper_channel-wrapper_name-wrapper ${
              selected.includes(channelData?._id)
                ? 'create_post_select-channel_container_modal-wrapper_group-wrapper_select-channel-wrapper_name-wrapper'
                : ''
            }`}>
            {channelData?.communityProfilePictureType === 'emoji' ? (
              <div
                className={`create_post_select-channel_container_modal-wrapper_group-wrapper_channel-wrapper_name-wrapper_svg-wrapper ${
                  selected.includes(channelData?._id)
                    ? 'create_post_select-channel_container_modal-wrapper_group-wrapper_select-channel-wrapper_name-wrapper_svg-wrapper'
                    : ''
                }`}>
                {channelData?.communityProfilePicture}
              </div>
            ) : (
              <div
                className={`create_post_select-channel_container_modal-wrapper_group-wrapper_channel-wrapper_name-wrapper_img-wrapper ${
                  selected.includes(channelData?._id)
                    ? 'create_post_select-channel_container_modal-wrapper_group-wrapper_select-channel-wrapper_name-wrapper_img-wrapper'
                    : ''
                }`}>
                <img src={channelData?.communityProfilePicture} alt="logo" />
              </div>
            )}
            {channelData?.name}
          </div>
          <Checkbox
            checked={selected?.includes(channelData?._id)}
            onChange={(event) =>
              handleSelectCheckbox(event?.target?.checked, channelData?._id)
            }
          />
        </div>
      );
    },
    [selected],
  );

  const displayGroup = useCallback(
    (groupData: IChannelGroup) => {
      return (
        <div
          key={groupData?._id}
          className="create_post_select-channel_container_modal-wrapper_group-wrapper">
          <p className="create_post_select-channel_container_modal-wrapper_group-wrapper_name">
            {groupData?.name}
          </p>
          {(groupData?.channelArr || []).map((each) => displayChannel(each))}
        </div>
      );
    },
    [displayChannel],
  );

  const displayGroupList = useCallback(() => {
    return groupChannelList.map((eachGroup) => displayGroup(eachGroup));
  }, [displayGroup, groupChannelList]);

  useEffect(() => {
    if (show) {
      setSelected(selectedIdList);
    }
  }, [selectedIdList, show]);

  return (
    <BottomSheet
      open={show}
      onDismiss={() => {
        closeModal();
        resetState();
      }}
      className="create_post_select-channel"
      header={
        <Header
          title={`Select Channel`}
          canGoBack={false}
          noBorder
          actionItems={[
            <HeaderTextAction
              text="Done"
              onClick={() => handleSaveChannelList(selected)}
            />,
          ]}
        />
      }>
      <div className="create_post_select-channel_container">
        <SearchBar
          onDebounce={(value) => {
            if (setSearchQuery) {
              setSearchQuery(value);
            }
          }}
          placeholder="Search"
          style={{
            width: '100%',
            borderColor: colors.BORDER,
            padding: 0,
          }}
        />

        <div className="create_post_select-channel_container_modal-wrapper">
          {displayGroupList()}
        </div>
      </div>
    </BottomSheet>
  );
};

export default CreatePostSelectChannelModel;
