import React from 'react';

import { Input } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { PiDotsSixVerticalBold, PiTrashFill } from 'react-icons/pi';

import { reorderItemInList } from '../../../shared/utils';
import { IPollOption } from '../../../types/communityTypes';

type Props = {
  options: IPollOption[];
  setOptions: React.Dispatch<
    React.SetStateAction<
      {
        value: string;
        _id: string;
      }[]
    >
  >;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties,
) => ({
  userSelect: 'none',
  borderRadius: 8,
  backgroundColor: isDragging ? '#f0f2f5' : 'transparent',
  ...draggableStyle,
});

const CommunityPollOptions: React.FC<Props> = ({ options, setOptions }) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorderItemInList(
      options,
      result.source.index,
      result.destination.index,
    );
    setOptions(items);
  };

  const handleUpdateOptionValue = (value: string, index: number) => {
    setOptions((prev) => {
      const tempOptions = [...prev];
      const oldData = tempOptions[index];
      oldData.value = value;
      tempOptions[index] = oldData;
      return tempOptions;
    });
  };

  const removeOption = (index: number) => {
    const newOptionList = [...options];
    newOptionList.splice(index, 1);
    setOptions(newOptionList);
  };

  return (
    <DragDropContext
      onDragEnd={(result: any) => {
        onDragEnd(result);
      }}>
      <Droppable droppableId="droppable">
        {(provided: any) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="createpoll_options_droppable-wrapper"
            style={{
              padding: 0,
            }}>
            {(options || []).map((eachOption, index) => (
              <Draggable
                key={eachOption?._id}
                draggableId={`${eachOption?._id}`}
                index={index}>
                {(_provided: any, _snapshot: any) => (
                  <div
                    ref={_provided.innerRef}
                    {..._provided.draggableProps}
                    {..._provided.dragHandleProps}
                    style={{
                      ...getItemStyle(
                        _snapshot.isDragging,
                        _provided.draggableProps.style,
                      ),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: 8,
                    }}
                    className="createpoll_options_droppable-wrapper_draggable-wrapper">
                    <div className="createpoll_options_droppable-wrapper_draggable-wrapper_option-wrapper">
                      <div className="createpoll_options_droppable-wrapper_draggable-wrapper_option-wrapper_input-wrapper">
                        <Input
                          placeholder={`Enter Option ${index + 1}`}
                          value={eachOption?.value}
                          onChange={(e) =>
                            handleUpdateOptionValue(e?.target?.value, index)
                          }
                        />
                        <div className="createpoll_options_droppable-wrapper_draggable-wrapper_option-wrapper_input-wrapper_drag-icon">
                          <PiDotsSixVerticalBold />
                        </div>
                      </div>
                      <div className="createpoll_options_droppable-wrapper_draggable-wrapper_option-wrapper_remove-wrapper">
                        {options?.length > 2 ? (
                          <button
                            className="createpoll_options_droppable-wrapper_draggable-wrapper_option-wrapper_remove-wrapper_button"
                            onClick={() => removeOption(index)}>
                            <PiTrashFill />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CommunityPollOptions;
