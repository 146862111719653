import '../styles.scss';

import React, { memo, useCallback } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { PiDotsSixVerticalBold, PiPlusCircleFill } from 'react-icons/pi';
import { BottomSheet } from 'react-spring-bottom-sheet';

import HeaderTextAction from '../../../components/Header/actions/HeaderTextAction';
import Header from '../../../components/Header/Header';
import { ISelFile } from '../../../types/communityTypes';
import CreatePostMediaRender from './CreatePostMediaRender';

type Props = {
  show: boolean;
  closeModal: () => void;
  saveChanges: () => void;
  selFile: ISelFile[];
  handleRemoveFile: (index: number) => void;
  handleDragEnd: (result: any) => void;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties,
) => ({
  userSelect: 'none',
  borderRadius: 8,
  backgroundColor: isDragging ? '#f0f2f5' : 'transparent',
  ...draggableStyle,
});

const PostMediaEditModel: React.FC<Props> = ({
  show,
  closeModal,
  saveChanges,
  selFile,
  handleRemoveFile,
  handleDragEnd,
}) => {
  const displayMedia = useCallback(() => {
    return (
      <DragDropContext
        onDragEnd={(result: any) => {
          handleDragEnd(result);
        }}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="createpoll_options_droppable-wrapper"
              style={{
                padding: 0,
              }}>
              {(selFile || []).map((eachFile, index) => (
                <Draggable
                  key={`${eachFile?.name}_${index}`}
                  draggableId={`${eachFile?.name}_${index}`}
                  index={index}>
                  {(_provided: any, _snapshot: any) => (
                    <div
                      ref={_provided.innerRef}
                      {..._provided.draggableProps}
                      {..._provided.dragHandleProps}
                      style={{
                        ...getItemStyle(
                          _snapshot.isDragging,
                          _provided.draggableProps.style,
                        ),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 8,
                      }}
                      className="createpoll_options_droppable-wrapper_draggable-wrapper">
                      <div className="post-media-edit_container_item-wrapper">
                        <CreatePostMediaRender
                          selectedLayout="carousel"
                          files={[eachFile]}
                          renderDummy
                        />
                        <button
                          className="post-media-edit_container_item-wrapper_remove-btn"
                          onClick={() => handleRemoveFile(index)}>
                          <PiPlusCircleFill />
                        </button>
                        <div className="post-media-edit_container_item-wrapper_drag-icon">
                          <PiDotsSixVerticalBold />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }, [handleDragEnd, handleRemoveFile, selFile]);
  return (
    <BottomSheet
      open={show}
      onDismiss={() => {
        closeModal();
      }}
      className="create_post_select-channel"
      header={
        <Header
          title={`Select Channel`}
          canGoBack={false}
          noBorder
          actionItems={[
            <HeaderTextAction text="Done" onClick={() => saveChanges()} />,
          ]}
        />
      }>
      <div className="post-media-edit_container">{displayMedia()}</div>
    </BottomSheet>
  );
};

export default memo(PostMediaEditModel);
