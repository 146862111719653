import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Input, InputRef, message } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import { MouseDownEvent } from 'emoji-picker-react/dist/config/config';
import { FaRegKeyboard } from 'react-icons/fa6';
import { IoCloseCircle } from 'react-icons/io5';
import { PiCamera, PiPaperPlaneRightFill, PiSmileyLight } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import CommunityAPI from '../../../../api/CommunityAPI';
import {
  addCommunityPostComments,
  appendReplyComments,
  setReplyToComment,
} from '../../../../context/communityReducer';
import showAppError from '../../../../shared/error';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { convertImage, getFileExtension } from '../../../../shared/utils';
import { ICommentReqBody } from '../../../../types/communityTypes';
import ReplyCommentCard from './ReplyCommentCard';

type Props = {};

const CommunityPostCommentInput: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const { replyComment } = useAppSelector((state) => state?.community);

  const { channelId, postId } = useParams();
  const inputRef = useRef<InputRef | null>(null);
  const touchedRef = useRef<boolean>(false);

  const [comment, setComment] = useState('');
  const [openEmoji, setOpenEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<{ file: File; url: string } | null>(null);

  const closeModal = () => {
    setOpenEmoji(false);
  };

  const resetStates = () => {
    setComment('');
    setOpenEmoji(false);
    setFile(null);
  };

  const beforeUpload = (fileData: File) => {
    const type = getFileExtension(fileData.name)?.[1].toLowerCase();
    const isImage =
      type === 'jpg' ||
      type === 'png' ||
      type === 'gif' ||
      type === 'webp' ||
      type === 'tiff' ||
      type === 'jpeg' ||
      type === 'heic' ||
      type === 'svg';
    if (!isImage) {
      message.error('You can only put a valid image here!');
      return false;
    }
    convertImage(type, fileData, (rowFile, url) =>
      setFile({ file: rowFile, url }),
    );
  };

  const onSelect = useCallback(async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    // input.setAttribute('multiple', true as unknown as string);
    input.setAttribute(
      'accept',
      '.jpg, .jpeg, .png, .svg, .gif, .bmp, .webp, .tiff, .heic',
    );
    input.click();
    input.addEventListener('change', (e: any) => {
      let evt: React.ChangeEvent<HTMLInputElement> = e as any;
      if (!evt.target?.files) return;
      beforeUpload(e.target.files[0] as File);
    });
  }, []);

  const addedOnIcons = useMemo(
    () => (
      <div className="addon-button_wrapper">
        <button
          className="addon-button_wrapper_button"
          disabled={loading}
          onClick={() => {
            if (openEmoji) {
              inputRef.current?.focus();
            }
            setOpenEmoji((prev) => !prev);
          }}>
          {openEmoji ? <FaRegKeyboard /> : <PiSmileyLight />}
        </button>

        <button
          disabled={loading}
          onClick={() => {
            onSelect();
          }}
          style={{ marginRight: 2 }}
          className="addon-button_wrapper_button">
          <PiCamera />
        </button>
      </div>
    ),
    [loading, onSelect, openEmoji],
  );

  const onSelectEmoji = useCallback((emoji: string) => {
    if (!inputRef.current) return;
    const inputObj = inputRef.current;
    const input = inputObj?.input;
    if (!input) return;

    let selectionStart = input?.selectionStart;
    let selectionEnd = input?.selectionEnd;

    if (selectionStart === null || selectionEnd === null) return;

    // if (
    //   selectionStart === 0 &&
    //   selectionEnd === 0 &&
    //   input?.textLength > 0
    // ) {
    //   selectionStart = input?.textLength;
    //   selectionEnd = input?.textLength;
    // }
    setComment(
      (prev) =>
        prev.slice(0, selectionStart || 0) +
        emoji +
        prev.slice(selectionEnd || 0),
    );
    const newCursor = selectionStart + emoji.length;
    setTimeout(() => input.setSelectionRange(newCursor, newCursor), 10);
    // input.focus();
  }, []);

  const handleOnEmojiClick: MouseDownEvent = (event) => {
    onSelectEmoji(event?.emoji);
  };

  const addComment = useCallback(async () => {
    if (!(channelId && postId)) return;
    if (!comment.trim() && !file) {
      message.warning('Write comment...');
      return;
    }
    try {
      setLoading(true);
      const commentReq: ICommentReqBody = {
        comment,
      };
      if (replyComment) {
        commentReq.repliedComment = replyComment?._id;
      }
      if (file) {
        const respImg = await CommunityAPI.uploadCommunityMedia(
          [file?.file],
          () => {},
        );
        if (respImg?.status === 201 && respImg?.data?.result?.length) {
          commentReq.images = respImg?.data?.result.map(
            (eachMedia) => eachMedia?.assetUrl,
          );
        }
      }
      const resp = await CommunityAPI.crateComment(
        channelId,
        postId,
        commentReq,
      );
      if (resp?.status === 201) {
        if (replyComment?.parentCommentId) {
          dispatch(
            appendReplyComments({
              comment: resp?.data?.result,
              parentId: replyComment?.parentCommentId,
            }),
          );
        } else {
          dispatch(addCommunityPostComments(resp?.data?.result));
        }
        resetStates();
        if (replyComment) {
          dispatch(setReplyToComment(null));
        }
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setLoading(false);
    }
  }, [channelId, comment, dispatch, file, postId, replyComment]);

  const displayUploadedImg = useCallback(() => {
    if (file?.url) {
      return (
        <div className="comment-input_container_media">
          <img
            className="comment-input_container_media_uploaded-img"
            src={file?.url}
          />
          <button
            className="siteBtn noStyle comment-input_container_media_cross-btn"
            onClick={() => setFile(null)}>
            <IoCloseCircle />
          </button>
        </div>
      );
    }
    return null;
  }, [file?.url]);

  useEffect(() => {
    if (!openEmoji && touchedRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 800);
    }
  }, [openEmoji]);

  return (
    <>
      <div className="comment-input_container">
        {replyComment ? (
          <ReplyCommentCard loading={loading} replyComment={replyComment} />
        ) : null}
        {openEmoji ? null : (
          <div className="comment-input_container_">
            <Input
              addonAfter={addedOnIcons}
              id="community-post-comment-input_id"
              className="comment-input_container__input"
              placeholder="Write a comment..."
              value={comment}
              disabled={loading}
              onChange={(e) => {
                if (!touchedRef?.current) {
                  touchedRef.current = true;
                }
                setComment(e?.target?.value);
              }}
              ref={(ref) => (inputRef.current = ref)}
            />
            <div className="comment-input_container__send-button">
              <button disabled={loading} onClick={addComment}>
                <PiPaperPlaneRightFill />
              </button>
            </div>
          </div>
        )}
        {displayUploadedImg()}
      </div>
      <BottomSheet
        open={openEmoji}
        onDismiss={() => closeModal()}
        style={{ zIndex: 0 }}>
        <>
          {openEmoji ? (
            <div className="comment-input_container">
              <div className="comment-input_container_">
                <Input
                  addonAfter={addedOnIcons}
                  className="comment-input_container__input"
                  placeholder="Write a comment..."
                  value={comment}
                  disabled={loading}
                  onChange={(e) => {
                    setComment(e?.target?.value);
                  }}
                  ref={(ref) => (inputRef.current = ref)}
                />
                <div className="comment-input_container__send-button">
                  <button disabled={loading} onClick={addComment}>
                    <PiPaperPlaneRightFill />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
        <EmojiPicker
          className="emoji_container_wrapper"
          onEmojiClick={handleOnEmojiClick}
          autoFocusSearch={false}
          lazyLoadEmojis
        />
      </BottomSheet>
    </>
  );
};

export default memo(CommunityPostCommentInput);
