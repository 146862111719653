import React, { memo } from 'react';

import { Modal } from 'antd';

import Header from '../../../components/Header/Header';
import { useAppSelector } from '../../../shared/hooks';
import CommunityChatbackgroundLayer from './CommunityChatbackgroundLayer';
import MessagesCard from './MessagesCard';

type Props = {
  showModal: boolean;
  closeModal: () => void;
};

const PinnedMessageList: React.FC<Props> = ({ showModal, closeModal }) => {
  const { chatRoomDetails } = useAppSelector(
    (state) => state?.communityMessage,
  );

  return (
    <Modal
      open={showModal}
      title={null}
      destroyOnClose
      className="pinned-chat-modal full-page-model"
      closable={false}
      footer={null}
      closeIcon={null}>
      <div className="pinned-chat-modal_container">
        <Header
          title={`Pinned Messages(${chatRoomDetails?.pinnedMessages?.length})`}
          backType="arrow"
          handleBack={closeModal}
        />
        <div className="pinned-chat-modal_container_chats">
          {chatRoomDetails?.pinnedMessages?.map((each) => (
            <MessagesCard message={each} />
          ))}
        </div>
        <CommunityChatbackgroundLayer />
      </div>
    </Modal>
  );
};

export default memo(PinnedMessageList);
