import './styles.scss';
import './Posts/styles.scss';

import { useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import FeedHeader from '../../components/Header/FeedHeader';
import {
  setIsUserProfilePage,
  setSelected,
} from '../../context/activityReducer';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import FeedContent from './FeedContent';

const Feed: React.FC = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const {
    tags: allTags,
    isTagMango,
    hostMetadata: { appName },
  } = useAppSelector((state) => state.app);
  const { creatorMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );
  const { selected, subscriptions, isUserProfilePage } = useAppSelector(
    (state) => state.activity,
  );

  const title = `${userDetails.name || 'User'} - Activity page`;
  const metaTitle = `${
    userDetails.name || 'User'
  }’s activity page on ${appName}`;
  const metaDescription = `${
    userDetails.name || 'User'
  }’s activity page on ${appName}. Check your feed posts, earnings and subscribers.`;

  const tags = useMemo(
    () =>
      Object.entries(allTags)
        .filter(([, value]) => {
          if (!selected || selected === 'all') {
            return true;
          }
          return value.creatorId === selected;
        })
        .map(([key, value]) => ({
          ...value,
          key,
        })),
    [allTags, selected],
  );

  const showWorkspace = useMemo(
    () =>
      Boolean(
        isTagMango &&
          userDetails.type &&
          userDetails.type !== 'details_added' &&
          (subscriptions || []).filter(
            (item) => item.creatorId !== userDetails.id,
          ).length > 0,
      ),
    [isTagMango, subscriptions, userDetails.id, userDetails.type],
  );

  // To hide for TagMango
  // Refresh the feed when the user navigates from the profile page in White Label Platforms
  useEffect(() => {
    if (!isTagMango && isUserProfilePage) {
      dispatch(setSelected(''));
      dispatch(setIsUserProfilePage(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isTagMango, isUserProfilePage]);

  // Refresh the feed when the user navigates from the profile page in White Label Platforms
  useEffect(() => {
    if (isUserProfilePage) {
      dispatch(setSelected(''));
      dispatch(setIsUserProfilePage(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isUserProfilePage]);

  return (
    <DynamicWrapper path={ROUTES.FEED}>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="dashboard, tagmango, influencers, influencer marketing, video shoutout, How to make money online, online skills, content, creator, Instagram creator, youtube creator,"
        />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>

      <div className="feed__container">
        <FeedHeader showWorkspace={showWorkspace} tags={tags} />

        <FeedContent />
      </div>
    </DynamicWrapper>
  );
};

export default Feed;
