import React, { useCallback, useMemo, useState } from 'react';

import { Divider, message, Modal } from 'antd';
import { BiCommentCheck, BiCommentX } from 'react-icons/bi';
import { GoLink } from 'react-icons/go';
import { IoMdHeartEmpty } from 'react-icons/io';
import { IoHeartDislikeOutline } from 'react-icons/io5';
import { PiClosedCaptioning, PiPencilSimple } from 'react-icons/pi';
import {
  TbMessageCircle,
  TbMessageCircleOff,
  TbPin,
  TbPinnedOff,
} from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import DeleteSvg from '../../../../assets/svg/Delete';
import ViewMoreSvg from '../../../../assets/svg/ViewMore';
import BottomSheetTile from '../../../../components/BottomSheetTile/BottomSheetTile';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal/ConfirmationModal';
import {
  setPDPCommunityPostData,
  updateCommunitPost,
  userInterationAction,
} from '../../../../context/communityReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { copyToClipboard, handleShareLink } from '../../../../shared/utils';
import {
  IChannelPostDetails,
  IPostSettings,
} from '../../../../types/communityTypes';
import { ROUTES } from '../../../../types/routes';
import EditCommunityPost from '../../../CreateCommunityPost/EditCommunityPost';

type Props = {
  showMenu: boolean;
  closeModel: () => void;
  communityPostDetails: IChannelPostDetails;
  onTogglePinPost: () => void;
  isPDP?: boolean;
};

const CommunityPostMenuModel: React.FC<Props> = ({
  showMenu,
  closeModel,
  communityPostDetails,
  isPDP,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { selectedChannelData } = useAppSelector((state) => state.community);

  const { channelId: paramsChannelId } = useParams();
  const { colors } = useTheme();

  const { post, ...postDetails } = communityPostDetails;
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const channelId = useMemo(() => {
    return selectedChannelData?._id;
  }, [selectedChannelData?._id]);

  const copyLinktoClipboard = async () => {
    handleShareLink(
      `web${ROUTES.POST_METADATA_VIEW.replace(':postId', post._id)}`,
    );
  };

  const handleRemovePost = useCallback(() => {
    const thunkAction = window.location?.pathname.includes('/channel/')
      ? updateCommunitPost
      : setPDPCommunityPostData;
    dispatch(
      userInterationAction(
        'removePost',
        communityPostDetails?._id,
        (channelId || paramsChannelId) as string,
        communityPostDetails,
        {},
        () => {
          dispatch(thunkAction({}));
        },
        {},
        () => {
          message.success('Deleted post successfully');
          if (!window.location?.pathname.includes('/channel/')) {
            navigate.goBack();
          }
          setIsDialogVisible(false);
          closeModel();
        },
        window.location?.pathname.includes('/channel/'),
      ),
    );
  }, [
    channelId,
    closeModel,
    communityPostDetails,
    dispatch,
    navigate,
    paramsChannelId,
  ]);

  const handleToggledPinned = useCallback(
    (isPinned: boolean, successMessage: string) => {
      const thunkAction = window.location?.pathname.includes('/channel/')
        ? updateCommunitPost
        : setPDPCommunityPostData;

      dispatch(
        userInterationAction(
          isPinned ? 'addPinned' : 'removePinned',
          communityPostDetails?._id,
          (channelId || paramsChannelId) as string,
          communityPostDetails,
          {},
          (updatedData) => {
            dispatch(thunkAction({ ...updatedData, isPinned }));
          },
          { isPinned },
          () => {
            message.success(successMessage);
          },
          window.location?.pathname.includes('/channel/'),
        ),
      );
      closeModel();
    },
    [channelId, closeModel, communityPostDetails, dispatch, paramsChannelId],
  );

  const handleUpdatePostSettings = useCallback(
    (updatedPostSettings: IPostSettings, successFunction: () => void) => {
      const updatedAttributes = {
        post: {
          ...post,
          postSettings: { ...post?.postSettings, ...updatedPostSettings },
        },
      };
      const thunkAction = window.location?.pathname.includes('/channel/')
        ? updateCommunitPost
        : setPDPCommunityPostData;
      dispatch(
        userInterationAction(
          'updatePostSettings',
          communityPostDetails?._id,
          (channelId || paramsChannelId) as string,
          communityPostDetails,
          updatedAttributes,
          (updatedData) => {
            dispatch(thunkAction(updatedData));
          },
          { postSettings: { ...post?.postSettings, ...updatedPostSettings } },
          successFunction,
        ),
      );
      closeModel();
    },
    [
      channelId,
      closeModel,
      communityPostDetails,
      dispatch,
      paramsChannelId,
      post,
    ],
  );

  return (
    <>
      <BottomSheet open={showMenu} onDismiss={closeModel}>
        {isPDP ? null : (
          <BottomSheetTile
            icon={<ViewMoreSvg width={20} height={20} />}
            title="View more"
            onClick={() => {
              navigate(ROUTES.COMMUNITY_POST_DETAILS, {
                postId: communityPostDetails?._id,
                channelId: channelId as string,
              });
            }}
          />
        )}
        <BottomSheetTile
          icon={<GoLink color={colors.ICON} size={20} />}
          title="Copy link"
          onClick={() => {
            copyLinktoClipboard();
            closeModel();
          }}
        />
        {post.caption ? (
          <BottomSheetTile
            icon={<PiClosedCaptioning color={colors.ICON} size={20} />}
            title="Copy caption"
            onClick={() => {
              copyToClipboard(post.caption);
              closeModel();
            }}
          />
        ) : null}

        {/* {isMyPost || (!isTagMango && hasFeedWriteAccess) ? ( */}
        <>
          {!post.poll ? (
            <BottomSheetTile
              icon={<PiPencilSimple color={colors.ICON} size={20} />}
              title="Edit post"
              onClick={() => {
                setShowEditPostModal(true);
                closeModel();
              }}
            />
          ) : null}

          <Divider />
          {/* {!isTagMango && hasFeedWriteAccess ? ( */}
          <BottomSheetTile
            icon={
              postDetails.isPinned ? (
                <TbPinnedOff color={colors.ICON} size={20} />
              ) : (
                <TbPin color={colors.ICON} size={20} />
              )
            }
            title={`${postDetails.isPinned ? 'Unpin' : 'Pin'} post`}
            onClick={() => {
              handleToggledPinned(
                !postDetails.isPinned,
                `${postDetails.isPinned ? 'Unpin' : 'Pin'} post successfully`,
              );
            }}
          />
          {/* ) : null} */}

          <BottomSheetTile
            icon={
              post?.postSettings?.hideComment ? (
                <TbMessageCircleOff color={colors.ICON} size={20} />
              ) : (
                <TbMessageCircle color={colors.ICON} size={20} />
              )
            }
            title={
              post?.postSettings?.hideComment
                ? 'Unhide comments'
                : 'Hide comments'
            }
            onClick={() => {
              handleUpdatePostSettings(
                {
                  ...post?.postSettings,
                  hideComment: !post?.postSettings?.hideComment,
                },
                () => {
                  message.success(
                    `${
                      post?.postSettings?.hideComment
                        ? 'Unhide comments'
                        : 'Hide comments'
                    } successfully`,
                  );
                },
              );
            }}
            // onClick={() => {
            //   onEnableOrDisableComments(false);
            // }}
          />

          {/* {(isMyPost || (isCreatorPost && hasFeedWriteAccess)) && !isTagMango ? ( */}

          <BottomSheetTile
            icon={
              post?.postSettings?.disableComment ? (
                <BiCommentCheck color={colors.ICON} size={20} />
              ) : (
                <BiCommentX color={colors.ICON} size={20} />
              )
            }
            title={
              post?.postSettings?.disableComment
                ? 'Enable comments'
                : 'Disable comments'
            }
            onClick={() => {
              handleUpdatePostSettings(
                {
                  ...post?.postSettings,
                  disableComment: !post?.postSettings?.disableComment,
                },
                () => {
                  message.success(
                    `${
                      post?.postSettings?.disableComment
                        ? 'Enable comments'
                        : 'Disable comments'
                    } successfully`,
                  );
                },
              );
            }}
            // onClick={() => {
            //   onEnableOrDisableComments(false);
            // }}
          />

          <BottomSheetTile
            icon={
              post?.postSettings?.hideLikes ? (
                <IoHeartDislikeOutline color={colors.ICON} size={20} />
              ) : (
                <IoMdHeartEmpty color={colors.ICON} size={20} />
              )
            }
            title={
              post?.postSettings?.hideLikes ? 'Unhide likes' : 'Hide likes'
            }
            onClick={() => {
              handleUpdatePostSettings(
                {
                  ...post?.postSettings,
                  hideLikes: !post?.postSettings?.hideLikes,
                },
                () => {
                  message.success(
                    `${
                      post?.postSettings?.hideLikes
                        ? 'Unhide likes'
                        : 'Hide likes'
                    } successfully`,
                  );
                },
              );
            }}
            // onClick={() => {
            //   onEnableOrDisableComments(false);
            // }}
          />

          <hr className="dropdownDivider" />
          <BottomSheetTile
            icon={<DeleteSvg width={20} height={20} />}
            warning
            // alignIcon="flex-start"
            title="Remove post"
            onClick={() => {
              // handleRemovePost();
              closeModel();
              setIsDialogVisible(true);
            }}
            // onClick={() => {
            //   setIsBottomSheetVisible(false);
            //   setIsDialogVisible(true);
            // }}
          />
        </>
      </BottomSheet>
      <Modal
        open={showEditPostModal}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose
        className="postDetailsModal">
        <EditCommunityPost
          post={post}
          handleBack={() => {
            setShowEditPostModal(false);
          }}
          postId={communityPostDetails?._id}
        />
      </Modal>
      <ConfirmationModal
        title="Remove"
        open={isDialogVisible}
        handleOk={handleRemovePost}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
        message="Do you really want to delete the post?"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
      />
    </>
  );
};

export default CommunityPostMenuModel;
