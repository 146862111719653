import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Divider } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import CommunityMessagesAPI from '../../../api/CommunityMessageAPI';
import showAppError from '../../../shared/error';
import { IMessageRoomParticipant } from '../../../types/communityMessagesTypes';
import CommunityUserCard from './CommunityUserCard';
import ListTitle from './ListTitle';

type Props = {
  onlineUserList: IMessageRoomParticipant[];
};

const NEW_USER_LIMIT = 10;

const initialState = {
  hasMore: true,
  loading: false,
  users: [],
  page: 1,
};

const CommunityChannelMemberList: React.FC<Props> = ({ onlineUserList }) => {
  const [offlineUserState, setOfflineUserState] = useState<{
    hasMore: boolean;
    loading: boolean;
    users: IMessageRoomParticipant[];
    page: number;
  }>(initialState);

  const onlineUserIds = useMemo(() => {
    return onlineUserList.map((each) => each?._id);
  }, [onlineUserList]);

  const getParticipentList = useCallback(async () => {
    if (offlineUserState?.loading || !offlineUserState?.hasMore) return;
    try {
      setOfflineUserState((prev) => ({ ...prev, loading: true }));
      const query = new URLSearchParams();
      query.append('limit', NEW_USER_LIMIT.toString());
      query.append('page', offlineUserState?.page.toString());
      const resp = await CommunityMessagesAPI.getNewMessageUser(
        query?.toString(),
      );
      if (resp?.status === 200) {
        const filteredUserList = resp?.data?.result?.filter(
          (eachUser) => !onlineUserIds.includes(eachUser?._id),
        );
        setOfflineUserState((prev) => {
          const currentUsers = [...prev.users];

          return {
            ...prev,
            users: [...currentUsers, ...filteredUserList],
            loading: false,
            hasMore: resp?.data?.result?.length === NEW_USER_LIMIT,
            page: prev?.page + 1,
          };
        });
      } else {
        showAppError(resp?.data);
      }
    } catch (error) {
      showAppError(error);
    }
  }, [
    offlineUserState?.hasMore,
    offlineUserState?.loading,
    offlineUserState?.page,
    onlineUserIds,
  ]);

  useEffect(() => {
    getParticipentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setOfflineUserState(initialState);
    };
  }, []);

  return (
    <div className="channel-info_container_scroll_user-container">
      <p>Members(234)</p>
      <div>
        <div className="channel-info_container_scroll_user-container_users">
          <ListTitle title="Online" />
          {onlineUserList?.map((eachUser) => (
            <CommunityUserCard
              key={eachUser?._id}
              user={{ ...eachUser, profilePicType: 'image' }}
              isClickable={false}
              onClick={() => {}}
              isOnline
            />
          ))}
        </div>
        <Divider />
        {offlineUserState?.users?.length ? (
          <div className="channel-info_container_scroll_user-container_users">
            <ListTitle title="Offline" />
            <EasyVirtualizedScroller
              useParentScrollElement
              hasMore={offlineUserState?.hasMore}
              onLoadMore={() => getParticipentList()}>
              <div>
                {offlineUserState?.users?.map((eachUser) => (
                  <CommunityUserCard
                    key={eachUser?._id}
                    user={{ ...eachUser, profilePicType: 'image' }}
                    isClickable={false}
                    onClick={() => {}}
                  />
                ))}
              </div>
            </EasyVirtualizedScroller>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(CommunityChannelMemberList);
