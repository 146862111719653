import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from 'antd';
import { PiArrowBendDownRightLight } from 'react-icons/pi';
import { useParams } from 'react-router-dom';

import CommunityAPI from '../../../../api/CommunityAPI';
import Loader from '../../../../components/Loader';
import {
  addReplyComments,
  appendCommunityPostComments,
} from '../../../../context/communityReducer';
import showAppError from '../../../../shared/error';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import CommentCard from './CommentCard';

type Props = {
  parentCommentId?: string;
};

const COMMENTS_LIMIT = 5;
const CommunityPostCommentsList: React.FC<Props> = ({ parentCommentId }) => {
  const dispatch = useAppDispatch();
  const { communityPostComments, replayCommentsObj } = useAppSelector(
    (state) => state?.community,
  );
  const { channelId, postId } = useParams();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  console.log(replayCommentsObj);
  const commentList = useMemo(() => {
    if (parentCommentId) {
      return replayCommentsObj?.[parentCommentId] || [];
    }
    return communityPostComments;
  }, [communityPostComments, parentCommentId, replayCommentsObj]);

  const fetchCommentsBasedOnPage = useCallback(async () => {
    if (!(channelId && postId)) return;
    try {
      setLoading(true);
      const query = new URLSearchParams();
      query.append('page', `${page}`);
      query.append('pageSize', `${COMMENTS_LIMIT}`);
      let getCommentAction = CommunityAPI.getComments;
      if (parentCommentId) {
        getCommentAction = CommunityAPI.getReplyComments;
      }
      const resp = await getCommentAction(
        channelId,
        postId,
        query?.toString(),
        parentCommentId as string,
      );
      if (resp.status === 200) {
        if (parentCommentId) {
          dispatch(
            addReplyComments({
              ...resp?.data?.result,
              parentId: parentCommentId,
            }),
          );
        } else {
          dispatch(appendCommunityPostComments(resp?.data?.result?.comments));
        }
        setTotalCount(resp?.data?.result?.totalComments);
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setLoading(false);
    }
  }, [channelId, dispatch, page, parentCommentId, postId]);

  const displayCommentList = useCallback(() => {
    return (
      <>
        {commentList.map((eachComment) => {
          return (
            <CommentCard
              comment={eachComment}
              channelId={channelId}
              postId={postId}
              parentCommentId={parentCommentId}
            />
          );
        })}
      </>
    );
  }, [channelId, commentList, parentCommentId, postId]);

  const handleViewMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const displayViewMore = useCallback(() => {
    if (loading) return null;
    if (!(totalCount > page * COMMENTS_LIMIT)) return null;
    return (
      <Button
        className="siteBtn noStyle commnet-view-more_button"
        onClick={handleViewMore}>
        <PiArrowBendDownRightLight />{' '}
        <span>View Comments({totalCount - page * COMMENTS_LIMIT})</span>
      </Button>
    );
  }, [handleViewMore, loading, page, totalCount]);

  const displayLoader = useCallback(() => {
    if (loading) {
      return (
        <div className="comment-loader_wrapper">
          <Loader size="small" style={{ marginBlock: 24 }} />
        </div>
      );
    }
  }, [loading]);

  useEffect(() => {
    fetchCommentsBasedOnPage();
  }, [fetchCommentsBasedOnPage, page]);
  return (
    <div
      className="comment-list_container"
      style={parentCommentId ? { marginLeft: 40 } : {}}>
      {displayCommentList()}
      {displayLoader()}
      {displayViewMore()}
    </div>
  );
};

export default memo(CommunityPostCommentsList);
