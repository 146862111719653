import React, { memo, useCallback, useEffect, useState } from 'react';

import { BottomSheet } from 'react-spring-bottom-sheet';

import CommunityMessagesAPI from '../../../api/CommunityMessageAPI';
import Loader from '../../../components/Loader';
import showAppError from '../../../shared/error';
import { ICommunityMessageSender } from '../../../types/communityMessagesTypes';
import CommunityUserCard from './CommunityUserCard';

type Props = {
  messageId: string | null;
  roomId: string | null;
  closeModal: () => void;
};

const InfoMessageModel: React.FC<Props> = ({
  messageId,
  roomId,
  closeModal,
}) => {
  const [reactionVsUserObj, setReactionVsObj] = useState<{
    [key: string]: ICommunityMessageSender[];
  } | null>(null);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [selectedReaction, setSelectedReaction] = useState('all');

  const displayUserList = useCallback(() => {
    if (!reactionVsUserObj) return;
    if (selectedReaction === 'all') {
      return Object.values(reactionVsUserObj)
        .flat()
        .map((each) => (
          <CommunityUserCard
            user={{ ...each, profilePicType: 'image' }}
            isClickable={false}
            onClick={() => {}}
          />
        ));
    }
    return reactionVsUserObj[selectedReaction].map((each) => (
      <CommunityUserCard
        user={{ ...each, profilePicType: 'image' }}
        isClickable={false}
        onClick={() => {}}
      />
    ));
  }, [reactionVsUserObj, selectedReaction]);

  const displayTabs = useCallback(() => {
    if (!reactionVsUserObj) return;
    return (
      <>
        {
          <button
            className={`${selectedReaction === 'all' ? 'selected' : ''}`}
            onClick={() => setSelectedReaction('all')}>
            <p>All {totalUserCount}</p>
          </button>
        }
        {Object.entries(reactionVsUserObj)
          .filter(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([emoji, _list]: [string, ICommunityMessageSender[]]) =>
              emoji !== 'onlySeen',
          )
          .map(([emoji, list]: [string, ICommunityMessageSender[]]) => (
            <button
              className={`${selectedReaction === emoji ? 'selected' : ''}`}
              onClick={() => setSelectedReaction(emoji)}>
              <p>
                {emoji} {list?.length}
              </p>
            </button>
          ))}
      </>
    );
  }, [reactionVsUserObj, selectedReaction, totalUserCount]);

  const fetchSeenAndReactionUserList = useCallback(async () => {
    if (!(messageId && roomId)) return;
    try {
      const resp = await CommunityMessagesAPI.getSeenAndReactionUserList(
        roomId,
        messageId,
      );
      if (resp?.status === 200) {
        const reactionObj = resp.data?.result.reduce(
          (acc: { [key: string]: ICommunityMessageSender[] }, eachUser) => {
            setTotalUserCount(resp?.data?.result?.length);
            const reaction = (eachUser?.reaction as string) || 'onlySeen';

            const reactionUserList = acc?.[reaction];
            if (reactionUserList) {
              acc[reaction] = [...acc[reaction], eachUser];
            } else {
              acc[reaction] = [eachUser];
            }
            return acc;
          },
          {},
        );
        setReactionVsObj(reactionObj);
      } else {
        showAppError(resp?.data);
      }
    } catch (error) {
      showAppError(error);
    }
  }, [messageId, roomId]);

  useEffect(() => {
    if (messageId && roomId) {
      fetchSeenAndReactionUserList();
    }
  }, [fetchSeenAndReactionUserList, messageId, roomId]);

  if (!reactionVsUserObj && Boolean(messageId)) {
    return (
      <Loader size="small" className="input-wrapper__actions__sending-loader" />
    );
  }

  return (
    <div>
      <BottomSheet
        open={Boolean(messageId)}
        onDismiss={() => closeModal()}
        style={{ zIndex: 0 }}>
        <div className="info-wrapper">
          {reactionVsUserObj && Object.keys(reactionVsUserObj)?.length ? (
            <>
              <div className="info-wrapper_tabs">{displayTabs()}</div>
              <div className="info-wrapper_users">{displayUserList()}</div>
            </>
          ) : null}
        </div>
      </BottomSheet>
    </div>
  );
};

export default memo(InfoMessageModel);
