import { AxiosProgressEvent } from 'axios';

import {
  IChannel,
  IChannelGroup,
  IChannelPostDetails,
  IComment,
  ICommentReqBody,
  ICommunityUploadedMedia,
  IEditPostReqBody,
  ILink,
  IPostReqBody,
} from '../types/communityTypes';
import API from './';

export default abstract class CommunityAPI {
  static root = 'api/v1/community';

  static channel = '/channels';

  static getAllChannelGroups = () =>
    API.get<IChannelGroup[]>(`${this.root}${this.channel}/groups`);

  static getAllCommunityLinks = () => API.get<ILink[]>(`${this.root}/links`);

  static getChannelDetailsById = (channelId: string) =>
    API.get<IChannel>(`${this.root}${this.channel}/${channelId}`);

  static getPostsByChannelId = (channelId: string, queryParams: string) =>
    API.get<{ posts: IChannelPostDetails[]; totalPosts: number }>(
      `${this.root}${this.channel}/${channelId}/posts?${queryParams}`,
    );

  static createPost = (data: IPostReqBody) => {
    return API.post<IChannelPostDetails>(
      `${this.root}${this.channel}/posts`,
      data,
    );
  };

  static editPost = (
    channelId: string,
    postId: string,
    data: IEditPostReqBody,
  ) =>
    API.put<IChannelPostDetails[]>(
      `${this.root}${this.channel}/${channelId}/posts/${postId}`,
      data,
    );

  static increasePostViews = (channelId: string, postId: string) =>
    API.post<any>(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/views`,
    );

  static getCommunityPostById = (channelId: string, postId: string) =>
    API.get(`${this.root}${this.channel}/${channelId}/posts/${postId}`);

  static addVoteOnCommunityPoll = (
    channelId: string,
    postId: string,
    data: { option: string },
  ) =>
    API.post(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/votes`,
      data,
    );

  static removeVoteOnCommunityPoll = (
    channelId: string,
    postId: string,
    data: { option: string },
  ) =>
    API.delete(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/votes`,
      { data },
    );

  static addLikeToCommunityPost = (channelId: string, postId: string) =>
    API.post(`${this.root}${this.channel}/${channelId}/posts/${postId}/likes`);

  static removeLikeToCommunityPost = (channelId: string, postId: string) =>
    API.delete(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/likes`,
    );

  static updatePost = (channelId: string, postId: string, data: any) =>
    API.put(`${this.root}${this.channel}/${channelId}/posts/${postId}`, data);

  static addPinnedToCommunityPost = (channelId: string, postId: string) =>
    API.post(`${this.root}${this.channel}/${channelId}/posts/${postId}/pin`);

  static removePinnedFromCommunityPost = (channelId: string, postId: string) =>
    API.delete(`${this.root}${this.channel}/${channelId}/posts/${postId}/pin`);

  static removeCommunityPost = (channelId: string, postId: string) =>
    API.delete(`${this.root}${this.channel}/${channelId}/posts/${postId}`);

  // ---------Comments
  static crateComment = (
    channelId: string,
    postId: string,
    data: ICommentReqBody,
  ) =>
    API.post<IComment>(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/comments`,
      data,
    );

  static getComments = (
    channelId: string,
    postId: string,
    query: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _: string,
  ) =>
    API.get<{ comments: IComment; totalComments: 0 }>(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/comments?${query}`,
    );

  static getReplyComments = (
    channelId: string,
    postId: string,
    query: string,
    commentId: string,
  ) =>
    API.get<{ comments: IComment; totalComments: 0 }>(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/comments/${commentId}?${query}`,
    );

  static addCommentLike = (
    channelId: string,
    postId: string,
    commentId: string,
  ) =>
    API.post<IComment>(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/comments/${commentId}/likes`,
    );

  static removeCommentLike = (
    channelId: string,
    postId: string,
    commentId: string,
  ) =>
    API.delete<IComment>(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/comments/${commentId}/likes`,
    );

  static removeComment = (
    channelId: string,
    postId: string,
    commentId: string,
  ) =>
    API.delete<IComment>(
      `${this.root}${this.channel}/${channelId}/posts/${postId}/comments/${commentId}`,
    );

  // ---------Comments

  static uploadCommunityMedia = (
    files: File[],
    onUploadProgress: (e: AxiosProgressEvent) => void,
  ) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });

    return API.post<ICommunityUploadedMedia[]>(
      `${this.root}/uploads/assets`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      },
    );
  };

  // ----------------------------------------Message

  // ----------------------------------------Message
}
