import React, { memo, useEffect, useState } from 'react';

import { Switch } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';

import HeaderTextAction from '../../../components/Header/actions/HeaderTextAction';
import Header from '../../../components/Header/Header';
import { IPostReqBodyPostSettings } from '../../../types/communityTypes';

type Props = {
  show: boolean;
  closeModal: () => void;
  handleSave: (setings: IPostReqBodyPostSettings) => void;
  postSettings: IPostReqBodyPostSettings;
};

const CommunityPostSettingsModel: React.FC<Props> = ({
  show,
  closeModal,
  handleSave,
  postSettings,
}) => {
  const [setings, setSettings] = useState(postSettings);

  const handleUpdateSettings = (
    checked: boolean,
    key: keyof IPostReqBodyPostSettings,
  ) => {
    setSettings((prev) => ({ ...prev, [key]: checked }));
  };

  useEffect(() => {
    if (show) {
      setSettings(postSettings);
    }
  }, [postSettings, show]);

  return (
    <BottomSheet
      open={show}
      onDismiss={() => {
        closeModal();
      }}
      className="mango__selection__modal"
      header={
        <Header
          title={'Post Settings'}
          canGoBack={false}
          noBorder
          actionItems={[
            <HeaderTextAction
              text="Done"
              onClick={() => {
                handleSave(setings);
                closeModal();
              }}
            />,
          ]}
        />
      }>
      <div className="post-settings_container">
        <div className="post-settings_container_wrapper">
          <div className="post-settings_container_wrapper_item">
            <p>Hide comments</p>
            <Switch
              checked={setings?.hideComment}
              onChange={(checked) =>
                handleUpdateSettings(checked, 'hideComment')
              }
            />
          </div>
          <div className="post-settings_container_wrapper_item">
            <p>Disable comments</p>
            <Switch
              checked={setings.disableComment}
              onChange={(checked) =>
                handleUpdateSettings(checked, 'disableComment')
              }
            />
          </div>
          <div className="post-settings_container_wrapper_item">
            <p>Hide likes</p>
            <Switch
              checked={setings.hideLikes}
              onChange={(checked) => handleUpdateSettings(checked, 'hideLikes')}
            />
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default memo(CommunityPostSettingsModel);
