import React, { memo } from 'react';

import { BsCardImage } from 'react-icons/bs';
import { IoCloseCircle } from 'react-icons/io5';

import { setReplyToComment } from '../../../../context/communityReducer';
import { useAppDispatch } from '../../../../shared/hooks';
import { IComment } from '../../../../types/communityTypes';

type Props = {
  loading: boolean;
  replyComment: IComment;
};

const ReplyCommentCard: React.FC<Props> = ({ loading, replyComment }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="comment-input_container_reply">
      <div className="comment-input_container_reply_comment">
        <h4>{replyComment?.commentedBy?.name}</h4>
        {replyComment?.comment ? (
          <p>
            {replyComment?.comment.substring(0, 20)}
            {'...'}
          </p>
        ) : (
          <BsCardImage />
        )}
      </div>
      <button
        className="siteBtn noStyle comment-input_container_reply_corss-btn"
        disabled={loading}
        onClick={() => {
          dispatch(setReplyToComment(null));
        }}>
        <IoCloseCircle />
      </button>
    </div>
  );
};

export default memo(ReplyCommentCard);
