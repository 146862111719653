import '../styles.scss';

import React from 'react';

import { Button } from 'antd';

interface Props {
  text: string;
  loadingText?: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const HeaderTextAction: React.FC<Props> = ({
  text,
  loadingText = 'Loading',
  onClick,
  loading = false,
  disabled = false,
  style = {},
}) => (
  <Button
    type="link"
    className="siteBtn siteBtnLink"
    disabled={disabled}
    loading={loading}
    style={style}
    onClick={onClick}>
    {loading ? loadingText : text}
  </Button>
);

export default React.memo(HeaderTextAction);
