import React from 'react';

import { Button, Typography } from 'antd';
import { FaAngleDown } from 'react-icons/fa6';

type Props = {
  filteredDataList: any[];
  buttonTitle: string;
  modalOpenAction: () => void;
  children: React.ReactNode;
};

const FilterButtonWrapper: React.FC<Props> = ({
  filteredDataList,
  buttonTitle,
  modalOpenAction,
  children,
}) => {
  return (
    <div className="create-post-header-details-mango feed-filter-button-wrapper">
      <Button
        className={`siteBtn create-post-header-details-mango-button feed-filter-button ${
          filteredDataList?.length ? 'applied_filter_button' : ''
        }`}
        onClick={modalOpenAction}>
        <Typography.Text
          className={`button-text ${
            filteredDataList?.length ? 'applied_filter' : ''
          }`}>
          {buttonTitle}
        </Typography.Text>
        <FaAngleDown
          className={`dropdown-symbol ${
            filteredDataList?.length ? 'applied_filter' : ''
          }`}
        />
      </Button>
      {children}
    </div>
  );
};

export default FilterButtonWrapper;
