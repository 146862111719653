import { message } from 'antd';
import { AxiosProgressEvent } from 'axios';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import CommunityAPI from '../api/CommunityAPI';
import showAppError from '../shared/error';
import {
  IChannel,
  IChannelGroup,
  ICommunityChannelTabTypes,
  ICommunitySlice,
  ICommunityUploadedMedia,
  ILink,
  IPostProgressData,
  IPostReqBody,
  IPostReqBodyMediaContent,
  IPostState,
  Showing,
  SortBy,
} from '../types/communityTypes';
import { AppThunk } from './store';

export const sortByOptionList = [
  { title: 'Latest', _id: SortBy.Latest },
  { title: 'Trending now', _id: SortBy.TrendingNow },
  { title: 'Most popular', _id: SortBy.MostPopular },
  { title: 'Oldest', _id: SortBy.Oldest },
];

export const showingOptionList = [
  { title: 'Everything', _id: Showing.Everything },
  { title: 'My post', _id: Showing.MyPosts },
  { title: 'For your subscribers', _id: Showing.PostsFromSubscribers },
];

export const initialPostState = {
  loading: false,
  page: 1,
  hasMore: true,
  posts: [],
  refreshing: false,
};

const filterObject = {
  filteredMangoes: [],
  showing: [{ title: 'Everything', _id: Showing.Everything }],
  sortBy: [{ title: 'Latest', _id: SortBy.Latest }],
};

const initialState: ICommunitySlice = {
  ...filterObject,
  activeSection: '',
  headerTitle: '',
  headerIcon: null,
  groupChannelList: [],
  channelPageActiveTab: 'feed',
  selectedChannelData: null,
  communityLinkList: [],
  communityPostState: {
    loading: false,
    page: 1,
    hasMore: true,
    posts: [],
    refreshing: false,
  },
  postProgressData: {
    loaded: 0,
    total: 100,
    message: '',
    showProgress: false,
    post: null,
  },
  mediaProgressData: {
    loaded: 0,
    total: 100,
    message: '',
    showProgress: false,
    post: null,
  },
  communityViewedPostIds: [],
  pdpCommunityPostData: null,
  communityPostComments: [],
  replyComment: null,
  replayCommentsObj: {},
};

const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    setFilteredMangoes: (state, action: PayloadAction<any[]>) => {
      state.filteredMangoes = action.payload;
    },
    setSortBy: (state, action: PayloadAction<any[]>) => {
      state.sortBy = action.payload;
    },
    setShowing: (state, action: PayloadAction<any[]>) => {
      state.showing = action.payload;
    },
    setActiveSection: (
      state,
      action: PayloadAction<{ activeSection: string; headerTitle: string }>,
    ) => {
      state.activeSection = action.payload.activeSection;
      state.headerTitle = action.payload.headerTitle;
    },
    setGroupChannelList: (state, action: PayloadAction<IChannelGroup[]>) => {
      state.groupChannelList = action.payload;
    },
    setChannelPageActiveTab: (
      state,
      action: PayloadAction<ICommunityChannelTabTypes>,
    ) => {
      state.channelPageActiveTab = action.payload;
    },
    setSelectedChannelData: (state, action: PayloadAction<IChannel | null>) => {
      state.selectedChannelData = action.payload;
    },
    setCommunityLinkList: (state, action: PayloadAction<ILink[]>) => {
      state.communityLinkList = action.payload;
    },
    setCommunityPostState: (
      state,
      action: PayloadAction<Partial<IPostState>>,
    ) => {
      Object.assign(state.communityPostState, action.payload);
    },
    setCommunityPostProgressData: (
      state,
      action: PayloadAction<Partial<IPostProgressData>>,
    ) => {
      Object.assign(state.postProgressData, action.payload);
    },
    setCommunityViewedPostIds: (state, action: PayloadAction<string>) => {
      const newViewedPostIds = [...state.communityViewedPostIds];
      if (!newViewedPostIds.includes(action.payload)) {
        newViewedPostIds.push(action.payload);
        state.communityViewedPostIds = newViewedPostIds;
      }
    },
    setCommunityMediaProgressData: (
      state,
      action: PayloadAction<Partial<IPostProgressData>>,
    ) => {
      Object.assign(state.mediaProgressData, action.payload);
    },
    setPDPCommunityPostData: (state, action) => {
      state.pdpCommunityPostData = action?.payload;
    },
    addCommunityPostComments: (state, action) => {
      state.communityPostComments = [
        action?.payload,
        ...state.communityPostComments,
      ];
    },
    appendCommunityPostComments: (state, action) => {
      state.communityPostComments = [
        ...state.communityPostComments,
        ...action?.payload,
      ];
    },
    setCommunityPostComments: (state, action) => {
      state.communityPostComments = action.payload;
    },
    setReplyToComment: (state, action) => {
      state.replyComment = action?.payload;
    },
    addReplyComments: (state, action) => {
      const parentId = action.payload?.parentId;
      const replyList = state?.replayCommentsObj?.[parentId] || [];
      const updatedReplyList = [...replyList, ...action.payload?.comments];
      state.replayCommentsObj = {
        ...state?.replayCommentsObj,
        [parentId]: updatedReplyList,
      };
    },
    appendReplyComments: (state, action) => {
      const parentId = action.payload?.parentId;
      const replyList = state?.replayCommentsObj?.[parentId] || [];
      const updatedReplyList = [action.payload?.comment, ...replyList];
      state.replayCommentsObj = {
        ...state?.replayCommentsObj,
        [parentId]: updatedReplyList,
      };
    },
    updateReplyComment: (state, action) => {
      const parentId = action.payload?.parentId;
      const replyList = [...(state?.replayCommentsObj?.[parentId] || [])];
      const updatedComment = action.payload?.comment;
      let replyIndex;
      const reply = replyList.find((eachComment, index) => {
        const isThatOne = eachComment?._id === updatedComment?._id;
        if (isThatOne) {
          replyIndex = index;
          return true;
        }
        return false;
      });
      if (Number.isInteger(replyIndex) && replyIndex !== undefined) {
        replyList.splice(replyIndex, 1, { ...reply, ...updatedComment });
        state.replayCommentsObj = {
          ...state?.replayCommentsObj,
          [parentId]: replyList,
        };
      }
    },
    updateReplyCommentObj: (state, action) => {
      Object.assign(state?.replayCommentsObj, action.payload);
    },

    resetAllFilters: (state) => {
      Object.assign(state, filterObject);
    },
  },
});

export const {
  setFilteredMangoes,
  setSortBy,
  setShowing,
  setActiveSection,
  setGroupChannelList,
  setChannelPageActiveTab,
  setSelectedChannelData,
  setCommunityLinkList,
  setCommunityPostState,
  setCommunityPostProgressData,
  setCommunityMediaProgressData,
  setCommunityViewedPostIds,
  resetAllFilters,
  setPDPCommunityPostData,
  addCommunityPostComments,
  setCommunityPostComments,
  appendCommunityPostComments,
  addReplyComments,
  setReplyToComment,
  updateReplyComment,
  appendReplyComments,
  updateReplyCommentObj,
} = communitySlice.actions;

export default communitySlice.reducer;

export const getChannelPosts =
  (data: {
    pageCount: any;
    postList?: any[];
    hasMore?: boolean;
    loading?: boolean;
    currState?: IPostState;
    showUserPosts?: boolean;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { communityPostState, selectedChannelData, sortBy, showing } =
      state.community;
    const { token } = state.app;
    const { pageCount } = data;

    const postList = data.postList || state.community.communityPostState.posts;
    const hasMore = data.hasMore || state.community.communityPostState.hasMore;
    const loading = data.loading || state.community.communityPostState.loading;
    const currState = data.currState || state.community.communityPostState;

    if (hasMore && !loading && token) {
      dispatch(setCommunityPostState({ ...currState, loading: true }));

      const query = new URLSearchParams();
      query.append('page', pageCount);
      query.append('pageSize', '5');
      query.append('sortBy', sortBy[0]._id);
      if (showing[0]?._id !== 'everything') {
        query.append('filterBy', showing[0]._id);
      }

      CommunityAPI.getPostsByChannelId(
        selectedChannelData?._id as string,
        query?.toString() as string,
      )
        .then((response: any) => {
          const res = response.data;
          let newState = { ...currState, loading: false, refreshing: false };
          if (response.status === 200) {
            if (res.result && res.result.posts && res.result.posts?.length) {
              const totalPostCount = res.result?.totalPosts;
              const updatedPost =
                pageCount > 1
                  ? [...(postList || []), ...res.result.posts]
                  : [...res.result.posts];

              newState = {
                ...newState,
                posts: updatedPost,
                page: pageCount + 1,
                hasMore: totalPostCount > updatedPost?.length,
              };
            } else {
              newState = {
                ...newState,
                posts: [...(postList || [])],
                hasMore: false,
              };
            }
          }

          dispatch(
            setCommunityPostState({ ...communityPostState, ...newState }),
          );
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(
            setCommunityPostState({ ...communityPostState, loading: false }),
          );
        });
    }
  };

export const onRefresh = (): AppThunk => (dispatch, getState) => {
  const {
    activity: { postState },
  } = getState();

  const newState = {
    posts: [],
    hasMore: true,
    page: 1,
    loading: false,
    refreshing: true,
  };

  dispatch(
    setCommunityPostState({
      ...postState,
      ...newState,
    }),
  );

  // if (selected) {
  //   dispatch(
  //     getPosts({
  //       pageCount: 1,
  //       postList: [],
  //       hasMore: true,
  //       loading: false,
  //       currState: newState,
  //     }),
  //   );
  // }
};

export const handleProgress =
  (event: AxiosProgressEvent): AppThunk =>
  (dispatch, getState) => {
    const { mediaProgressData } = getState().community;
    dispatch(
      setCommunityMediaProgressData({
        ...mediaProgressData,
        loaded: event.loaded,
        total: event.total,
        message:
          event.loaded === event.total
            ? 'Processing'
            : mediaProgressData.message,
      }),
    );
  };

export const uploadFilesForCommunityPost =
  (files: File[]): AppThunk<Promise<IPostReqBodyMediaContent[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch(
        setCommunityMediaProgressData({
          message: 'Uploading files...',
          showProgress: true,
        }),
      );

      const resp = await CommunityAPI.uploadCommunityMedia(files, (e) => {
        dispatch(handleProgress(e));
      });

      if (resp.status === 201) {
        dispatch(
          setCommunityMediaProgressData({
            message: 'Files uploaded successfully',
            showProgress: false,
          }),
        );

        return resp.data?.result.map((file: ICommunityUploadedMedia) => ({
          type: file.type,
          contentUrl: file.assetUrl,
          _id: file._id,
        }));
      }
    } catch (error) {
      dispatch(
        setCommunityMediaProgressData({
          message: 'Upload failed. Please try again.',
          showProgress: false,
        }),
      );
      showAppError(error);
      throw error;
    }
  };

export const updateCommunitPost =
  (post: any): AppThunk =>
  (dispatch, getState) => {
    const {
      community: { communityPostState },
    } = getState();
    const newPosts = [...communityPostState.posts].map((prevPost) => {
      if (prevPost._id === post._id) {
        return {
          ...prevPost,
          ...post,
        };
      }

      return prevPost;
    });

    dispatch(
      setCommunityPostState({
        ...communityPostState,
        posts: newPosts,
      }),
    );
  };

export const updateCommunitComments =
  (comment: any): AppThunk =>
  (dispatch, getState) => {
    const {
      community: { communityPostComments },
    } = getState();
    const newComments = [...communityPostComments].map((prevComment) => {
      if (prevComment._id === comment._id) {
        return {
          ...prevComment,
          ...comment,
        };
      }

      return prevComment;
    });

    dispatch(setCommunityPostComments(newComments));
  };

export const createCommunityPost =
  (
    data: IPostReqBody,
    channels: any[],
    caption: string | undefined | null,
    files: File[],
    onSucess?: () => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const { user: userDetails } = getState();

    try {
      const uploadedFileList = await dispatch(
        uploadFilesForCommunityPost(files),
      );
      dispatch(
        setCommunityPostProgressData({
          message: 'Uploading',
          loaded: 0,
          total: 100,
          showProgress: true,
          post: {
            channels: channels,
            caption,
            creatorId: userDetails.id,
            imgUrl: userDetails.profilePic,
            name: userDetails.name,
            createdAt: new Date().toISOString(),
          },
        }),
      );
      const resp = await CommunityAPI.createPost({
        ...data,
        mediaContent: uploadedFileList,
      });
      dispatch(setCommunityPostProgressData({ showProgress: false }));
      if (resp.status === 201) {
        dispatch(onRefresh());
        onSucess?.();
        message.success('Post created successfully');
      } else {
        showAppError(resp.data);
      }
    } catch (err) {
      dispatch(setCommunityPostProgressData({ showProgress: false }));
      showAppError(err);
    }
  };

export const userInterationAction =
  (
    actionType:
      | 'addLike'
      | 'removeLike'
      | 'updatePostSettings'
      | 'addPinned'
      | 'removePinned'
      | 'removePost'
      | 'addCommentLike'
      | 'removeCommentLike',
    postId: string,
    channelId: string,
    communityPostDetails: any,
    updatedAttributes: { [key: string]: any },
    updateThunkAction: (arg0: any, showError?: boolean) => void,
    data?: any,
    onSucess?: () => void,
    isHardRefresh: boolean = false,
  ): AppThunk =>
  async (dispatch) => {
    const originalCommunityPostDetails = { ...communityPostDetails };
    try {
      let actionAPI;
      if (actionType === 'addLike') {
        actionAPI = CommunityAPI.addLikeToCommunityPost;
      } else if (actionType === 'removeLike') {
        actionAPI = CommunityAPI.removeLikeToCommunityPost;
      } else if (actionType === 'updatePostSettings') {
        actionAPI = CommunityAPI.updatePost;
      } else if (actionType === 'addPinned') {
        actionAPI = CommunityAPI.addPinnedToCommunityPost;
      } else if (actionType === 'removePinned') {
        actionAPI = CommunityAPI.removePinnedFromCommunityPost;
      } else if (actionType === 'removePost') {
        actionAPI = CommunityAPI.removeCommunityPost;
      } else if (actionType === 'addCommentLike') {
        actionAPI = CommunityAPI.addCommentLike;
      } else if (actionType === 'removeCommentLike') {
        actionAPI = CommunityAPI.removeCommentLike;
      }

      if (!actionAPI) return;

      updateThunkAction({
        ...communityPostDetails,
        ...updatedAttributes,
      });

      const resp = await actionAPI(channelId, postId, data);

      if (resp?.status < 299) {
        onSucess?.();
        if (isHardRefresh) {
          dispatch(onRefresh());
        }
      } else {
        updateThunkAction({
          ...originalCommunityPostDetails,
        });
        showAppError(resp?.data);
      }
    } catch (error) {
      showAppError(error);
      updateThunkAction({
        ...originalCommunityPostDetails,
      });
    }
  };

// export const addComments = (commentReqData): AppThunk => async (dispatch) => {
//   try {

//   }
// };

export const removeComment =
  (commentId: string, parentCommentId?: string): AppThunk =>
  (dispatch, getState) => {
    const { community } = getState();
    const { communityPostComments, replayCommentsObj } = community;
    if (parentCommentId) {
      const newParentReplyComments = replayCommentsObj[parentCommentId];
      if (newParentReplyComments?.length) {
        const newCommunityReplyPostComments = newParentReplyComments.filter(
          (each) => each?._id !== commentId,
        );
        dispatch(
          updateReplyCommentObj({
            [parentCommentId]: newCommunityReplyPostComments,
          }),
        );
      }
    } else {
      const newCommunityPostComments = communityPostComments.filter(
        (each) => each?._id !== commentId,
      );
      dispatch(setCommunityPostComments(newCommunityPostComments));
    }
  };
