import '../styles.scss';

import React from 'react';

import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { setChannelPageActiveTab } from '../../../context/communityReducer';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  CommunityChannelTabs,
  ICommunityChannelTabTypes,
} from '../../../types/communityTypes';

type Props = {};

const CommunityChannelTabPannel: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { channelPageActiveTab, selectedChannelData } = useAppSelector(
    (state) => state.community,
  );

  const changeChannelTab = (tabName: ICommunityChannelTabTypes) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('tab');
    searchParams.append('tab', tabName);
    navigate({
      search: searchParams.toString(),
    });
    dispatch(setChannelPageActiveTab(tabName));
  };

  return (
    <div className="channel-switch-wrapper">
      {selectedChannelData?.features.includes('feed') ? (
        <Button
          className={`${
            channelPageActiveTab === CommunityChannelTabs.FEED
              ? 'channel-switch-wrapper_active-btn'
              : ''
          }`}
          onClick={() => changeChannelTab(CommunityChannelTabs.FEED)}>
          <Typography.Text>Feed</Typography.Text>
        </Button>
      ) : null}
      {selectedChannelData?.features.includes('messages') ? (
        <Button
          className={`${
            channelPageActiveTab === CommunityChannelTabs.MESSAGES
              ? 'channel-switch-wrapper_active-btn'
              : ''
          }`}
          onClick={() => changeChannelTab(CommunityChannelTabs.MESSAGES)}>
          <Typography.Text>Messages</Typography.Text>
        </Button>
      ) : null}
    </div>
  );
};

export default CommunityChannelTabPannel;
