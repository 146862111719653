import React from 'react';

import { DatePicker, Switch } from 'antd';
import moment from 'moment';
import { IoIosArrowDown } from 'react-icons/io';

import { DayMap } from '../../../shared/constants';

type Props = {
  setIsResultVisibleToMembers: React.Dispatch<React.SetStateAction<boolean>>;
  isResultVisibleToMembers: boolean;
  allowVoteChange: boolean;
  setAllowVoteChange: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSelectePollDurationModel: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  duration: string | number;
  setCustomEndDateTime: React.Dispatch<
    React.SetStateAction<moment.Moment | null>
  >;
};

const CommunityPollSettings: React.FC<Props> = ({
  setIsResultVisibleToMembers,
  isResultVisibleToMembers,
  allowVoteChange,
  setAllowVoteChange,
  setShowSelectePollDurationModel,
  duration,
  setCustomEndDateTime,
}) => {
  return (
    <div className="poll-setting_container">
      <h3>Settings</h3>
      <div className="poll-setting_container_allow-result">
        <h4>Allow members to see the result</h4>
        <Switch
          checked={isResultVisibleToMembers}
          onChange={(checked) => setIsResultVisibleToMembers(checked)}
        />
      </div>
      <div className="poll-setting_container_allow-change-vote">
        <div className="poll-setting_container_allow-change-vote_title-subtitle-wrapper">
          <h4>Allow Vote Changes</h4>
          <p>
            Enable this setting to let users modify their responses after
            submitting their vote. If disabled, votes will be final.
          </p>
        </div>
        <Switch
          checked={allowVoteChange}
          onChange={(checked) => setAllowVoteChange(checked)}
        />
      </div>
      <div className="poll-setting_container_poll-duration">
        <div className="poll-setting_container_poll-duration_select-wrapper">
          <h4>Poll duration</h4>
          <button onClick={() => setShowSelectePollDurationModel(true)}>
            <span>{duration ? DayMap[duration] : 'Select'}</span>
            <IoIosArrowDown />
          </button>
        </div>
        {duration === 'custom' ? (
          <div className="poll-setting_container_poll-duration_date-time-wrapper">
            <DatePicker
              format="YYYY-MM-DD  HH:mm"
              // disabledDate={disabledDate}
              // disabledTime={disabledDateTime}
              popupClassName="date-time-picker_popup"
              placeholder="Select End date and End time"
              showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
              onChange={setCustomEndDateTime}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CommunityPollSettings;
