import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Badge } from 'antd';
import { Link } from 'react-router-dom';
import TMIcon from 'tm-icons-library';

import FeedAPI from '../../api/FeedAPI';
import logo from '../../assets/img/tagmango.png';
import { useAppProvider } from '../../context/AppProvider';
import { useTheme } from '../../context/ThemeProvider';
import ChatAvatar from '../../screens/Messages/components/ChatAvatar/ChatAvatar';
import { useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import CustomImage from '../CustomImage/CustomImage';
import ConfirmationModal from '../Modals/ConfirmationModal/ConfirmationModal';

interface Props {
  title: string;
  children?: React.ReactNode;
  actionItems?: React.ReactNode[];
  noBorder?: boolean;
  beforTitleAction?: React.ReactNode;
  customTitle?: React.ReactNode;
}

const HomeHeader: React.FC<Props> = ({
  title,
  children,
  actionItems: moreActionItems = [],
  noBorder = false,
  beforTitleAction = null,
  customTitle = null,
}) => {
  const { colors } = useTheme();
  const { handleLogout } = useAppProvider();

  const userDetails = useAppSelector((state) => state.user);
  const { token, isTagMango } = useAppSelector((state) => state.app);

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const logout = () => {
    setIsLoaderVisible(true);
    handleLogout();
  };

  useEffect(() => {
    if (token)
      FeedAPI.getUnreadNotificationsCount()
        .then((res) => {
          setUnreadNotificationCount(res.data?.result || 0);
        })
        .catch((error: any) => {
          console.log(error);
        });
  }, [token]);

  // const handleDownloadApp = async () => {
  //   if (deferredPrompt !== null) {
  //     deferredPrompt.prompt();
  //     const { outcome } = await deferredPrompt.userChoice;
  //     if (outcome === 'accepted') {
  //       dispatch(setDeferredPrompt(null));
  //     }
  //   }
  // };

  const actionItems = useMemo(() => {
    let items = [...moreActionItems];

    items.push(
      <Link to={ROUTES.NOTIFICATIONS} key="notifications">
        <Badge count={unreadNotificationCount} size="small">
          <div className="header--activity__action">
            <TMIcon name="notifications" color={colors.ICON} />
          </div>
        </Badge>
      </Link>,
    );

    if (userDetails.type === 'creator_restricted') {
      items.push(
        <div
          key="logout"
          className="header--activity__action"
          onClick={() => {
            setLogoutModalVisible(true);
          }}
          style={{
            backgroundColor: `${colors.DANGER}0d`,
          }}>
          <TMIcon name="log-out" color={colors.DANGER} />
        </div>,
      );
    } else {
      items.push(
        <Link to={ROUTES.MENU} key="menu">
          <div className="header--activity__action">
            <ChatAvatar url={userDetails.profilePic || ''} size={34} />
          </div>
        </Link>,
      );
    }

    return items;
  }, [
    colors,
    moreActionItems,
    unreadNotificationCount,
    userDetails.profilePic,
    userDetails.type,
  ]);

  const headerTitle = useMemo(() => {
    if (customTitle) {
      return customTitle;
    }
    return (
      <div
        className="header--activity__logo"
        style={{
          width: isTagMango ? 125 : undefined,
          flexGrow: isTagMango ? undefined : 1,
          alignSelf: 'center',
        }}>
        {isTagMango ? (
          <CustomImage src={logo} alt="logo" />
        ) : (
          <h1 className="header--activity__logo__text">{title}</h1>
        )}
      </div>
    );
  }, [customTitle, isTagMango, title]);

  return (
    <>
      <div
        className={`header--activity__container ${
          noBorder ? 'borderless' : ''
        }`}>
        <div className="header--activity">
          <div style={{ display: 'flex' }}>
            {beforTitleAction}
            {headerTitle}
          </div>
          {/* <div
            className="header--activity__logo"
            style={{
              width: isTagMango ? 125 : undefined,
              flexGrow: isTagMango ? undefined : 1,
            }}>
            {isTagMango ? (
              <CustomImage src={logo} alt="logo" />
            ) : (
              <h1 className="header--activity__logo__text">{title}</h1>
            )}
          </div> */}
          <div className="header--activity__actions">
            {/* {isTagMango && deferredPrompt ? (
              <div
                className="header--activity__action"
                onClick={handleDownloadApp}>
                <MdDownload />
              </div>
            ) : null}
            {!isTagMango && customApps ? (
              <Link
                to={ROUTES.CUSTOM_APPS}
                title={customApps.tabTitle}
                className="header--activity__action">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"></path>
                </svg>
              </Link>
            ) : null} */}

            {actionItems}
          </div>
        </div>
        {children}
      </div>
      <ConfirmationModal
        open={logoutModalVisible}
        loading={isLoaderVisible}
        title="Logout"
        message="Are you sure you want to logout?"
        okayButtonText="Logout"
        cancelButtonText="Cancel"
        handleOk={() => {
          logout();
        }}
        handleCancel={() => {
          setLogoutModalVisible(false);
        }}
      />
    </>
  );
};

export default React.memo(HomeHeader);
