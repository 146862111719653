import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import CommunityAPI from '../../api/CommunityAPI';
import {
  setActiveSection,
  setChannelPageActiveTab,
  setSelectedChannelData,
} from '../../context/communityReducer';
import showAppError from '../../shared/error';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  CommunityChannelTabs,
  ICommunityChannelTabTypes,
} from '../../types/communityTypes';
import CommunityChannelPostList from './CommunityPost/CommunityChannelPostList';
import CommunityChatRoom from './components/CommunityChatRoom';

type Props = {};

const CommunityChannel: React.FC<Props> = () => {
  const { channelId } = useParams();
  const dispatch = useAppDispatch();
  const { channelPageActiveTab, selectedChannelData } = useAppSelector(
    (state) => state.community,
  );
  // const channelId = useMemo(() => {
  //   return selectedChannelData?._id;
  // }, [selectedChannelData?._id]);

  const [loader, setLoader] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const queryTagValue = searchParams.get('tab');

  const setInitialTabForPage = useCallback(() => {
    const communityTabTypeList: ICommunityChannelTabTypes[] =
      Object.values(CommunityChannelTabs);
    if (!queryTagValue && channelPageActiveTab !== CommunityChannelTabs.FEED) {
      dispatch(setChannelPageActiveTab(CommunityChannelTabs.FEED));
    } else if (
      queryTagValue &&
      communityTabTypeList.includes(
        queryTagValue as ICommunityChannelTabTypes,
      ) &&
      channelPageActiveTab !== queryTagValue
    ) {
      dispatch(
        setChannelPageActiveTab(queryTagValue as ICommunityChannelTabTypes),
      );
    }
  }, [channelPageActiveTab, dispatch, queryTagValue]);

  const fetchChannelDetails = useCallback(async () => {
    if (!channelId) return;
    try {
      setLoader(true);
      const resp = await CommunityAPI.getChannelDetailsById(channelId);
      if (resp?.status === 200) {
        dispatch(setSelectedChannelData(resp?.data?.result));
        dispatch(setChannelPageActiveTab(resp?.data?.result?.features[0]));
        dispatch(
          setActiveSection({
            activeSection: resp?.data?.result?._id,
            headerTitle: resp?.data?.result?.name,
          }),
        );
      } else {
        showAppError(resp?.data);
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setLoader(false);
    }
  }, [channelId, dispatch]);

  useEffect(() => {
    setInitialTabForPage();
    fetchChannelDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedChannelData(null));
    };
  }, [dispatch]);

  const displaySelectedTabComponent = useCallback(() => {
    if (channelPageActiveTab === 'feed') {
      return <CommunityChannelPostList />;
    }
    if (channelPageActiveTab === 'messages') {
      return (
        <CommunityChatRoom
          channelRoomId={selectedChannelData?.chatRoom}
          hideHeader
        />
      );
    }
    return null;
  }, [channelPageActiveTab, selectedChannelData?.chatRoom]);

  if (!selectedChannelData?._id) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="feed__content">
        {loader ? null : displaySelectedTabComponent()}
      </div>
    </>
  );
};

export default CommunityChannel;
